import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageUtils, Utils } from "../shared/utils/utils";
import { TranslationService } from "../services/translation.service";
import { CourseService } from "../services/course.service";
import { COURSE_CONST } from "../create-course/data";
import { QUIZ_CONST } from "../create-quiz/data";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent {
  constructor(
    private router: Router,
    public translationService: TranslationService,
    private courseService: CourseService,
  ) {
    Utils.checkAuthorization(this.router);
    this.courseService.deleteCourseBuilderSteps();
    this.courseService.applyStyles();
  }
  mode!: string;
  eventName!: string;
  categories: any[] = [
    {
      name: this.translationService.translate("EDE_COURSE_CONTENT"),
      id: COURSE_CONST.COURSE_CATEGORY,
      disabled: false,
    },
    {
      name: this.translationService.translate("QUIZ_QSSP"),
      id: QUIZ_CONST.QUIZ_CATEGORY,
      disabled: false,
    },
    {
      name: this.translationService.translate("QUESTIONNIRE"),
      id: "questionnaire",
      disabled: true,
    },
    {
      name: this.translationService.translate("ETC"),
      id: "etc",
      disabled: true,
    },
  ];

  categoryOnClick(id: string): void {
    this.router.navigate([`/${id}`]);
  }

  redirect() {
    window.location.href = LocalStorageUtils.getRedirectURL();
  }

  getClientName() {
    var name = LocalStorageUtils.getClientName().toString().toUpperCase();
    return name == "EDE2"? "EDE 2": name;
  }
}
