import * as jwt from "jsonwebtoken";
import { environment } from "src/environments/environment";
import { AuthRequest } from "../models/auth-response.interface";
import { Router } from "@angular/router";

export class LocalStorageUtils {
  /**
   * Saves data to localStorage.
   * @param key - Key for the data to be saved.
   * @param data - Data to be saved.
   */
  static saveToLocalStorage(key: string, data: any): void {
    try {
      const stringifiedData = JSON.stringify(data);
      localStorage.setItem(key, stringifiedData);
    } catch (error) {
      console.error("Error saving to local storage:", error);
    }
  }

  /**
   * Retrieves data from localStorage.
   * @param key - Key for the data to be retrieved.
   * @returns - Retrieved data or null if not found.
   */
  static retrieveFromLocalStorage(key: string): any {
    try {
      const data = localStorage.getItem(key);
      return data && data != "undefined" ? JSON.parse(data) : null;
    } catch (error) {
      console.error("Error retrieving from local storage:", error);
      return null;
    }
  }

  /**
   * Deletes data from localStorage.
   * @param key - Key for the data to be deleted.
   */
  static deleteFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clears all data from localStorage.
   */
  static clearLocalStorage(): void {
    localStorage.clear();
  }

  static updateLocalStorageProperty(
    key: string,
    property: string,
    value: any,
  ): void {
    try {
      const existingData =
        LocalStorageUtils.retrieveFromLocalStorage(key) || {};
      existingData[property] = value;
      LocalStorageUtils.saveToLocalStorage(key, existingData);
    } catch (error) {
      console.error("Error updating property in local storage:", error);
    }
  }

  static generateCourseId(): number {
    try {
      const courses =
        LocalStorageUtils.retrieveFromLocalStorage("courses") || [];
      if (courses && courses.length > 0) {
        const lastCourse = courses[courses.length - 1];
        return Number(lastCourse.id) + 1;
      } else {
        return 1;
      }
    } catch (error) {
      return 0;
    }
  }

  static isTokenExpired(): boolean {
    try {
      const token = JSON.parse(
        LocalStorageUtils.retrieveFromLocalStorage("authData"),
      )?.strapiToken;

      if (!token) {
        return true;
      }

      return false;
    } catch (error: any) {
      if (error.name === "TokenExpiredError") {
      } else {
      }
      return true;
    }
  }

  static getUser(): any {
    return JSON.parse(LocalStorageUtils.retrieveFromLocalStorage("authData"))
      ?.user;
  }

  static getClient(): any {
    return JSON.parse(LocalStorageUtils.retrieveFromLocalStorage("authData"))
      ?.client;
  }

  static getClientLogo(): any {
    return LocalStorageUtils.getClient()?.clientLogo;
  }

  static isUserBuilder(): any {
    return Boolean(LocalStorageUtils.getUser()?.isBuilder);
  }

  static getUsername(): any {
    return LocalStorageUtils.getUser()?.username.toString();
  }

  static getRedirectURL(): string {
    var authRequestData =
      LocalStorageUtils.retrieveFromLocalStorage("authRequestData");
    const database = authRequestData?.database; // database will be TBS or BDL
    return environment.redirectURL[database] || environment.redirectURL["TBS"];
  }

  static getClientName() {
    var authRequestData =
      LocalStorageUtils.retrieveFromLocalStorage("authRequestData");

    return authRequestData?.database;
  }
}

export class CommonUtils {
  static getBase64(file: any): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  static breakObjectReference(obj: any): any {
    let object = JSON.parse(JSON.stringify(Object.assign(obj)));
    return JSON.parse(JSON.stringify(object));
  }

  static generateRandomKey() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let key = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      key += characters.charAt(randomIndex);
    }
    return key;
  }

  static deepObjectEqualityCheck(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;
    if (obj1 == null || obj2 == null) return false;
    if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key)) return false;
      if (!this.deepObjectEqualityCheck(obj1[key], obj2[key])) return false;
    }

    return true;
  }
}

export class Utils {
  constructor(private router: Router) { }

  static checkAuthorization(router: Router): void {
    if (LocalStorageUtils.isTokenExpired()) {
      router.navigate(["/unauthorized"]);
    }
    if (!LocalStorageUtils.isUserBuilder()) {
      router.navigate(["/forbidden"]); //unauthorized
    }
  }

  static checkViewerAuthorization(router: Router): void {
    if (LocalStorageUtils.isTokenExpired()) {
      router.navigate(["/unauthorized"]);
    }
  }
}
