import { Component, EventEmitter, Output } from "@angular/core";
import { Option } from "../../create-course.interfaces";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  LAYER_OPTIONS,
  COURSE_CONST,
  LAYER_ONE_AND_TWO_OPTIONS,
  LAYER_ONE_OPTIONS,
  PART_1_SUBTITLES,
} from "../../data";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { CourseService } from "../../../services/course.service";
import { Utils } from "../../../core/utils";
import { environment } from "src/environments/environment";
import { SupportedAsset } from "src/app/models/enum/supported-asset.enum";
import { TranslationService } from "src/app/services/translation.service";
import { GlobalService } from "src/app/services/global.service";
import { QUESTION_TYPE_DATA, QUIZ_CONST } from "src/app/create-quiz/data";

@Component({
  selector: "app-step-two",
  templateUrl: "./step-two.component.html",
  styleUrls: ["./step-two.component.scss"],
})
export class StepTwoComponent {
  validateForm!: UntypedFormGroup;
  currentLayerOptions!: Option[];
  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() imageDelete: EventEmitter<any> = new EventEmitter();
  selectedOption: Option | null = null;
  fileList: NzUploadFile[] = [];
  audioFileList: NzUploadFile[] = [];
  hotspotList: any[] = [];
  totalParts: string = COURSE_CONST.ONE;
  isFormSubmit: boolean = false;
  styleObject: any;
  router: any;
  disableUploadButton: boolean = false;
  imageConfig: any = environment.imageConfig;
  videoConfig: any = environment.videoConfig;
  audioConfig: any = environment.audioConfig;
  subtitle: any = PART_1_SUBTITLES;
  supportedAsset = SupportedAsset;
  reloadingPreview: boolean = false;
  enableFreeFormAnswer: boolean = false;
  questionType!: Option;
  showQuestionEditor: boolean = false;
  courseConst = COURSE_CONST;

  constructor(
    private fb: UntypedFormBuilder,
    private courseService: CourseService,
    public translationService: TranslationService,
    public globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
    this.isFormSubmit = false;
    this.prepareStepTwo();
  }

  /* Default Asset Type Setting  */
  get assetType(): any {
    if (
      this.selectedOption?.value === LAYER_OPTIONS.L1_OP_2 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_L2_OP_1 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_L2_OP_3
    ) {
      return SupportedAsset.IMAGE;
    } else if (
      this.selectedOption?.value === LAYER_OPTIONS.L1_OP_3 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_OP_4
    ) {
      return SupportedAsset.VIDEO;
    } else {
      return null;
    }
  }

  get courseObject(): any {
    return this.courseService.retrieveCourseObject() || {};
  }
  /*  */

  prepareStepTwo() {
    const step1 = this.courseService.retrieveCourseObject()?.stepOne || null;
    if (step1) {
      this.totalParts = step1.layer;

      this.selectedOption =
        [...LAYER_ONE_OPTIONS, ...LAYER_ONE_AND_TWO_OPTIONS].find(
          (option) => option.value === step1.layerOptions,
        ) || null;

      if (this.selectedOption) {
        this.setupFormBasedOnSelectedOption();
      }

      if (
        this.selectedOption == LAYER_ONE_AND_TWO_OPTIONS[3] ||
        this.selectedOption == LAYER_ONE_AND_TWO_OPTIONS[4]
      ) {
        // Setting Up Step Two For Quiz Slides
        this.questionType = QUESTION_TYPE_DATA.find(x => x?.["courseMappedValue"] == this.selectedOption?.value) || LAYER_ONE_AND_TWO_OPTIONS[3];
        this.populateCourseStepTwoDataForQuizSlides();
      }
      else {
        // Setting Up Step Two For Media Assets Slides
        this.populateCourseStepTwoDataForMediaSlides();
      }
    }
  }

  private handleUploadButtonState(): void {
    if (
      (this.assetType == SupportedAsset.IMAGE &&
        this.fileList.length === this.imageConfig.maxFileCount) ||
      (this.assetType == SupportedAsset.VIDEO &&
        this.fileList.length === this.videoConfig.maxFileCount) ||
      (this.assetType == SupportedAsset.AUDIO &&
        this.fileList.length === this.audioConfig.maxFileCount)
    ) {
      this.disableUploadButton = true;
    } else {
      this.disableUploadButton = false;
    }
  }

  private populateCourseStepTwoDataForMediaSlides(): void {
    const courseBuilderSteps: any =
      this.courseService.retrieveCourseObject() || {};

    const editorText: string = courseBuilderSteps?.stepTwo?.editor || "";
    const slideTitle: string = courseBuilderSteps?.stepTwo?.slideTitle || "";
    const buttonData: string[] = courseBuilderSteps?.stepTwo?.buttons || [];
    const storedImages: string[] = courseBuilderSteps?.stepTwo?.images || [];
    const storedVideos: string[] = courseBuilderSteps?.stepTwo?.videos || [];
    const storedAudios: string[] = courseBuilderSteps?.stepTwo?.audios || [];
    const stordHotspots: any[] = courseBuilderSteps?.stepTwo?.hotspots || [];

    this.populateFormSimpleValues(editorText, slideTitle);

    if (buttonData.length > 0) {
      this.populateButtonValues(buttonData);
    }
    if (storedImages.length > 0 || storedVideos.length > 0) {
      this.populateFormAndFileListWithStoredAssets(
        this.assetType == SupportedAsset.IMAGE
          ? storedImages
          : this.assetType == SupportedAsset.VIDEO
            ? storedVideos
            : storedAudios,
      );
    }
    if (storedAudios.length > 0) {
      this.populateFormAndAudioFileList(storedAudios);
    }
    let layerOptions = courseBuilderSteps.stepOne.layerOptions;

    if (stordHotspots?.length > 0 && layerOptions == LAYER_OPTIONS.L1_L2_OP_3) {
      this.populateFormAndHotspotList(stordHotspots);
    } else if (courseBuilderSteps.stepTwo) {
      courseBuilderSteps.stepTwo.hotspots = [];
    }

    this.handleUploadButtonState();
  }

  private populateCourseStepTwoDataForQuizSlides(): void {
    const courseBuilderSteps: any =
      this.courseService.retrieveCourseObject() || {};

    const questionTitle = courseBuilderSteps?.stepTwo?.questionTitle || "";
    const questionText = courseBuilderSteps?.stepTwo?.questionText || "";
    const question = courseBuilderSteps?.stepTwo?.question || "";
    const tags = courseBuilderSteps?.stepTwo?.tags || [];
    const possibleAnswer = courseBuilderSteps?.stepTwo?.possibleAnswer || [];
    const answerLabel = courseBuilderSteps?.stepTwo?.answerLabels || [];
    const answer = courseBuilderSteps?.stepTwo?.answer || [];
    const correctAnswer = courseBuilderSteps?.stepTwo?.correctAnswer || [];

    this.validateForm?.get(QUIZ_CONST.QUESTION_TITLE)?.setValue(questionTitle);
    this.validateForm?.get(QUIZ_CONST.QUESTION_TEXT)?.setValue(questionText);
    this.validateForm?.get(QUIZ_CONST.QUESTION)?.setValue(question);

    this.populateQuizFormArrayValues(
      tags,
      possibleAnswer,
      answerLabel,
      answer,
      correctAnswer,
    );
  }

  private populateQuizFormArrayValues(
    tags: any,
    possibleAnswer: any,
    answerLabel: any,
    answers: any,
    correctAnswer: any,
  ) {
    const tagsArray = this.validateForm?.get(QUIZ_CONST.TAGS) as FormArray;
    tags.forEach((tag: any) => {
      tagsArray.push(this.fb.control(tag));
    });

    const possibleAnswerArray = this.validateForm?.get(
      QUIZ_CONST.POSSIBLE_ANSWER,
    ) as FormArray;
    possibleAnswer.forEach((ans: any) => {
      possibleAnswerArray.push(this.fb.control(ans));
    });

    const answerLabelArray = this.validateForm?.get(
      QUIZ_CONST.ANSWER_LABEL,
    ) as FormArray;

    answerLabel.forEach((labelObj: any) => {
      const labelGroup = this.fb.group({
        label: [labelObj.label, Validators.maxLength(200)],
        correct_answer: [labelObj.correct_answer],
      });
      answerLabelArray.push(labelGroup);
    });

    const answersArray = this.validateForm?.get(
      QUIZ_CONST.ANSWERS,
    ) as FormArray;
    answers.forEach((answers: string) => {
      answersArray.push(this.fb.control(answers));
    });

    const correctAnswerArray = this.validateForm?.get(
      QUIZ_CONST.CORRECT_ANSWER,
    ) as FormArray;
    correctAnswer.forEach((answers: string) => {
      correctAnswerArray.push(this.fb.control(answers));
    });
  }

  private populateFormSimpleValues(
    editorText: string,
    slideTitle: string,
  ): void {
    this.validateForm?.get(COURSE_CONST.EDITOR)?.setValue(editorText);
    this.validateForm?.get(COURSE_CONST.SLIDE_TITLE)?.setValue(slideTitle);
  }

  private populateButtonValues(buttons: any) {
    const buttonArray = this.validateForm?.get(
      COURSE_CONST.BUTTONS,
    ) as FormArray;
    buttons.forEach((buttonText: string) => {
      buttonArray.push(this.fb.control(buttonText));
    });
  }

  private populateFormAndFileListWithStoredAssets(storedAssets: any[]): void {
    const assetArray = this.validateForm?.get(
      this.assetType == SupportedAsset.IMAGE
        ? COURSE_CONST.IMAGES
        : this.assetType == SupportedAsset.VIDEO
          ? COURSE_CONST.VIDEOS
          : COURSE_CONST.AUDIOS,
    ) as FormArray;

    storedAssets.forEach((file: any, index) => {
      assetArray.push(this.fb.control(file));
      this.fileList.push({
        uid: index.toString(),
        id: file.id,
        name: file.name ? file.name : `${this.assetType}-${index}`,
        status: "done",
        url: file.url,
        thumbUrl:
          this.assetType == SupportedAsset.IMAGE ||
            this.assetType == SupportedAsset.VIDEO
            ? file.thumbUrl
              ? file.thumbUrl
              : file.url
            : "",
      });
    });
  }

  private populateFormAndAudioFileList(storedAssets: any[]): void {
    const assetArray = this.validateForm?.get(COURSE_CONST.AUDIOS) as FormArray;
    storedAssets.forEach((file: any, index) => {
      assetArray.push(this.fb.control(file));
      this.audioFileList.push({
        uid: index.toString(),
        id: file.id,
        name: file.name ? file.name : `${this.assetType}-${index}`,
        status: "done",
        url: file.url,
        thumbUrl: "",
      });
    });
  }

  private populateFormAndHotspotList(storedHotspots: any[]): void {
    const assetArray = this.validateForm?.get(
      COURSE_CONST.HOTSPOTS,
    ) as FormArray;
    storedHotspots.forEach((hotspot: any, index) => {
      assetArray.push(this.fb.control(hotspot));
      this.hotspotList.push({
        uid: index.toString(),
        id: hotspot.id,
        data: hotspot.data ? hotspot.data : `${this.assetType}-${index}`,
        coords: hotspot.coords,
      });
    });
  }

  private setupFormBasedOnSelectedOption(): void {
    switch (this.selectedOption?.value) {
      case LAYER_OPTIONS.Text_Only:
        this.setupTextOnlyForm();
        break;
      case LAYER_OPTIONS.L1_OP_2:
        this.setupL1Op2Form();
        break;
      case LAYER_OPTIONS.L1_OP_3:
        this.setupL1Op3Form();
        break;
      case LAYER_OPTIONS.L1_OP_4:
        this.setupL1Op4Form();
        break;
      case LAYER_OPTIONS.L1_L2_OP_1:
        this.setupL1L2Op1Form();
        break;
      case LAYER_OPTIONS.L1_L2_OP_2:
        this.setupL1L2Op2Form();
        break;
      case LAYER_OPTIONS.L1_L2_OP_3:
        this.setupL1L2Op3Form();
        break;
      case LAYER_OPTIONS.L1_L2_OP_4:
      case LAYER_OPTIONS.L1_L2_OP_5:
        this.showQuestionEditor = true;
        this.setupL1L2Op4_or_L1L2Op5Form();
        break;
      default:
        this.setupDefaultForm();
        break;
    }
  }

  // Text Only Form
  private setupTextOnlyForm(): void {
    this.validateForm = this.fb.group({
      slideTitle: ["", [Validators.required]],
      editor: ["", [Validators.required]], // Quill editor,
      audios: this.fb.array([]),
    });
  }

  // Text & Images Form
  private setupL1Op2Form(): void {
    this.validateForm = this.fb.group({
      slideTitle: ["", [Validators.required]],
      editor: ["", [Validators.required]],
      images: this.fb.array([], [Validators.required, Validators.minLength(1)]),
      audios: this.fb.array([]),
    });
  }

  // Text & Video Form
  private setupL1Op3Form(): void {
    this.validateForm = this.fb.group({
      slideTitle: ["", [Validators.required]],
      editor: ["", [Validators.required]],
      videos: this.fb.array([], [Validators.required, Validators.minLength(1)]),
      audios: this.fb.array([]),
    });
  }

  // Video Only Form
  private setupL1Op4Form(): void {
    this.validateForm = this.fb.group({
      slideTitle: ["", [Validators.required]],
      videos: this.fb.array([], [Validators.required, Validators.minLength(1)]),
      audios: this.fb.array([]),
    });
  }

  private setupL1L2Op1Form(): void {
    this.validateForm = this.fb.group({
      slideTitle: ["", [Validators.required]],
      editor: ["", [Validators.required]],
      images: this.fb.array([], [Validators.required, Validators.minLength(1)]),
      audios: this.fb.array([]),
    });
  }

  private setupL1L2Op2Form(): void {
    this.validateForm = this.fb.group({
      slideTitle: ["", [Validators.required]],
      editor: ["", [Validators.required]],
      buttons: this.fb.array(
        [],
        [Validators.required, Validators.minLength(1)],
      ),
      audios: this.fb.array([]),
    });
  }

  private setupL1L2Op3Form(): void {
    this.validateForm = this.fb.group({
      slideTitle: ["", [Validators.required]],
      editor: ["", [Validators.required]],
      images: this.fb.array(
        [],
        [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
      ),
      audios: this.fb.array([]),
      hotspots: this.fb.array([]),
    });
  }

  // Setup form for Multi-Select or Multi-Choice Questions
  private setupL1L2Op4_or_L1L2Op5Form(): void {
    this.validateForm = this.fb.group({
      questionTitle: ["", []],
      question: ["", [Validators.required]],
      tags: this.fb.array([]),
      freeFormQuestion: [false, [Validators.required]],
      answerLabels: this.fb.array([]),
      horizontalLabels: this.fb.array([]),
    });
  }

  private setupDefaultForm(): void {
    this.validateForm = this.fb.group({
      slideTitle: ["", [Validators.required]],
      editor: ["", [Validators.required]],
      audios: this.fb.array([]),
    });
  }

  private isAnyCorrect(answerLabels: { correct_answer: boolean }[]): boolean {
    return answerLabels.some((label) => label.correct_answer);
  }

  deletingImage(event: any) {
    this.imageDelete.emit(event);
  }

  submitForm(handleSaveOnly?: boolean): boolean {
    this.isFormSubmit = true;
    const courseBuilderSteps: any =
      this.courseService.retrieveCourseObject() || {};

    if (
      (this.selectedOption?.value == LAYER_OPTIONS.L1_L2_OP_4 || this.selectedOption?.value == LAYER_OPTIONS.L1_L2_OP_5) &&
      !this.isAnyCorrect(this.validateForm.value.answerLabels) &&
      (this.questionType.value == "1" || this.questionType.value == "2")
    ) {
      return false;
    }

    if (
      (this.validateForm.valid &&
        LAYER_OPTIONS.L1_L2_OP_3 != courseBuilderSteps.stepOne.layerOptions) ||
      handleSaveOnly ||
      (this.validateForm.valid &&
        LAYER_OPTIONS.L1_L2_OP_3 == courseBuilderSteps.stepOne.layerOptions &&
        this.validateForm.value.hotspots?.length != 0)
    ) {
      const stepTwo = this.validateForm.value;
      this.courseService.updateCourseObject(
        this.validateForm.value,
        COURSE_CONST.STEP_TWO,
      );
      // Emit the form data for any parent component or service that might be listening
      this.formSubmitted.emit(stepTwo);
    }
    else {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsTouched();
        this.validateForm.controls[i].updateValueAndValidity();
      }
      return false;
    }
    return true;
  }

  displayUploadValidation() {
    return this.isFormSubmit && !this.fileList.length;
  }

}
