import { environment } from "src/environments/environment";
import { Option } from "./create-course.interfaces";

export const SLIDE_STEP = {
  ADD_NEW_SLIDE: "Add New Slide",
  SETUP_LAYER: "Setup Layer",
  ADD_LAYER_ONE_CONFIG: "Add Layer One Config",
  ADD_LAYER_TWO_CONFIG: "Add Layer Two Config",
};

export const STORAGE_KEY = {
  COURSE_BUILDER_STEPS: "courseBuilderSteps",
};

export const CATEGORIES = [
  "coursecontent",
  "quiz",
  "questionnaire",
  "etc",
];

export const COURSE_CONST = {
  ONE: "1",
  TWO: "2",
  ID: "id",
  REG_ID: "regId",
  SLIDE: "slide",
  STEP: "step",
  EVENT_NAME: "event-name",
  CATEGORY_NAME: "category-name",
  STEP_ONE_DATA: "stepOne",
  UPDATE_MODE: "UPDATE",
  CREATE_MODE: "CREATE",
  COMPLETED: "Completed",
  NOT_COMPLETED: "Not-Completed",
  STEP_ONE: "stepOne",
  LAYER: "layer",
  LAYER_OPTIONS: "layerOptions",
  ENABLED_NEXT: "enabledNext",
  VIDEO_OPTION: "videoOption",
  PAGE_LAYOUT_OPTION: "pageLayoutOption",
  IMAGES: "images",
  VIDEOS: "videos",
  AUDIOS: "audios",
  HOTSPOTS: "hotspots",
  BUTTONS: "buttons",
  EDITOR: "editor",
  SLIDE_TITLE: "slideTitle",
  STEP_TWO: "stepTwo",
  STEP_THREE: "stepThree",
  MODAL_FORMS: "modalForms",
  IMAGE: "image",
  PREVIEW_MODE: "previewMode",
  PREVIEW_LAST_SLIDE: "preViewLastSlide",
  TRUE: "true",
  BUTTON_FILL_COLOR: "buttonFillColor",
  BUTTON_TEXT_COLOR: "buttonTextColor",
  BUTTON_BORDER_COLOR: "buttonBorderColor",
  COURSE_CATEGORY: "coursecontent",
};

export const LAYER_OPTIONS = {
  L1: '1',
  L2: '2',
  Text_Only: "L1-OP-1",
  L1_ID: "L1-OP",
  L2_ID: "L1-L2-OP",
  L1_L2_OP_1: "L1-L2-OP-1",
  L1_L2_OP_2: "L1-L2-OP-2",
  L1_L2_OP_3: "L1-L2-OP-3",
  L1_L2_OP_4: "L1-L2-OP-4",
  L1_L2_OP_5: "L1-L2-OP-5",
  L1_OP_2: "L1-OP-2",
  L1_OP_3: "L1-OP-3",
  L1_OP_4: "L1-OP-4",
  L1_OP_5: "L1-OP-5",
};

// Type identification for slide based on number of layer
export const SLIDE_TYPE_OPTIONS: Option[] = [
  {
    label: "1 Layer Page", // Layer 1
    value: "1",
  },
  {
    label: "2 Layer Page", // Layer 1 & 2
    value: "2",
  },
];

// Layer 1 Options
export const LAYER_ONE_OPTIONS: Option[] = [
  {
    label: "Text Only",
    value: "L1-OP-1",
    disabled: false,
  },
  {
    label: "Text and up to 3 Static Image(s) / Icon(s)",
    value: "L1-OP-2",
    disabled: false,
  },
  {
    label: "Text and 1 Static Video / Animation",
    value: "L1-OP-3",
    disabled: false,
  },
  {
    label: "1 Static Video / Animation Only",
    value: "L1-OP-4",
    disabled: false,
  },
  {
    label: "Consent Page",
    value: "L1-OP-5",
    disabled: false,
  },
];

// Layer 1 & 2 Options
export const LAYER_ONE_AND_TWO_OPTIONS: Option[] = [
  {
    label: "Text and up to 3 Clickable Image(s) / Icon(s)",
    value: "L1-L2-OP-1",
  },
  {
    label: "Text and up to 8 Clickable Button(s)",
    value: "L1-L2-OP-2",
  },
  {
    label: `Text and up to ${environment.hotspotsCount} hotspots on Image(s) / Icon(s)`,
    value: "L1-L2-OP-3",
  },
  {
    label: "Knowledge check - MultiChoice",
    value: "L1-L2-OP-5",
    mappedQuestionType: "1"
  },
  {
    label: "Knowledge check - MultiSelect",
    value: "L1-L2-OP-4",
    mappedQuestionType: "2"
  },
];

// Layer 2 Options
export const LAYER_TWO_OPTIONS: Option[] = [
  {
    label: "Text Only",
    value: "L1-OP-1",
  },
];

// Page Completion Rule

export const COMPLETION_NEXT_RULE = {
  SLIDE_LOADED: "Slide-Loaded",
  LAYER_2_COMPLETED: "All-Actions-Completed",
  CONSENT_PROVIDED: "Consent-Provided"
};

export const CompletionRule: Option[] = [
  {
    label: "The user must see the Layer 1 page ONCE",
    value: COMPLETION_NEXT_RULE.SLIDE_LOADED,
  },
  {
    label: "User must see ALL Layer 2 page(s) ONCE",
    value: COMPLETION_NEXT_RULE.LAYER_2_COMPLETED,
  },
  {
    label: "User must select one button on the consent page",
    value: COMPLETION_NEXT_RULE.CONSENT_PROVIDED,
  },
];

// Video Player Option

export const VIDEO_PLAYER_CONSTANT = {
  AUTOMATIC: "Automatic",
  MANUAL: "Manual",
};

export const VIDEO_PLAYER_OPTIONS: Option[] = [
  {
    label: "Video Plays Automatically On Page Load",
    value: VIDEO_PLAYER_CONSTANT.AUTOMATIC,
  },
  {
    label: "Video Plays Manually Based On User Actions",
    value: VIDEO_PLAYER_CONSTANT.MANUAL,
  },
];

export const BUTTON_LAYOUT_CONSTANT = {
  VERTICAL: "Vertical",
  HORIZONTAL: "Horizontal",
};

export const BUTTON_LAYOUT: Option[] = [
  {
    label: "In a vertical column",
    value: BUTTON_LAYOUT_CONSTANT.VERTICAL,
  },
];

export const BUTTON_APPEARANCE_CONSTANT = {
  ONLOAD: "Onload",
};

export const BUTTON_APPEARANCE: Option[] = [
  {
    label: "Immediately on page load",
    value: BUTTON_APPEARANCE_CONSTANT.ONLOAD,
  },
];

// Button Option

export const BUTTON_FILL_CONSTANT = {
  VERTICAL: "Vertical",
  HORIZONTAL: "Horizontal",
};

export const BUTTON_COLOR_PROPERTIES: Option[] = [
  {
    label: "Default Theme",
    value: "default",
  },
  {
    label: "Black",
    value: "black",
  },
  {
    label: "White",
    value: "white",
  },
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Green",
    value: "green",
  },
  {
    label: "Blue",
    value: "blue",
  },
];

export const BUTTON_COLOR_ACTIVE_PROPERTIES: Option[] = [
  {
    label: "Default Theme",
    value: "default",
  },
  {
    label: "Black",
    value: "black",
  },
  {
    label: "White",
    value: "white",
  },
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Green",
    value: "green",
  },
  {
    label: "Blue",
    value: "blue",
  },
];


// Page Layout Option

export const PAGE_LAYOUT_CONSTANT = {
  FULL_PAGE: "Full",
  HALF_TEXT_HALF_IMAGE: "Partial-Text-Image",
  HALF_TEXT_HALF_VIDEO: "Partial-Text-Video",
  HALF_TEXT_HALF_BUTTON: "Partial-Text-Button",
};

export const PAGE_LAYOUT_OPTIONS: Option[] = [
  {
    label: "Full page",
    value: PAGE_LAYOUT_CONSTANT.FULL_PAGE,
  },
  {
    label: "1/2 Page Text & 1/2 Page Video",
    value: PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_VIDEO,
  },
  {
    label: "1/2 Page Text & 1/2 Page Image(s)",
    value: PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_IMAGE,
  },
  {
    label: "1/2 Page Text & 1/2 Page Button(s)",
    value: PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_BUTTON,
  },
];

// Step 2 Component

// Instruction
export const PART_1_SUBTITLES = {
  L1_L2_OP_1: "Paste the text and upload up file(s)",
  "L1-0P-1": "Paste the text into the box below and use the tools to format it",
  "L1-OP-2": "Paste the text and upload up file(s)",
  "L1-OP-3": "Paste the text and upload up file(s)",
  "L1-OP-4": "Upload File(s)",
};

// Step 2 Component
// Instruction
export const PART_2_UPLOADING_INSTRUCTIONS = {
  image: {
    TITLE: "UPLOAD_IMAGES",
    MIN_SIZE: "MINIMUM_IMAGE_SIZE",
    MIN_ICON_SIZE: "MINIMUM_ICON_SIZE",
    MAX_SIZE: "MAXIMUM_IMAGE_ICON_SIZE",
    ALERT_SIZE: "ALERT_IMAGE_SIZE",
    ALLOWABLE_TYPE: "ACCEPTED_IMAGE_EXT",
    ALLOWABLE_COUNT: "MAXIMUM_NUMBER_OF_IMAGES",
    ALERT_MAX_ALLOWED_COUNT: "ALERT_MAX_IMAGE_CONDITION",
    DELETE_ASSET_LAYER: "ALERT_DELETING_IMAGE_LAYER_2",
    ASSET_REQUIRED: "IMAGE_REQUIRED",
  },
  video: {
    TITLE: "UPLOAD_VIDEO",
    MIN_SIZE: "MINIMUM_VIDEO_SIZE",
    MIN_ICON_SIZE: "MINIMUM_GIF_SIZE",
    MAX_SIZE: "MAXIMUM_VIDEO_ICON_SIZE",
    ALERT_SIZE: "ALERT_VIDEO_SIZE",
    ALLOWABLE_TYPE: "ACCEPTED_VIDEO_EXT",
    ALLOWABLE_COUNT: "MAXIMUM_NUMBER_OF_VIDEOS",
    ALERT_MAX_ALLOWED_COUNT: "ALERT_MAX_VIDEO_CONDITION",
    DELETE_ASSET_LAYER: "ALERT_DELETING_VIDEO_LAYER_2",
    ASSET_REQUIRED: "VIDEO_REQUIRED",
  },
  audio: {
    TITLE: "UPLOAD_AUDIO",
    MIN_SIZE: "MINIMUM_AUDIO_SIZE",
    ALERT_SIZE: "ALERT_AUDIO_SIZE",
    ALLOWABLE_TYPE: "ACCEPTED_AUDIO_EXT",
    ALLOWABLE_COUNT: "MAXIMUM_NUMBER_OF_AUDIO",
    ALERT_MAX_ALLOWED_COUNT: "ALERT_MAX_AUDIO_CONDITION",
    MAX_SIZE: "MAXIMUM_AUDIO_SIZE",
    DELETE_ASSET_LAYER: "ALERT_DELETING_AUDIO_LAYER_2",
    ASSET_REQUIRED: "AUDIO_REQUIRED",
  },
};

// Type identification for slide based on content
export enum SlideType {
  QUIZ = "Quiz",
  CONTENT = "Content",
  CONSENT = "Consent"
}