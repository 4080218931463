import { Component, EventEmitter, Output } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { Option } from "../../create-course.interfaces";
import {
  COMPLETION_NEXT_RULE,
  CompletionRule,
  LAYER_OPTIONS,
  COURSE_CONST,
  LAYER_ONE_AND_TWO_OPTIONS,
  LAYER_ONE_OPTIONS,
  SLIDE_TYPE_OPTIONS,
  PAGE_LAYOUT_OPTIONS,
  VIDEO_PLAYER_OPTIONS,
  VIDEO_PLAYER_CONSTANT,
  PAGE_LAYOUT_CONSTANT,
  LAYER_TWO_OPTIONS,
  BUTTON_COLOR_PROPERTIES,
  BUTTON_LAYOUT,
  BUTTON_LAYOUT_CONSTANT,
  BUTTON_APPEARANCE_CONSTANT,
  BUTTON_APPEARANCE,
  BUTTON_COLOR_ACTIVE_PROPERTIES,
} from "../../data";
import { CourseService } from "../../../services/course.service";
import { Utils } from "src/app/shared/utils/utils";
import { Router } from "@angular/router";
import { ACTION_WHEN_ANSWERED_CORRECTLY, ACTION_WHEN_ANSWERED_INCORRECTLY, QUESTION_TYPE } from "src/app/create-quiz/data";
import { TranslationService } from "src/app/services/translation.service";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-step-one",
  templateUrl: "./step-one.component.html",
  styleUrls: ["./step-one.component.scss"],
})
export class StepOneComponent {
  validateForm!: UntypedFormGroup;
  currentLayerOptions!: Option[];
  slideTypeOptions!: Option[];
  pageLayoutOptions!: Option[];
  videoPlayerOptions!: Option[];
  buttonLayoutOptions!: Option[];
  buttonAppearancOption!: Option[];
  buttonConfigOptions!: Option[];
  buttonActiveTextColorOptions!: Option[];
  correctAnswerProceedingOptions!: Option[];
  incorrectAnswerProceedingOptions!: Option[];
  completionRuleOptions: Option[] = CompletionRule;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() layerOptionChanged: EventEmitter<any> = new EventEmitter();
  styleObject: any;
  courseDataObject: any;
  isLayerOptionChanged: boolean = false;

  // Conditional Form Field Rendering
  showVideoPlayingSelector: boolean = false;
  showButtonConfiguration: boolean = false;
  showMCQRelatedConfiguration: boolean = false;
  showConsentButtonConfiguration: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public courseService: CourseService,
    private router: Router,
    public translationService: TranslationService,
    private modalService: NzModalService,
  ) { }

  ngOnInit(): void {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
    this.prepareStepOne();
  }

  prepareStepOne() {
    const savedData = this.courseService.retrieveCourseObject();
    this.courseDataObject = savedData;
    // Setting Default Data For Selectors
    this.currentLayerOptions = LAYER_ONE_OPTIONS;
    this.slideTypeOptions = SLIDE_TYPE_OPTIONS;
    this.videoPlayerOptions = VIDEO_PLAYER_OPTIONS;
    this.buttonConfigOptions = BUTTON_COLOR_PROPERTIES;
    this.buttonActiveTextColorOptions = BUTTON_COLOR_ACTIVE_PROPERTIES;
    this.buttonLayoutOptions = BUTTON_LAYOUT;
    this.buttonAppearancOption = BUTTON_APPEARANCE;

    let opts = ACTION_WHEN_ANSWERED_CORRECTLY;

    opts.forEach((option, index) => {
      option.disabled = index != 1;
    });

    let opts2 = ACTION_WHEN_ANSWERED_INCORRECTLY;

    opts2.forEach((option, index) => {
      option.disabled = index != 1;
    });

    this.correctAnswerProceedingOptions = opts;
    this.incorrectAnswerProceedingOptions = opts2;
    //
    const defaultLayer = savedData?.stepOne?.layer || COURSE_CONST.ONE;
    const defaultLayerOptions =
      savedData?.stepOne?.layerOptions || LAYER_OPTIONS.Text_Only;
    const enabledNext =
      savedData?.stepOne?.enabledNext || COMPLETION_NEXT_RULE.SLIDE_LOADED;
    const videoOption =
      savedData?.stepOne?.videoOption || VIDEO_PLAYER_CONSTANT.AUTOMATIC;
    const buttonBorderColorOption =
      savedData?.stepOne?.buttonBorderColor || BUTTON_COLOR_PROPERTIES[0].value;
    const buttonFillColorOption =
      savedData?.stepOne?.buttonFillColor || BUTTON_COLOR_PROPERTIES[0].value;
    const consentTrueButtonFillColorOption =
      savedData?.stepOne?.consentTrueButtonFillColor || BUTTON_COLOR_PROPERTIES[0].value;
    const consentFalseButtonFillColorOption =
      savedData?.stepOne?.consentFalseButtonFillColor || BUTTON_COLOR_PROPERTIES[0].value;
    const buttonTextColorOption =
      savedData?.stepOne?.buttonTextColor || BUTTON_COLOR_PROPERTIES[0].value;
    const buttonActiveColorOption =
      savedData?.stepOne?.buttonActiveColor || BUTTON_COLOR_PROPERTIES[4].value;
    const buttonActiveTextColorOption =
      savedData?.stepOne?.buttonActiveTextColor ||
      BUTTON_COLOR_PROPERTIES[2].value;
    const pageLayoutOption =
      savedData?.stepOne?.pageLayoutOption || PAGE_LAYOUT_CONSTANT.FULL_PAGE;
    const buttonLayoutOption =
      savedData?.stepOne?.buttonLayout || BUTTON_LAYOUT_CONSTANT.VERTICAL;
    const buttonAppearanceOption =
      savedData?.stepOne?.buttonAppearance || BUTTON_APPEARANCE_CONSTANT.ONLOAD;
    const defaultQuestionType = savedData?.stepOne?.questionType ||
      QUESTION_TYPE[0].value;
    const defaultCorrectAnswerProceeding =
      savedData?.stepOne?.correctAnswerProceeding ||
      ACTION_WHEN_ANSWERED_CORRECTLY[1].value;
    const defaultIncorrectAnswerProceeding =
      savedData?.stepOne?.incorrectAnswerProceeding ||
      ACTION_WHEN_ANSWERED_INCORRECTLY[1].value;
    const defaultSlideId = Date.now().toString(); // You need to implement generateUniqueSlideId function

    this.controlFormFieldRendering(defaultLayerOptions);

    if (defaultLayer == COURSE_CONST.TWO) {
      this.currentLayerOptions = LAYER_ONE_AND_TWO_OPTIONS;
      this.completionRuleOptionConfig(COURSE_CONST.TWO)
    } 
    else if (defaultLayer == COURSE_CONST.ONE) {
      this.completionRuleOptionConfig(
        defaultLayerOptions != LAYER_ONE_OPTIONS[4].value
          ? COURSE_CONST.ONE
          : LAYER_ONE_OPTIONS[4].value
      )
    }

    this.validateForm = this.fb.group({
      layer: [defaultLayer, [Validators.required]],
      layerOptions: [defaultLayerOptions, [Validators.required]],
      enabledNext: [enabledNext, [Validators.required]],
      // Video Player Configuration
      videoOption: [videoOption, [Validators.required]],
      // Button Configuration
      buttonBorderColor: [buttonBorderColorOption, [Validators.required]],
      buttonFillColor: [buttonFillColorOption, [Validators.required]],
      buttonTextColor: [buttonTextColorOption, [Validators.required]],
      buttonActiveColor: [buttonActiveColorOption, [Validators.required]],
      buttonLayout: [buttonLayoutOption, [Validators.required]],
      buttonActiveTextColor: [
        buttonActiveTextColorOption,
        [Validators.required],
      ],
      buttonAppearance: [buttonAppearanceOption, [Validators.required]],
      // Quiz Component Configuration
      questionType: [defaultQuestionType],
      questionPoint: [1],
      maximumAttempt: [1],
      correctAnswerPoint: [1],
      slideId: [defaultSlideId],
      isQuestionMandatoryForQssp: [true],
      correctAnswerProceeding: [defaultCorrectAnswerProceeding, [Validators.required]],
      incorrectAnswerProceeding: [defaultIncorrectAnswerProceeding, [Validators.required]],
      // Page Layout Option
      pageLayoutOption: [pageLayoutOption, [Validators.required]],
      // Consent Buttons
      consentTrueButtonFillColor: [consentTrueButtonFillColorOption, [Validators.required]],
      consentFalseButtonFillColor: [consentFalseButtonFillColorOption, [Validators.required]],
    });

    this.pageLayoutOptions = PAGE_LAYOUT_OPTIONS.filter(
      (x: Option) => x.value == this.pageLayoutControl?.value,
    );

    // Only call onLayerOptionSelect if the saved layer option is different from the default
    const savedLayerOption = savedData?.stepOne?.layerOptions;
    if (savedLayerOption && savedLayerOption !== defaultLayerOptions) {
      this.onLayerOptionSelect(defaultLayerOptions);
    }  
  }

  submitForm(handleSaveOnly?: boolean): boolean {
    if (this.validateForm.valid || handleSaveOnly) {
      this.formSubmitted.emit(this.validateForm.value);
      this.courseService.updateCourseObject(
        this.validateForm.value,
        COURSE_CONST.STEP_ONE,
      );
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.validateForm.valid;
  }

  onLayerSelect(selectedValue: any): void {
    this.layer?.setValue(selectedValue);

    if (selectedValue == COURSE_CONST.TWO) {
      this.currentLayerOptions = LAYER_ONE_AND_TWO_OPTIONS;
      this.completionRuleOptionConfig(COURSE_CONST.TWO);
      this.handleStepOneLayerOptionChange(LAYER_OPTIONS.L1_L2_OP_1);

      this.validateForm
        ?.get(COURSE_CONST.LAYER_OPTIONS)
        ?.setValue(LAYER_OPTIONS.L1_L2_OP_1);
      this.validateForm
        ?.get(COURSE_CONST.ENABLED_NEXT)
        ?.setValue(COMPLETION_NEXT_RULE.LAYER_2_COMPLETED);
    }
    else {
      this.currentLayerOptions = LAYER_ONE_OPTIONS;
      this.completionRuleOptionConfig(COURSE_CONST.ONE);
      this.handleStepOneLayerOptionChange(LAYER_OPTIONS.Text_Only);

      this.validateForm
        ?.get(COURSE_CONST.LAYER_OPTIONS)
        ?.setValue(LAYER_OPTIONS.Text_Only);
      this.validateForm
        ?.get(COURSE_CONST.ENABLED_NEXT)
        ?.setValue(COMPLETION_NEXT_RULE.SLIDE_LOADED);
    }
  }

  handleStepOneLayerOptionChange(selectedValue: any) {
    // Making Sure That Step 2 & Step 3 Layer Data Is Removed
    if (
      (
        this.courseDataObject?.stepTwo ||
        this.courseDataObject?.stepThree
      ) &&
      selectedValue != this.courseDataObject?.stepOne?.layerOptions &&
      !this.isLayerOptionChanged
    ) {
      const modalRef: NzModalRef = this.modalService.create({
        nzTitle: this.translationService.translate("CONFIRMATION"),
        nzContent: this.translationService.translate("ALERT_CHANGING_LAYER_OPTION"),
        nzWidth: "40%",
        nzClosable: false,
        nzMaskClosable: false,
        nzFooter: [
          {
            label: this.translationService.translate("YES"),
            onClick: () => {
              this.layerOptionChanged.emit(true);
              this.isLayerOptionChanged = true;
              modalRef.close();
            },
          },
          {
            label: this.translationService.translate("CANCEL"),
            onClick: () => {
              this.isLayerOptionChanged = false;
              this.layer?.setValue(this.courseDataObject?.stepOne?.layer);
              this.onLayerOptionSelect(this.courseDataObject?.stepOne?.layerOptions);
              modalRef.close();
            },
          }
        ]
      });
    }
    this.onLayerOptionSelect(selectedValue);
  }

  onLayerOptionSelect(selectedValue: any): void {
    this.controlFormFieldRendering(selectedValue);
    this.completionRuleOptionConfig(this.layer.value);

    this.validateForm
      ?.get(COURSE_CONST.ENABLED_NEXT)
      ?.setValue(COMPLETION_NEXT_RULE.SLIDE_LOADED);

    this.validateForm
      ?.get(COURSE_CONST.LAYER_OPTIONS)
      ?.setValue(selectedValue)

    // Modifying Page Layout Field Options
    switch (selectedValue) {
      // Text OR Video Only
      case LAYER_ONE_OPTIONS[0].value:
        this.pageLayoutControl?.setValue(PAGE_LAYOUT_CONSTANT.FULL_PAGE);
        break;
      // Half Text & Half Image
      case LAYER_ONE_OPTIONS[1].value:
      case LAYER_ONE_AND_TWO_OPTIONS[0].value:
        this.pageLayoutControl?.setValue(
          PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_IMAGE,
        );
        break;
      // Half Text & Half Video
      case LAYER_ONE_OPTIONS[2].value:
        this.pageLayoutControl?.setValue(
          PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_VIDEO,
        );
        break;
      // Half Text & Half Button For Consent Page
      case LAYER_ONE_OPTIONS[4].value:
        this.pageLayoutControl?.setValue(
          PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_BUTTON,
        );
        this.completionRuleOptionConfig(LAYER_ONE_OPTIONS[4].value)
        this.validateForm
          ?.get(COURSE_CONST.ENABLED_NEXT)
          ?.setValue(COMPLETION_NEXT_RULE.CONSENT_PROVIDED);
        break;
      case LAYER_ONE_AND_TWO_OPTIONS[1].value:
        this.pageLayoutControl?.setValue(
          PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_BUTTON,
        );
        break;
      case LAYER_ONE_AND_TWO_OPTIONS[3].value:
      case LAYER_ONE_AND_TWO_OPTIONS[4].value:
        this.validateForm
          ?.get("questionType")
          ?.setValue(selectedValue == LAYER_ONE_AND_TWO_OPTIONS[3].value ? "1" : "2");
        break;
      default:
        this.pageLayoutControl?.setValue(PAGE_LAYOUT_CONSTANT.FULL_PAGE);
        break;
    }

    this.pageLayoutOptions = PAGE_LAYOUT_OPTIONS.filter(
      (x: Option) => x.value == this.pageLayoutControl?.value,
    );
  }

  controlFormFieldRendering(layerValue: string) {
    // Reseting Default Configuration
    this.showVideoPlayingSelector = false;
    this.showButtonConfiguration = false;
    this.showMCQRelatedConfiguration = false;
    this.showConsentButtonConfiguration = false;

    switch (layerValue) {
      // Controlling Video Player Selector Rendering
      case LAYER_ONE_OPTIONS[2].value:
      case LAYER_ONE_OPTIONS[3].value:
        this.showVideoPlayingSelector = true;
        break;
      // Controlling Button Configuration Rendering
      case LAYER_ONE_AND_TWO_OPTIONS[1].value:
        this.showButtonConfiguration = true;
        break;
      // Controlling MCQ Configuration
      case LAYER_ONE_AND_TWO_OPTIONS[3].value:
      case LAYER_ONE_AND_TWO_OPTIONS[4].value:
        this.showMCQRelatedConfiguration = true;
        break;
      // Controlling Button Configuration Rendering
      case LAYER_ONE_OPTIONS[4].value:
        this.showConsentButtonConfiguration = true;
        break;
      default:
        break;
    }
  }

  /* Form Control Accessor */
  get pageLayoutControl(): any {
    return this.validateForm?.get(COURSE_CONST.PAGE_LAYOUT_OPTION);
  }

  get layer(): any {
    return this.validateForm?.get(COURSE_CONST.LAYER);
  }

  get layerControl(): any {
    return this.validateForm?.get(COURSE_CONST.LAYER_OPTIONS);
  }

  completionRuleOptionConfig(selectedValue: string) {
    this.completionRuleOptions = CompletionRule;
    switch (selectedValue) {
      case COURSE_CONST.TWO:
        this.completionRuleOptions = this.completionRuleOptions.slice(0, 2);
        break;
      case LAYER_ONE_OPTIONS[4].value:
        this.completionRuleOptions = this.completionRuleOptions.slice(2);
        break;
      default:
        this.completionRuleOptions = this.completionRuleOptions.slice(0, 1);
        break;
    }
  }

  getFormvalue(FormControlName: string, isButtonColor?: boolean): any {
    let value: string = this.validateForm?.get(FormControlName)?.value;
    return BUTTON_COLOR_PROPERTIES.find(x => x.value == value);
  }
}
