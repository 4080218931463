import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { Option } from "../../../create-course/create-course.interfaces";
import { COURSE_CONST } from "../../../create-course/data";
import { CourseService } from "../../../services/course.service";
import { Utils } from "../../../core/utils";
import { Router } from "@angular/router";
import {
  ACTION_WHEN_ANSWERED_CORRECTLY,
  ACTION_WHEN_ANSWERED_INCORRECTLY,
  MAXIMUM_RETRY,
  QUESTION_TYPE,
} from "../../data";

@Component({
  selector: "app-quiz-step-one",
  templateUrl: "./quiz-step-one.component.html",
  styleUrls: ["./quiz-step-one.component.scss"],
})
export class QuizStepOneComponent {
  @Input() globalConfigs: any = {};
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  questionTypeConfiguration!: UntypedFormGroup;
  questionTypeOptions!: Option[];
  correctAnswerProceedingOptions!: Option[];
  incorrectAnswerProceedingOptions!: Option[];
  numberOfRetriesOption!: Option[];
  numberOfPointOption: Option[] = [
    {
      label: "1",
      value: "1",
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private courseService: CourseService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
    setTimeout(() => {
      this.prepareStepOne();
    }, 400);
  }

  prepareStepOne() {
    const savedData = this.courseService.retrieveCourseObject();
    var defaultQuestionTypeOptionValue = null;

    // Setting Default Data For Selectors
    this.questionTypeOptions = QUESTION_TYPE;

    if (this.globalConfigs && this.globalConfigs?.correctIncorrectAnswerExist) {
      this.questionTypeOptions = this.questionTypeOptions.filter(
        (option) => option.value === "1" || option.value === "2",
      );

      defaultQuestionTypeOptionValue = "1";
    } else if (
      this.globalConfigs &&
      !this.globalConfigs?.correctIncorrectAnswerExist
    ) {
      this.questionTypeOptions = this.questionTypeOptions.filter(
        (option) => option.value === "3" || option.value === "4",
      );
      defaultQuestionTypeOptionValue = "3";
    }

    this.correctAnswerProceedingOptions = ACTION_WHEN_ANSWERED_CORRECTLY;
    this.incorrectAnswerProceedingOptions = ACTION_WHEN_ANSWERED_INCORRECTLY;
    this.numberOfRetriesOption = MAXIMUM_RETRY;
    //

    const defaultSlideId = Date.now().toString(); // You need to implement generateUniqueSlideId function
    const defaultQuestionType =
      savedData?.stepOne?.questionType || defaultQuestionTypeOptionValue;
    const defaultEnableFreeFormAnswer =
      savedData?.stepOne?.enableFreeFormAnswer || false;
    const defaultEnableNumberLabels =
      savedData?.stepOne?.enableNumberLabels != null
        ? savedData?.stepOne?.enableNumberLabels
        : true;
    const defaultIsQuestionMandatoryForQssp =
      savedData?.stepOne?.isQuestionMandatoryForQssp || true;
    const defaultIsFreeFormQuestionMandatory =
      savedData?.stepOne?.isFreeFormQuestionMandatory || false;
    const defaultCorrectAnswerProceeding =
      savedData?.stepOne?.correctAnswerProceeding ||
      ACTION_WHEN_ANSWERED_CORRECTLY[1].value;
    const defaultIncorrectAnswerProceeding =
      savedData?.stepOne?.incorrectAnswerProceeding ||
      ACTION_WHEN_ANSWERED_INCORRECTLY[1].value;
    const defaultMaximumAttempt =
      savedData?.stepOne?.maximumAttempt || MAXIMUM_RETRY[0].value;
    //const defaultDisplayCheckAnswerButton = savedData?.stepOne?.displayCheckAnswerButton || false;
    const defaultQuestionPoint = savedData?.stepOne?.questionPoint || "1";
    const defaultCorrectAnswerPoint =
      savedData?.stepOne?.correctAnswerPoint || "1";

    this.questionTypeConfiguration = this.fb.group({
      slideId: [defaultSlideId, [Validators.required]], // Include the slideId field with the default value
      questionType: [defaultQuestionType, [Validators.required]],
      enableFreeFormAnswer: [
        defaultEnableFreeFormAnswer,
        [Validators.required],
      ],
      enableNumberLabels: [defaultEnableNumberLabels, [Validators.required]],
      isQuestionMandatoryForQssp: [
        defaultIsQuestionMandatoryForQssp,
        [Validators.required],
      ],
      isFreeFormQuestionMandatory: [
        defaultIsFreeFormQuestionMandatory,
        [Validators.required],
      ],
      correctAnswerProceeding: [
        defaultCorrectAnswerProceeding,
        [Validators.required],
      ],
      incorrectAnswerProceeding: [
        defaultIncorrectAnswerProceeding,
        [Validators.required],
      ],
      maximumAttempt: [defaultMaximumAttempt, [Validators.required]],
      //displayCheckAnswerButton: [defaultDisplayCheckAnswerButton, [Validators.required]],
      questionPoint: [defaultQuestionPoint, [Validators.required]],
      correctAnswerPoint: [defaultCorrectAnswerPoint, [Validators.required]],
    });
  }

  submitForm(handleSaveOnly?: boolean): boolean {
    if (this.questionTypeConfiguration.valid || handleSaveOnly) {
      this.formSubmitted.emit(this.questionTypeConfiguration.value);
      this.courseService.updateCourseObject(
        this.questionTypeConfiguration.value,
        COURSE_CONST.STEP_ONE,
      );
    } else {
      Object.values(this.questionTypeConfiguration.controls).forEach(
        (control) => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        },
      );
    }
    return this.questionTypeConfiguration.valid;
  }

  /* Form Control Accessor */
  get pageLayoutControl(): any {
    return this.questionTypeConfiguration?.get(COURSE_CONST.PAGE_LAYOUT_OPTION);
  }

  get layerControl(): any {
    return this.questionTypeConfiguration?.get(COURSE_CONST.LAYER_OPTIONS);
  }
}
