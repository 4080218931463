<!-- HTML -->
<div class="container">
    <header class="header" *ngIf="course">
      <div>
        <br>
        <h1>{{ courseCategory == "quiz" ? translationService.translate("MANAGE_QUIZ") : translationService.translate("MANAGE_EVENT") }}</h1>
        <h2>{{ translationService.translate("EVENT_NAME") }}: {{ course.title}}</h2>
        <h2 *ngIf="courseCategory == 'quiz'">{{ translationService.translate("FILE_NAME") }}: {{ course.globalConfigs?.fileName}}</h2>
      </div>
      <div class="header-buttons">
        <button class="btn secondary action-primary" (click)="navigateToGlobalSettings()">
          {{ translationService.translate("EDIT_GLOBAL_CONFIGURATION_SETTINGS") }}
        </button>
        <button class="btn secondary action-primary" (click)="goToEventList()"> 
          {{ translationService.translate(courseCategory == "quiz" ? "VIEW_QUIZES" : "VIEW_COURSES") }}
        </button>
        <button class="btn secondary action-primary" (click)="goToStep0()" *ngIf="!isCourseCompleted">
          {{ translationService.translate("EXIT_HOME") }}
        </button>
        <button class="btn secondary action-primary" (click)="resetPagesStatus()" *ngIf="enableResetPage()">
          {{ translationService.translate("RESET_PAGE_COMPLETED") }}
        </button>
      </div>
    </header>
  
    <div class="table-container">
      <table class="quiz-table" *ngIf="course">
        <thead>
          <tr>
            <th>{{translationService.translate("PAGE")}} #</th>
            <th>{{ courseCategory == "quiz" ? "Quiz Question Text" : "Course Content Page Title" }}</th>
            <th *ngIf="courseCategory == 'quiz'">{{ translationService.translate("QUIZ_TAGS") }}</th>
            <th>{{ translationService.translate("SLIDE_COMPLETED") }}</th>
            <th>{{ translationService.translate("ACTIONS") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let page of course.slides; let pageIndex = index" [ngClass]="{'blinking-bg' : recentlyMovedPage == pageIndex}">
            <td>{{pageIndex + 1}}</td>
            <td class="w-100">{{courseCategory == "quiz" ? page.stepTwo?.question : page.stepTwo?.question || page.stepTwo?.slideTitle}}</td>
            <td *ngIf="courseCategory == 'quiz'">
              <span class="tag" *ngFor="let tag of page?.stepTwo?.tags">{{tag}}</span>
            </td>
            <td>
              <div *ngIf="pageValidityStatus(page)" class="valid-icon valid-green">
                <span nz-icon nzType='check' nzTheme='outline'></span>
              </div>
              <div *ngIf="!pageValidityStatus(page)" class="valid-icon valid-red">
                <span nz-icon nzType='close' nzTheme='outline'></span>
              </div>
            </td>
            <td class="actions">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="d-flex justify-content-between align-items-center">
                    <button class="d-flex justify-content-center align-items-center action-btn action-primary text-capitalize" (click)="previewPage(pageIndex, page)">
                        <span nz-icon nzType="eye" nzTheme="outline" class="mx-1 font-size-14"></span>
                        {{ translationService.translate("PREVIEW_HOME") }}
                    </button>
                      <button *ngIf="!isCourseCompleted" class="d-flex justify-content-center align-items-center action-btn action-primary text-capitalize" (click)="editPage(pageIndex)">
                        <span nz-icon nzType="edit" nzTheme="outline" class="mx-1 font-size-14"></span>
                        {{ translationService.translate("EDIT") }}
                    </button>
                      <button *ngIf="showDeleteButton(pageIndex)" class="d-flex justify-content-center align-items-center action-btn action-del text-capitalize" (click)="deletePage(pageIndex)">
                        <span nz-icon nzType="delete" nzTheme="outline" class="mx-1 font-size-14"></span>
                        {{ translationService.translate("DELETE") }}
                    </button>
                      <button *ngIf="!isCourseCompleted" title="{{ translationService.translate('INSERT_TOOLTIP') | textCase:'sentence' }}" class="d-flex justify-content-center align-items-center action-btn action-insert text-capitalize" (click)="insertPage(pageIndex)">
                        <span nz-icon nzType="form" nzTheme="outline" class="mx-1 font-size-14"></span>
                        {{ translationService.translate("INSERT") }}
                    </button>
                  </div>
                  <div *ngIf="!isCourseCompleted" class="d-flex justify-content-between align-items-center">
                        <a class="icon-btn-style" (click)="moveQuestion(pageIndex, swapDirection.UP)" *ngIf="showMoveUpButton(pageIndex)">
                            <span nz-icon nzType="up-square" nzTheme="outline" class="font-size-14"></span>
                        </a>
                        <a class="icon-btn-style" (click)="moveQuestion(pageIndex, swapDirection.DOWN)" *ngIf="showMoveDownButton(pageIndex)">
                            <span nz-icon nzType="down-square" nzTheme="outline" class="font-size-14"></span>
                        </a>
                  </div>
              </div>
            </td>
          </tr>
          <!-- More rows... -->
        </tbody>
      </table>
    </div>

    <div class="steps-footer">
      <button
        *ngIf="!isCourseCompleted"
        nz-button
        nzType="primary"
        nzSize="large"
        (click)="insertPage(course.slides.length - 1 || 0)"
      >
        {{ "INSERT_A_NEW_PAGE" | translate }}
      </button>
    </div>
  </div>
  
  <ng-template #customCloseIcon>
    <span class="ant-btn-primary ant-btn-lg custom-close-btn font-weight-400">
      {{ "EXIT_HOME" | translate }}
    </span>
  </ng-template>