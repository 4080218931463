import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChangeDetectorRef } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { CourseService } from "../services/course.service";
import { COURSE_CONST } from "../create-course/data";
import { AuthService } from "../services/auth.service";
import { CourseApiService } from "../services/course.api.service";
import { TranslationService } from "src/app/services/translation.service";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { SpinnerService } from "../services/spinner.service";
import { environment } from "src/environments/environment";
import { QUIZ_CONST } from "../create-quiz/data";
import { PreviewComponent } from "../view-course/components/preview/preview.component";
import { Utils, LocalStorageUtils } from "../shared/utils/utils";
import { QuizUtilityHelper } from "../create-quiz/shared/helper";
import { CourseUtilityHelper } from "../create-course/shared/helper";

@Component({
  selector: "app-view-courses",
  templateUrl: "./view-courses.component.html",
  styleUrls: ["./view-courses.component.scss"],
})
export class ViewCoursesComponent implements OnInit {
  @ViewChild("customCloseIcon") CustomCloseBtn!: TemplateRef<any>;

  column: any = {
    name: "",
    sortOrder: null,
    sortFn: null,
    listOfFilter: [],
    filterFn: null,
  };
  meta: any = {
    pagination: {
      page: 1,
      pageSize: 25,
      pageCount: 1,
      total: 25,
    },
  };
  activeCourses: any[] = [];
  softDeletedCourses: any[] = [];
  listOfColumns: any[] = [];
  pageOptions: any = [10, 25, 50, 100];
  activeCoursesResponse$: any;
  softDeletedCoursesResponse$: any;
  category: string = COURSE_CONST.COURSE_CATEGORY;
  isVisible = false;
  editPageNumber: number = 0;
  editPageSize: number = 0;
  editEventId: number = 0;
  production = false;
  selectedTabIndex: number = 0;
  private readonly PREVIEW_WIDTH = '80%';

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private message: NzMessageService,
    private courseService: CourseService,
    private courseApiService: CourseApiService,
    private authService: AuthService,
    private spinner: SpinnerService,
    private modalService: NzModalService,
    private activateRoute: ActivatedRoute,
    private translationService: TranslationService,
  ) { }

  ngOnInit(): void {
    this.production = environment.production;
    Utils.checkAuthorization(this.router);
    this.setQueryParams();
    this.initializeData();
    this.courseService.applyStyles();
    this.mapColumnData();
  }

  private initializeData(): void {
    this.courseService.deleteCourseBuilderSteps();
    this.getCourses();
  }

  // Getter Function
  get _tableHeaders() {
    if (this.selectedTabIndex == 0) {
      return [
        "Id",
        "CourseVersionIdentifier",
        "Title",
        "Status",
        "Version",
        "# Pages",
        "Created User",
        "Created At",
        "Updated At",
      ];
    }
    else {
      return [
        "Id",
        "CourseVersionIdentifier",
        "Title",
        "Version",
        "# Pages",
      ];
    }
  }

  get _isSuperAdmin(){
    return LocalStorageUtils.isSuperAdmin();
  }

  get _isBuilder(){
    return LocalStorageUtils.isUserBuilder();
  }

  // Get Courses
  getCourses(pageIndex: number = 1, pageSize: number = 25) {
    this.activeCourses = this.softDeletedCourses = [];
    if (this.selectedTabIndex == 0) {
      this.getActiveCourses(pageIndex, pageSize)
    }
    else {
      this.getSoftDeletedCourses(pageIndex, pageSize)
    }
  }

  getActiveCourses(pageIndex: number = 1, pageSize: number = 25): void {
    this.spinner.show();
    // Update your API call with the new pageIndex and pageSize
    this.activeCoursesResponse$ = this.courseApiService.getCourses(
      pageIndex,
      pageSize,
      this.category,
    );

    this.activeCoursesResponse$.subscribe(
      (response: any) => {
        this.activeCourses = response.data.map((item: any) => {
          const attributes = item.attributes;
          return { id: item.id, ...attributes };
        });
        this.meta = response.meta;
        this.modalService.closeAll();
        this.cdr.detectChanges();
      },
      (error: any) => {
        this.modalService.closeAll();
      },
    );
  }

  getSoftDeletedCourses(pageIndex: number = 1, pageSize: number = 25): void {
    this.spinner.show();
    // Update your API call with the new pageIndex and pageSize
    this.softDeletedCoursesResponse$ = this.courseApiService.getSoftDeleteCourses(
      pageIndex,
      pageSize,
      this.category,
    );

    this.softDeletedCoursesResponse$.subscribe(
      (response: any) => {
        this.softDeletedCourses = response.data.map((item: any) => {
          const attributes = item.attributes;
          return { id: item.id, ...attributes };
        });
        this.meta = response.meta;
        this.modalService.closeAll();
        this.cdr.detectChanges();
      },
      (error: any) => {
        this.modalService.closeAll();
      },
    );
  }
  //

  setQueryParams() {
    this.activateRoute.queryParams.subscribe((params) => {
      // Read and assign query parameters
      this.category = params["category"] || COURSE_CONST.COURSE_CATEGORY;
      if (this.category == QUIZ_CONST.QUIZ_CATEGORY) this._tableHeaders.splice(3, 0, "File Name");
    });
  }

  mapColumnData(): void {
    this.listOfColumns = this._tableHeaders.map((header: string) => ({
      name: header,
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
    }));
  }

  getDisplayText(category: string): string {
    if (category === 'quiz') {
      return "QQSPs";
    }
    return "EEBs";
  }

  editCourse(data: any) {
    this.router.navigate([`/manage-pages/${data.id}`])
  }

  onInputFocus(event: FocusEvent): void {
    // Use target to access the element that triggered the event
    (event.target as HTMLInputElement).select();
  }

  editCourseFromModal() {
    this.isVisible = false;

    if (this.editPageNumber == 0) {
      this.router.navigate([`/${this.category}`], {
        queryParams: { courseId: this.editEventId },
      });
    } else if (
      this.editPageNumber > 0 &&
      this.editPageNumber <= this.editPageSize
    ) {
      this.router.navigate([`/edit-${this.category}/${this.editEventId}`], {
        queryParams: {
          step: 2,
          slide: this.editPageNumber,
        },
      });
    }
  }

  parseInputValue(event: Event): number {
    const inputValue = (event.target as HTMLInputElement)?.valueAsNumber;
    return isNaN(inputValue) ? 0 : inputValue;
  }

  handleCancel() {
    this.isVisible = false;
  }

  createNextVersion(data: any) {
    this.courseApiService.createNextVersion(data.id).subscribe(
      (response: any) => {
        this.getActiveCourses();
      },
      (error: any) => { },
    );
  }

  duplicateToLive(data: any) {
    const modalRef: NzModalRef = this.modalService.create({
      nzTitle: this.translationService.translate("Confirmation"),
      nzContent: this.translationService.translate("ALERT_DUPLICATE_TO_LIVE"),
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [
        {
          label: this.translationService.translate("YES"),
          onClick: () => {
            this.spinner.show();
            this.courseApiService.createCopy(data.id, environment.PENSIVO_ENVs.LIVE).subscribe(
              (response: any) => {
                this.modalService.create({
                  nzTitle: "Information",
                  nzContent: "This event has been successfully duplicated to LIVE",
                  nzOkText: "OK",
                  nzClosable: false,
                  nzCancelText: null, // or nzCancelText: ''
                  nzWidth: "40%",
                  nzOnOk: () => {
                    this.spinner.hide()
                  },
                });
              },
              (error: any) => {
                this.spinner.hide()
              },
            );
            modalRef.close();
          },
        },
        {
          label: this.translationService.translate("CANCEL"),
          onClick: () => {
            modalRef.close();
          },
        }
      ]
    });
  }

  createCopy(data: any) {
    this.spinner.show();
    this.courseApiService.createCopy(data.id, environment.PENSIVO_ENVs.CURRENT).subscribe(
      (response: any) => {
        this.modalService.closeAll();
        this.modalService.create({
          nzTitle: "Information",
          nzContent: "This event has been copied successfully ",
          nzOkText: "OK",
          nzClosable: false,
          nzCancelText: null, // or nzCancelText: ''
          nzWidth: "40%",
          nzOnOk: () => {
            this.getCourses();
          },
        });
      },
      (error: any) => {
        this.spinner.hide();
      },
    );
  }

  softDeleteCourse(id: any) {
    const modalRef: NzModalRef = this.modalService.create({
      nzTitle: this.translationService.translate("Confirmation"),
      nzContent: this.translationService.translate("ALERT_DELETE_COURSE"),
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [
        {
          label: this.translationService.translate("YES"),
          onClick: () => {
            this.courseApiService.softDelete(id).subscribe(
              (response: any) => {
                this.getCourses();
              },
              (error: any) => { },
            );
            modalRef.close();
          },
        },
        {
          label: this.translationService.translate("CANCEL"),
          onClick: () => {
            modalRef.close();
          },
        }
      ]
    });
  }

  permenantDeleteCourse(id: any) {
    const modalRef: NzModalRef = this.modalService.create({
      nzTitle: this.translationService.translate("Confirmation"),
      nzContent: this.translationService.translate("ALERT_HARD_DELETE_COURSE"),
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [
        {
          label: this.translationService.translate("YES"),
          onClick: () => {
            this.courseApiService.deleteCourse(id).subscribe(
              (response: any) => {
                this.getCourses();
              },
              (error: any) => { },
            );
            modalRef.close();
          },
        },
        {
          label: this.translationService.translate("CANCEL"),
          onClick: () => {
            modalRef.close();
          },
        }
      ]
    });
  }

  checkCompletedStatus(data: any) {
    return data.status != COURSE_CONST.COMPLETED;
  }

  checkForDuplicte(data: any) {
    return data.duplicated !== true && data.status == COURSE_CONST.COMPLETED;
  }

  searchData() {
  }

  handlePageIndexChange(pageIndex: number): void {
    this.getCourses(pageIndex, this.meta.pagination.pageSize); // Adding 1 because pagination usually starts from 1, not 0
  }

  handlePageSizeChange(pageSize: number): void {
    this.getCourses(1, pageSize);
  }

  getColumnValue(data: any, column: string): string {
    // Convert column name to camelCase if needed (e.g., 'Created User' to 'createdUser')
    const columnName = column.replace(/\s+/g, "").toLowerCase();

    // Handle the case where column is not a string
    if (typeof column !== "string") {
      console.error(`Invalid column type: ${typeof column}`, column);
      return ""; // or handle it in a way that makes sense for your application
    }

    // Handle specific columns or nested properties
    switch (columnName) {
      case "#pages":
        return data?.totalSlideCount;
      case "courseversionidentifier":
        return data?.courseVersionIdentifier;
      case "createduser":
        return `${data?.user?.data?.attributes?.firstName} ${data?.user?.data?.attributes?.lastName}`;
      case "createdat":
        return this.formatDate(data?.createdAt);
      case "updatedat":
        return this.formatDate(data?.updatedAt);
      case "filename":
        return data?.globalConfigs?.fileName;
      default:
        // For other columns, return the value directly
        return data?.[columnName];
    }
  }

  formatDate(date: string): string {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "America/New_York", // Eastern Time
      timeZoneName: "short", // Display timezone abbreviation (e.g., EDT)
    } as Intl.DateTimeFormatOptions;

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      new Date(date),
    );
    return formattedDate;
  }

  previewCourse(id: number) {
    this.spinner.show();
    this.courseApiService.getCourse(id)
      .subscribe({
        next: (response: any) => {
          this.spinner.hide();
          let course = response?.data?.attributes;
          const isEventFullyValid =
          this.category == "quiz"
            ? QuizUtilityHelper.isEventQuizFullyComplete(course?.slides, course?.globalConfigs?.correctIncorrectAnswerExist)
            : CourseUtilityHelper.isEventCourseFullyComplete(course?.slides);
      
        if (isEventFullyValid) {
          this.router.navigate([`/view-courses/${id}`]);    
        }
        else {
          this.handleCourseInCompleteValidity(id);
        }
        },
        error: () => {
          this.spinner.hide();
        }
      });
  }

  previewPage(courseId: number): void {
    const url = this.generatePreviewUrl(courseId);
    this.openPreviewModal(url);
  }

  generatePreviewUrl(courseId: number): string {
    return this.router.createUrlTree(
      [`/view-courses/${courseId}`],
      {
        queryParams: {
          previewMode: 'true',
          preViewLastSlide: false,
          slide: 1
        }
      }
    ).toString();
  }

  openPreviewModal(url: string): void {
    this.modalService.closeAll();
    const modal = this.modalService.create({
      nzTitle: this.translationService.translate('PREVIEW_MODE'),
      nzContent: PreviewComponent,
      nzCloseIcon: this.CustomCloseBtn,
      nzData: { url },
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: this.PREVIEW_WIDTH
    });

    modal.afterClose.subscribe(() => this.modalService.closeAll());
  }

  handleCourseInCompleteValidity(id: number): void {
    const modalRef: NzModalRef = this.modalService.create({
      nzTitle: this.translationService.translate("VALIDATION_FAILED"),
      nzContent: this.translationService.translate("ALERT_INCOMPLETE_COURSE"),
      nzWidth: "40%",
      nzClosable: false,
      nzMaskClosable: false,
      nzFooter: [
        {
          label: this.translationService.translate("MANAGE_PAGES"),
          onClick: () => {
            modalRef.close();
            this.router.navigate([`/manage-pages/${id}`]);
          },
        },
        {
          label: this.translationService.translate("CANCEL"),
          onClick: () => modalRef.close(),
        }
      ]
    });
  }

  restoreCourse(courseId: number) {
    const modalRef: NzModalRef = this.modalService.create({
      nzTitle: this.translationService.translate("Confirmation"),
      nzContent: this.translationService.translate("ALERT_RESTORE_COURSE"),
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [
        {
          label: this.translationService.translate("YES"),
          onClick: () => {
            this.courseApiService.unDelete(courseId).subscribe(
              () => {
                this.getCourses();
              }
            );
            modalRef.close();
          },
        },
        {
          label: this.translationService.translate("CANCEL"),
          onClick: () => {
            modalRef.close();
          },
        }
      ]
    });
  }

  tabChanged($event: any) {
    this.spinner.show();
    this.setQueryParams();
    this.initializeData();
    this.courseService.applyStyles();
    this.mapColumnData();
  }
}
