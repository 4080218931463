<div class="headings">
  <h4 nz-typography>
    <span class="font-size-20" nz-typography
      >- {{ "LAYER2_TEXT_IMAGE" | translate }}</span
    >
    <span class="italic" nz-typography>*{{ "MANDATORY" | translate }}</span>
  </h4>
</div>
<nz-tabset [(nzSelectedIndex)]="activeTab">
  <nz-tab *ngFor="let panel of panels; let i = index" [nzTitle]="titleTemplate">
    <ng-template #titleTemplate>
      {{ getIconBasedOnFormStatus(i) }} {{ panel.name }}
    </ng-template>
    <form
      *ngIf="getFormGroupAtIndex(i)"
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="getFormGroupAtIndex(i)"
    >
      <nz-form-item>
        <nz-form-label>{{ "LAYER2_OPTIONS_TEXT" | translate }} </nz-form-label>
        <nz-form-control>
          <!-- If Clickable Asset Is Image -->
          <img
            *ngIf="panel.type == supportedAsset.IMAGE"
            [src]="panel.imageUrl"
            class="layer2Image"
          />
          <!-- If Clickable Layer Is Button -->
          <button
            nz-button
            nzType="primary"
            nzSize="large"
            class="button-preview"
            [style.backgroundColor]="
              panel?.buttonConfig?.fillColor != 'default'
                ? panel?.buttonConfig?.fillColor
                : courseService.getStyle()['primaryColor']
            "
            [style.color]="
              panel?.buttonConfig?.textColor != 'default'
                ? panel?.buttonConfig?.textColor
                : courseService.getStyle()['primaryTextColor']
            "
            [style.borderColor]="
              panel?.buttonConfig?.borderColor != 'default'
                ? panel?.buttonConfig?.borderColor
                : courseService.getStyle()['borderColor']
            "
            *ngIf="panel.type == supportedAsset.BUTTON"
          >
            {{ panel.buttonConfig.name }}
          </button>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzFor="layer2Options">{{
          "LAYER2_OPTIONS" | translate
        }}</nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_LAYER_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="layerOptions"
            formControlName="layer2Options"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
          >
            <nz-option
              *ngFor="let option of layerTwoOptions"
              [nzValue]="option.value"
              [nzLabel]="option.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div formGroupName="assetOptions" *ngIf="_showVideoPlayerOption(i)">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="videoOption"
            >{{ "SLIDE_VIDEO_PLAYER_OPTION" | translate }}
          </nz-form-label>
          <nz-form-control
            nzErrorTip="{{ 'SELECT_VIDEO_PLAYER_OPTION_PLACEHOLDER' | translate }}"
          >
            <nz-select
              id="videoOption"
              formControlName="videoOption"
              nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
            >
              <nz-option
                *ngFor="let option of videoPlayerOptions"
                [nzValue]="option.value"
                [nzLabel]="option.label"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <app-content-editor
        *ngIf="fileList.length > 0"
        [parentForm]="getFormGroupAtIndex(i)"
        [selectedOption]="getFormGroupAtIndex(i).get('layer2Options')"
        [fileList]="fileList[i]"
        [componentName]="courseConst.STEP_THREE"
        [audioFileList]="audioFileList[i]"
        [disableUploadButton]="disableUploadButton"
        (formSubmitted)="submitForm($event)"
        [indexOfActiveContentEditor]="activeTab"
        [indexOfContentEditor]="i"
        [courseCategory]="courseOrSlideCategory"
      >
      </app-content-editor>
    </form>
  </nz-tab>
</nz-tabset>
