import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { LayerTwoModalComponent } from "./components/layer-two-modal/layer-two-modal.component";
import {
  COMPLETION_NEXT_RULE,
  COURSE_CONST,
  LAYER_ONE_AND_TWO_OPTIONS,
  LAYER_ONE_OPTIONS,
  LAYER_OPTIONS,
  SlideType,
} from "../create-course/data";
import { CommonUtils, LocalStorageUtils, Utils } from "../shared/utils/utils";
import { CourseService } from "../services/course.service";
import { CourseApiService } from "../services/course.api.service";
import {
  CourseRequest,
  RegistrationRequest,
  RegistrationGetResponse,
  AttemptResponse,
  AttemptRequest,
  ConsentData,
  ConsentSlideInteractionEvent,
} from "../models/course-interface";
import { environment } from "src/environments/environment";
import { SpinnerService } from "src/app/services/spinner.service";
import { TranslationService } from "src/app/services/translation.service";
import { SafeType } from "../models/enum/safe-type.enum";
import { DeviceDetectorService } from "ngx-device-detector";
import { QuizSlide } from "../create-quiz/create-quiz.interfaces";
import { SlideComponent } from "./components/slide/slide.component";
import { AttemptStatus } from "../models/enum/attempt-status.enum";
import { QUIZ_CONST } from "../create-quiz/data";
import { Subscription } from "rxjs";
import { Subject } from "rxjs";

@Component({
  selector: "app-view-course",
  templateUrl: "./view-course.component.html",
  styleUrls: ["./view-course.component.scss"],
})
export class ViewCourseComponent implements OnInit, OnDestroy {
  courseId: number = 0;
  regId: string = "";
  slideIndex: number = 0;
  course: any = new Object();
  layerViewStatus: boolean[][] = [];
  imagesCount: number = 0;
  previewMode: boolean = false;
  preViewLastSlide: boolean = false;
  slideParam: number = 0;
  stepParam: number = 0;
  flgCongratulation: boolean = false;
  courses: any[] = [];
  logoUrl: any;
  slideViewValidated: boolean = false;
  slideViewValidated2: boolean = false;
  currentSlideFullyViewed: boolean = false;
  username: string = "";
  isBuilder: boolean = false;
  registration: RegistrationGetResponse | undefined;
  attempt: AttemptResponse | undefined;
  safeType = SafeType;
  isIOS: boolean = false;
  quizContent: QuizSlide | ConsentData = {
    slideId: "",
    slideType: SlideType.QUIZ,
    questionTitle: "",
    questionType: "",
    answerStatus: CorrectAnswer.notTaken,
    isQuestionMandatoryForQssp: true,
  };
  slideContent: any;
  consentViewIteration: number = 0;
  lastSlideCompleted: number = 0;
  completionPercentage: string = "";
  subscription = new Subscription();
  checkCourseSlideCompletionStatus: Subject<boolean> = new Subject();
  isUpdatingAttemptInProgress: boolean = false;
  quizResultsToShow: any = [];

  @ViewChild("slideRef", { static: false }) slideRef!: SlideComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NzModalService,
    public courseService: CourseService,
    private courseApiService: CourseApiService,
    private spinner: SpinnerService,
    public translationService: TranslationService,
    public deviceService: DeviceDetectorService,
  ) { }

  ngOnInit() {
    Utils.checkViewerAuthorization(this.router);
    this.courseService.deleteCourseBuilderSteps();
    this.courseService.applyStyles();
    this.username = LocalStorageUtils.getUsername();
    this.isBuilder = LocalStorageUtils.isUserBuilder();
    this.logoUrl = CommonUtils.getFileUrl(LocalStorageUtils.getClientLogo());
    this.getSelectedCourseInfo();
    this.getRegistration().then(() => {
      this.getCourse();
    });
    this.isIOS =
      this.deviceService.os == "iOS" || this.deviceService.os == "Mac"
        ? true
        : false;

    this.checkCourseSlideCompletionStatus.subscribe(
      (completionStatus) => {
        if (completionStatus && this._isEventCourse) {
          this.updateLastSlide();
        }
      }
    )
  }

  /* Getter Function */
  get _LastSlideCompleted(): any {
    const lastSlideCompleted =
      this.registration?.lastSlideCompleted == undefined
        ? 0
        : this.registration?.lastSlideCompleted;
    return this.course?.category == QUIZ_CONST.QUIZ_CATEGORY
      ? lastSlideCompleted + 1
      : lastSlideCompleted;
  }

  get _courseGlobalConfig(): any {
    if (typeof this.course.globalConfigs == "string") {
      return JSON.parse(this.course.globalConfigs);
    } else {
      return this.course.globalConfigs;
    }
  }

  setStyles() {
    var style = this.courseService.getStyle();

    if (!this.course?.globalConfigs?.enableLogoandtitle) {
      document.documentElement.style.setProperty(
        "--PageTitleBottomColor",
        style.primaryColor,
      );
    } else {
      document.documentElement.style.setProperty(
        "--PageTitleBottomColor",
        style.PageTitleBottomColor,
      );
    }
  }

  getRegistration() {
    return new Promise((resolve, reject) => {
      if (!this.isBuilder) {
        this.subscription.add(
          this.courseApiService.getRegistration(this.courseId, this.regId).subscribe(
            (response: any) => {
              this.registration = response;
              resolve(true);
            },
            (error: any) => {
              var message = error?.error?.message || "Internal Server Error";

              this.modalService.create({
                nzTitle: "Information",
                nzContent: `Server Error: ${message}. Issue with regId = ${this.regId} , Please try again.`,
                nzOkText: "OK",
                nzClosable: false,
                nzCancelText: null,
                nzOnOk: () => { this.handleExit() },
              });
            },
          )
        )
      } else {
        resolve(true);
      }
    });
  }

  editSlide() {
    this.router.navigate([`/edit-${this.course.category}/${this.courseId}`], {
      queryParams: {
        step: 2,
        slide: this.slideIndex + 1,
      },
    });
  }

  addSlide() {
    const courseBuilderSteps = this.courseService.retrieveCourseObject();
    this.course.slides.splice(this.slideIndex + 1, 0, courseBuilderSteps);
    const courseRequest: CourseRequest = {
      data: {
        totalSlideCount: this.course.slides.length,
        slides: JSON.stringify(this.course.slides),
      },
    };
    this.spinner.show();
    this.subscription.add(
      this.courseApiService.updateCourse(this.courseId, courseRequest).subscribe(
        (response: any) => {
          this.spinner.hide();
          this.router.navigate(
            [`/edit-${this.course.category}/${this.courseId}`],
            {
              queryParams: {
                step: 2,
                slide: this.slideIndex + 2,
              },
            },
          );
        },
        (error: any) => {
          this.spinner.show();
        },
      )
    )
  }

  getSelectedCourseInfo() {
    this.courseId = Number(this.route.snapshot.paramMap?.get(COURSE_CONST.ID));
    this.regId = this.route.snapshot.paramMap?.get(COURSE_CONST.REG_ID) || "";
    this.slideParam = Number(
      this.route.snapshot.queryParamMap?.get(COURSE_CONST.SLIDE),
    );
    this.stepParam = Number(
      this.route.snapshot.queryParamMap?.get(COURSE_CONST.STEP),
    );
    var previewModeParam = this.route.snapshot.queryParamMap?.get(
      COURSE_CONST.PREVIEW_MODE,
    );

    if (previewModeParam !== null) {
      this.previewMode = previewModeParam === COURSE_CONST.TRUE;
    }

    var preViewLastSlideParam = this.route.snapshot.queryParamMap?.get(
      COURSE_CONST.PREVIEW_LAST_SLIDE,
    );

    if (preViewLastSlideParam !== null) {
      this.preViewLastSlide = preViewLastSlideParam === COURSE_CONST.TRUE;
    }

    this.slideIndex = this.slideParam
      ? parseInt(this.slideParam.toString(), 10) - 1
      : 0;
    this.slideContent = this.getSlideContent(this.slideIndex);
  }

  getCourse() {
    this.spinner.show();
    this.subscription.add(
      this.courseApiService.getCourse(this.courseId).subscribe(
        (response: any) => {
          this.spinner.hide();
          this.course = response.data.attributes;
          this.course.slides =
            typeof this.course.slides == "string"
              ? JSON.parse(this.course.slides)
              : this.course.slides;
          this.course.id = response.data.id;
          this.setStyles();
          this.initializeAttemptDatas();

          const stepTwo = this.course.slides?.[this.slideIndex]?.stepTwo;
          if (stepTwo) {
            if (stepTwo.images) {
              this.imagesCount = stepTwo.images.length;
            }
            this.initLayerViewStatus();
            this.currentSlideFullyViewed = this.isCurrentSlideFullyViewed();
          }
          this.resumeCourseHandler();
        },
        (error: any) => {
          this.spinner.hide();
        },
      )
    )
  }

  initializeAttemptDatas() {
    if (this._isQuizCourse) {
      if (this.course.globalConfigs.canUserContinue) {
        if (this.registration?.attempts?.length) {
          this.attempt = this.registration?.attempts?.slice(-1)[0];
        }
      }

      if (this.course?.globalConfigs.attempt == "1") {
        this.quizResultsToShow = this.registration?.attempts;
      } else if (this.course?.globalConfigs.attempt == "2") {
        this.quizResultsToShow = this.registration?.attempts?.filter(
          (x: any) => x.Valid,
        );
      } else if (this.course?.globalConfigs?.attempt == "3") {
        this.quizResultsToShow = this.registration?.attempts
          ?.filter((x: any) => x.Valid).slice(-1);
      }
    }
    else if (this._isEventCourse || this._isQuizSlide || this._isConsentSlide) {
      if (this.registration?.attempts?.length)
        this.attempt = this.registration?.attempts?.slice(-1)[0];

      if (this.attempt && this.attempt?.QuizResult?.length > 0) {
        let doesConsentPageExist = this.attempt.QuizResult.findIndex((slide: any) => slide.slideType == SlideType.CONSENT);
        if (doesConsentPageExist > -1) {
          this.consentViewIteration = 1;
        }
      }
      this.quizResultsToShow = this.registration?.attempts;
    }
  }

  createQuizContent(stepTwo: any) {
    if (this._isQuizCourse) {
      const stepOne = this.course.slides?.[this.slideIndex]?.stepOne;
      const questionType = this.mapQuestionType(stepOne?.questionType);

      const existingQuizResult = this.attempt?.QuizResult.find(
        (result: any) => result?.slideId === stepOne?.slideId,
      );

      if (stepOne) {
        this.quizContent = existingQuizResult || {
          slideId: stepOne?.slideId,
          slideType: SlideType.QUIZ,
          questionTitle: stepTwo.questionTitle,
          questionType: questionType,
          answerStatus: CorrectAnswer.notTaken,
          questionPoint: stepOne?.questionPoint,
          correctAnswerPoint: stepOne?.correctAnswerPoint,
          isQuestionMandatoryForQssp: stepOne?.isQuestionMandatoryForQssp,
          enableNumberLabels: stepOne?.enableNumberLabels,
          mainQuestion: {
            question: stepTwo.question,
            userAnswer: "", // user answer go here
          },
          commentsFromConfiguration: {
            question: stepTwo.freeFormQuestion,
            userAnswer: "", // user answer go here
          },
          horizontalScale:
            questionType == "horizontalScale"
              ? stepTwo.horizontalLabels.map(
                (option: { label: string; point: any }) => ({
                  label: option.label,
                  point: option.point,
                  user_answer: "", // You may need to provide a default value,
                  value: option.label,
                }),
              )
              : [],
          multiChoice:
            questionType == "multiChoice"
              ? stepTwo.answerLabels.map(
                (option: { label: string; correct_answer: any }) => ({
                  label: option.label,
                  correct_answer: option.correct_answer ? true : false,
                  user_answer: "", // You may need to provide a default value,
                  value: option.label,
                }),
              )
              : [],
          multiSelect:
            questionType == "multiSelect"
              ? stepTwo.answerLabels.map(
                (option: { label: string; correct_answer: any }) => ({
                  label: option.label,
                  correct_answer: option.correct_answer ? true : false,
                  user_answer: "", // You may need to provide a default value
                }),
              )
              : [],
        };
      }
    }
    else if (this._isQuizSlide) {
      const stepOne = this.course.slides?.[this.slideIndex]?.stepOne;
      const questionType = this.mapQuestionType(stepOne.questionType);

      const existingQuizResult = this.attempt?.QuizResult.find(
        (result: any) => result?.slideId === stepOne.slideId,
      );

      this.quizContent = existingQuizResult || {
        slideId: stepOne.slideId,
        slideType: SlideType.QUIZ,
        questionTitle: stepTwo.questionTitle,
        questionType: questionType,
        answerStatus: CorrectAnswer.notTaken,
        questionPoint: stepOne.questionPoint,
        correctAnswerPoint: stepOne.correctAnswerPoint,
        isQuestionMandatoryForQssp: stepOne.isQuestionMandatoryForQssp,
        enableNumberLabels: stepOne.enableNumberLabels,
        mainQuestion: {
          question: stepTwo.question,
          userAnswer: "",
        },
        multiChoice:
          questionType == "multiChoice"
            ? stepTwo.answerLabels.map(
              (option: { label: string; correct_answer: any }) => ({
                label: option.label,
                correct_answer: option.correct_answer ? true : false,
                user_answer: "",
                value: option.label,
              }),
            )
            : [],
        multiSelect:
          questionType == "multiSelect"
            ? stepTwo.answerLabels.map(
              (option: { label: string; correct_answer: any }) => ({
                label: option.label,
                correct_answer: option.correct_answer ? true : false,
                user_answer: "",
              }),
            )
            : [],
      };
    }
  }

  // Function to map questionType from stepOne to quizContent
  mapQuestionType(stepOneType: string): string {
    switch (stepOneType) {
      case "1":
      case "multiChoice":
        return "multiChoice";
      case "2":
        return "multiSelect";
      case "3":
        return "freeFormQuestion";
      case "4":
        return "horizontalScale";
      default:
        return ""; // Handle default case
    }
  }

  initLayerViewStatus() {
    this.layerViewStatus = this.course.slides.map((slide: any) =>
      slide.stepThree?.modalForms
        ? slide.stepThree?.modalForms?.map(() => false)
        : [],
    );
  }

  goToNextSlide() {
    this.slideIndex++;
    if (this._isQuizSlide) {
      this.slideContent = this.getSlideContent(this.slideIndex, true);
    }
    this.updateSlideQueryParam();
  }

  handleExit() {
    if (this.isBuilder)
      this.router.navigate(["/view-courses"], {
        queryParams: {
          category: this.course?.category?.toString().toLowerCase(),
        },
      });
    else {
      this.redirectUrl();
    }
  }

  updateCourse() {
    const courseRequest: CourseRequest = {
      data: {
        totalSlideCount: this.course.slides.length,
        slides: JSON.stringify(this.course.slides),
      },
    };
    this.spinner.show();
    this.subscription.add(
      this.courseApiService.updateCourse(this.courseId, courseRequest).subscribe(
        (response: any) => {
          this.spinner.hide();
          this.redirectAfterDeletion();
        },
        (error: any) => {
          this.spinner.hide();
        },
      )
    )
  }

  redirectAfterDeletion() {
    //this.slideIndex -= 1;
    this.getCourse();
    if (this.slideIndex == 0 && !this.previewMode) {
      this.router.navigate(["/view-courses"], {
        queryParams: { category: this.course.category },
      });
    }
  }

  handleNavigation() {
    if (this._isQuizCourse) {
      this.quizPreViewDatGet(this.slideRef.slideContent.quizContent);
    }
    else if (this._isQuizSlide) {
      if (this.registration && !this.attempt) {
        this.createAttempt().then(() => {
          this.getRegistration();
          this.quizSlidePreViewDatGet(this.slideRef.slideContent.quizContent);
        });
      }
      else {
        this.quizSlidePreViewDatGet(this.slideRef.slideContent.quizContent);
      }
    } else if (this._isEventCourse) {
      var actions_completed = this.isCurrentSlideFullyViewed();

      if (
        !actions_completed ||
        (this.slideIndex + 1 === this.course?.slides?.length &&
          this.previewMode)
      ) {
        this.getDisableNextButton();
        return;
      }
      if (this.canNavigateToNextSlide()) {
        this.goToNextSlide();
      }
      else if (this.isLastSlide() && actions_completed) {
        this.flgCongratulation = true;
      }
    }
  }

  getDisableNextButton() {
    var message = "";
    if (this._isQuizCourse) {
      message = this.translationService.translate(
        "NEXT_BUTTON_QUIZ_COMPLETION_MESSAGE",
      );
    } else if (this._isQuizSlide) {
      message = this.translationService.translate(
        "NEXT_BUTTON_QUIZ_COMPLETION_MESSAGE",
      );
    } else if (!this.isCurrentSlideFullyViewed()) {
      message = this.translationService.translate(
        "NEXT_BUTTON_COMPLETION_MESSAGE",
      );
    } else if (this.isLastSlide() && this.previewMode) {
      message = this.translationService.translate("LAST_SLIDE");
    }

    this.modalService.create({
      nzTitle: this.translationService.translate("INFORMATION"),
      nzContent: message,
      nzOkText: this.translationService.translate("OK"),
      nzClosable: false,
      nzCancelText: null, // or nzCancelText: ''
      nzOnOk: () => { },
    });
  }

  navigateToEditCourse() {
    if (this.previewMode) {
      const queryParams = {
        fromPreviewMode: COURSE_CONST.TRUE,
        step: this.stepParam,
        slide: this.slideParam,
      };

      this.router.navigate([`/edit-${this.course.category}/${this.courseId}`], {
        queryParams: queryParams,
      });
    }
  }

  canNavigateToNextSlide(): boolean {
    return !this.isLastSlide() && this.isCurrentSlideFullyViewed();
  }

  isLastSlide(): boolean {
    return this.slideIndex === this.course.slides.length - 1;
  }

  isCurrentSlideFullyViewed(): boolean {
    let layerOptions =
      this.course?.slides?.[this.slideIndex]?.stepOne?.layerOptions;
    if (layerOptions == LAYER_OPTIONS.L1_L2_OP_3) {
      this.layerViewStatus[this.slideIndex][
        this.course?.slides?.[this.slideIndex]?.stepTwo.hotspots.length
      ] = true;
    }

    const enabledNext =
      this.course?.slides?.[this.slideIndex]?.stepOne?.enabledNext;
    if (
      this._LastSlideCompleted > 0 &&
      this.slideIndex + 1 <= this._LastSlideCompleted
    ) {
      this.currentSlideFullyViewed = true;
      return true;
    }

    switch (enabledNext) {
      case COMPLETION_NEXT_RULE.SLIDE_LOADED: {
        this.currentSlideFullyViewed = true;
        return true;
      }
      case COMPLETION_NEXT_RULE.LAYER_2_COMPLETED: {
        const currentSlideLayers = this.layerViewStatus[this.slideIndex];
        const result = currentSlideLayers.every((layer) => layer);
        this.currentSlideFullyViewed = result;
        return result;
      }
      case COMPLETION_NEXT_RULE.CONSENT_PROVIDED: {
        if (this.layerViewStatus[this.slideIndex].length > 0) {
          return true;
        }
        return false;
      }
      default: {
        this.currentSlideFullyViewed = false;
        return false;
      }
    }
  }

  updateLastSlide() {
    if (
      (this._LastSlideCompleted < this.slideIndex + 1) &&
      !this.isBuilder &&
      this.registration?.completionPercentage !== undefined &&
      this.registration?.completionPercentage < 100
    ) {
      this.lastSlideCompleted =
        this.course?.category == QUIZ_CONST.QUIZ_CATEGORY
          ? this.slideIndex
          : this.slideIndex + 1;
      this.completionPercentage = (
        (this.lastSlideCompleted / this.course.slides.length) *
        100
      ).toFixed(2);
      if (!this.isBuilder && (this._isEventCourse || this._isQuizSlide)) this.updateRegistration();
    }
  }

  updateRegistration() {
    // If Registration Exist
    if (this.registration) {
      const { lastSlideCompleted, completionPercentage } = this.registration;
      // Prevent updating with a lower lastSlideCompleted or completionPercentage
      if (
        (lastSlideCompleted && this.lastSlideCompleted < lastSlideCompleted) ||
        (completionPercentage && Number(this.completionPercentage) < completionPercentage)
      ) {
        return;
      }
    }

    const registrationRequest: RegistrationRequest = {
      completionPercentage: Number(this.completionPercentage ?? -1),
      lastSlideCompleted: this.lastSlideCompleted ?? -1,
      regId: this.regId,
      courseId: this.courseId,
    };

    this.subscription.add(
      this.courseApiService.updateRegistration(registrationRequest).subscribe(
        (response: any) => { },
        (error: any) => { },
      )
    )
  }

  resetRegistration() {
    const registrationRequest: RegistrationRequest = {
      completionPercentage: 0,
      courseId: this.courseId,
      regId: this.regId,
      lastSlideCompleted: 0,
    };

    this.subscription.add(
      this.courseApiService.updateRegistration(registrationRequest).subscribe(
        (response: any) => {
          this.getRegistration();
          this.resumeCourse(0);
        },
        (error: any) => { },
      )
    )
  }

  updateAttempt() {
    return new Promise((resolve, reject) => {
      if (this.quizContent.slideId) {
        const quizResultIndex = this.attempt?.QuizResult?.findIndex(
          (result: any) => result.slideId === this.quizContent.slideId,
        );

        if (quizResultIndex !== -1 && quizResultIndex !== undefined) {
          // If a matching element is found in the array, update it
          if (this.attempt?.QuizResult) {
            this.attempt.QuizResult[quizResultIndex] = this.quizContent;
          }
        }
        else {
          // If no matching element is found, push quizContent into the array
          this.attempt?.QuizResult.push(this.quizContent);
        }

        this.calculateQuizResults();
        let req: AttemptRequest = {
          data: {
            registration: this.registration?.id?.toString() || "",
            Valid: this.areAllMandatoryQuestionsCompleted(),
            QuizResult: JSON.stringify(this.attempt?.QuizResult),
            Grade: this.attempt?.Grade ?? 0,
            TotalPoints: this.attempt?.TotalPoints ?? 0,
            ScoredPoints: this.attempt?.ScoredPoints ?? 0,
            Status: AttemptStatus.INCOMPLETE,
          },
        };

        if (req.data.Valid) {
          req.data.Status =
            req.data?.Grade >= this.course.globalConfigs?.passingPercentage
              ? AttemptStatus.PASS
              : AttemptStatus.FAIL;

          if (!this.course.globalConfigs?.correctIncorrectAnswerExist) {
            if (this.slideIndex >= this.course.slides.length) {
              req.data.Status = AttemptStatus.PASS;
            } else {
              req.data.Status = AttemptStatus.INCOMPLETE;
            }
          }
        }

        if (this._isConsentSlide) {
          req.data.Status = AttemptStatus.PASS
        }

        this.subscription.add(
          this.courseApiService
            .updateAttempt(this.attempt?.id || 0, req)
            .subscribe(
              (response: any) => {
                this.attempt = {
                  id: response.data.id,
                  ...response.data.attributes,
                };
                this.isUpdatingAttemptInProgress = false;
                resolve(true);
              },
              (error: any) => { this.isUpdatingAttemptInProgress = false; },
            )
        )
      } else {
        resolve(true);
      }
    });
  }

  createAttempt(obj?: any) {
    return new Promise((resolve, reject) => {
      const req: AttemptRequest = {
        data: {
          registration: this.registration?.id?.toString() || "",
          Valid: this.course.globalConfigs.qsspInvalidation == "2",
          QuizResult: obj ? JSON.stringify([obj]) : "[]",
          Grade: 0,
          TotalPoints: 0,
          ScoredPoints: 0,
          Status: this._isConsentSlide ? AttemptStatus.PASS : AttemptStatus.INCOMPLETE,
        },
      };
      if (this.registration?.id)
        this.subscription.add(
          this.courseApiService.createAttempt(req).subscribe(
            (response: any) => {
              resolve(true);
              this.attempt = {
                id: response.data.id,
                ...response.data.attributes,
              };
            },
            (error: any) => {
              resolve(false);
            },
          ))
      else resolve(true);
    });
  }

  areAllMandatoryQuestionsCompleted(): boolean {
    if (this.course.globalConfigs?.qsspInvalidation == "2") {
      return true;
    }
    if (this.course.globalConfigs?.qsspInvalidation == "1") {
      let attemptedMandatoryQuestions =
        this.attempt?.QuizResult.filter(
          (x) =>
            x.isQuestionMandatoryForQssp &&
            x.answerStatus != CorrectAnswer.notTaken,
        )?.length ?? 0;
      let totalMandatoryQuestions = 0;
      this.course.slides.forEach((slide: any) => {
        if (slide.stepOne.isQuestionMandatoryForQssp)
          totalMandatoryQuestions = totalMandatoryQuestions + 1;
      });

      if (attemptedMandatoryQuestions < totalMandatoryQuestions) return false;
      return true;
    }
    return true;
  }

  goToPreviousSlide() {
    if (this.slideIndex > 0) {
      this.slideIndex--;
      if (this._isQuizCourse) {
        this.slideContent = this.getSlideContent(this.slideIndex, true);
      }
      else if (this._isQuizSlide) {
        this.slideContent = this.getSlideContent(this.slideIndex, true);
      }
      else {
        this.updateSlideQueryParam();
      }
    }
  }

  updateSlideQueryParam() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { slide: this.slideIndex + 1 },
      queryParamsHandling: "merge",
    });

    this.createQuizContent(this.course.slides?.[this.slideIndex]?.stepTwo);

    var images = this.course.slides[this.slideIndex]?.stepTwo?.images;

    if (images != undefined) {
      this.imagesCount = images.length;
    } else {
      this.imagesCount = 0;
    }
  }

  quizPreViewDatGet(data: QuizSlide) {
    let answerCorrect: CorrectAnswer = CorrectAnswer.notTaken;
    let currentQuestion = this.course.slides[this.slideIndex];
    this.quizContent = data;
    if (currentQuestion.stepOne.isQuestionMandatoryForQssp) {
      if (data.questionType == "horizontalScale") {
        let selectedAny = data.horizontalScale?.find((x) => x.user_answer);
        if (selectedAny) answerCorrect = CorrectAnswer.correct;
      } else if (data.questionType == "multiChoice") {
        let selectedAnswer = data.multiChoice?.find(
          (x: any) => x.user_answer == true,
        );
        if (selectedAnswer) {
          answerCorrect =
            selectedAnswer?.correct_answer == true
              ? CorrectAnswer.correct
              : CorrectAnswer.incorrect;
        }
      } else if (data.questionType == "multiSelect") {
        let correctAnswers = data.multiSelect?.filter(
          (x: any) => x.correct_answer == true,
        );
        let selectedAnswers = data.multiSelect?.filter(
          (x: any) => x.user_answer == true,
        );
        if (selectedAnswers?.length && correctAnswers?.length) {
          answerCorrect = this.arraysEqual(correctAnswers, selectedAnswers)
            ? CorrectAnswer.correct
            : CorrectAnswer.incorrect;
        }
      } else if (
        data.questionType == "freeFormQuestion" &&
        data?.mainQuestion?.userAnswer
      ) {
        answerCorrect = CorrectAnswer.correct;
      }

      this.quizContent.answerStatus = answerCorrect;
      let oldIndex = this.slideIndex;

      if (
        answerCorrect == CorrectAnswer.notTaken ||
        (currentQuestion.stepOne.isFreeFormQuestionMandatory &&
          !data?.commentsFromConfiguration?.userAnswer)
      ) {
        this.getDisableNextButton();
      } else if (
        !this.course?.globalConfigs?.correctIncorrectAnswerExist &&
        answerCorrect == CorrectAnswer.correct
      ) {
        this.slideIndex = this.slideIndex + 1;
      } else if (
        answerCorrect == CorrectAnswer.correct &&
        currentQuestion.stepOne.correctAnswerProceeding == "1"
      ) {
        this.slideIndex = this.slideIndex + 1;
      } else if (
        this.course?.globalConfigs?.correctIncorrectAnswerExist &&
        answerCorrect == CorrectAnswer.correct &&
        currentQuestion.stepOne.correctAnswerProceeding == "2"
      ) {
        this.showLayerTwoContent(this.getCorrectAnswerIndex(), () => {
          let oldIndex = this.slideIndex;
          this.slideIndex = this.slideIndex + 1;
          if (
            oldIndex != this.slideIndex &&
            this.slideIndex < this.course.slides.length
          )
            this.slideContent = this.getSlideContent(this.slideIndex);
          this.showCongratsUpdateLastSlideAttempt();
        });
      } else if (
        answerCorrect == CorrectAnswer.incorrect &&
        currentQuestion.stepOne.incorrectAnswerProceeding == "1"
      ) {
        this.slideIndex = this.slideIndex + 1;
      } else if (
        this.course?.globalConfigs?.correctIncorrectAnswerExist &&
        answerCorrect == CorrectAnswer.incorrect &&
        currentQuestion.stepOne.incorrectAnswerProceeding == "2"
      ) {
        this.showLayerTwoContent(0, () => {
          let oldIndex = this.slideIndex;
          this.slideIndex = this.slideIndex + 1;
          if (
            oldIndex != this.slideIndex &&
            this.slideIndex < this.course.slides.length
          )
            this.slideContent = this.getSlideContent(this.slideIndex);
          this.showCongratsUpdateLastSlideAttempt();
        });
      } else if (
        this.course?.globalConfigs?.correctIncorrectAnswerExist &&
        answerCorrect == CorrectAnswer.incorrect &&
        currentQuestion.stepOne.incorrectAnswerProceeding == "3"
      ) {
        this.showLayerTwoContent(0, () => { });
      }

      if (
        oldIndex != this.slideIndex &&
        this.slideIndex < this.course.slides.length
      )
        this.slideContent = this.getSlideContent(this.slideIndex);
    } else {
      this.slideIndex = this.slideIndex + 1;
      if (this.slideIndex < this.course.slides.length)
        this.slideContent = this.getSlideContent(this.slideIndex);
    }

    this.showCongratsUpdateLastSlideAttempt();
  }

  quizSlidePreViewDatGet(data: QuizSlide) {
    let answerCorrect: CorrectAnswer = CorrectAnswer.notTaken;
    let currentQuestion = this.course.slides[this.slideIndex];
    let isAnswerAttempted: boolean = true;

    this.quizContent = data;
    if (currentQuestion?.stepOne?.isQuestionMandatoryForQssp) {
      if (data.questionType == "multiChoice") {
        let selectedAnswer = data.multiChoice?.find(
          (x: any) => x.user_answer == true,
        );
        if (selectedAnswer) {
          answerCorrect =
            selectedAnswer?.correct_answer == true
              ? CorrectAnswer.correct
              : CorrectAnswer.incorrect;
        }
      } else if (data.questionType == "multiSelect") {
        let correctAnswers = data.multiSelect?.filter(
          (x: any) => x.correct_answer == true,
        );
        let selectedAnswers = data.multiSelect?.filter(
          (x: any) => x.user_answer == true,
        );
        if (selectedAnswers?.length && correctAnswers?.length) {
          answerCorrect = this.arraysEqual(correctAnswers, selectedAnswers)
            ? CorrectAnswer.correct
            : CorrectAnswer.incorrect;
        }
      }

      this.quizContent.answerStatus = answerCorrect;
      let oldIndex = this.slideIndex;

      if (
        answerCorrect == CorrectAnswer.notTaken ||
        (currentQuestion.stepOne.isFreeFormQuestionMandatory &&
          !data?.commentsFromConfiguration?.userAnswer)
      ) {
        isAnswerAttempted = false;
        this.getDisableNextButton();
      } else if (
        !this.course?.globalConfigs?.correctIncorrectAnswerExist &&
        answerCorrect == CorrectAnswer.correct
      ) {
        this.slideIndex = this.slideIndex + 1;
      } else if (
        answerCorrect == CorrectAnswer.correct &&
        currentQuestion.stepOne.correctAnswerProceeding == "1"
      ) {
        this.slideIndex = this.slideIndex + 1;
      } else if (
        this.course?.globalConfigs?.correctIncorrectAnswerExist &&
        answerCorrect == CorrectAnswer.correct &&
        currentQuestion.stepOne.correctAnswerProceeding == "2"
      ) {
        this.showLayerTwoContent(this.getCorrectAnswerIndex(),
          () => {
            this.handleQuizSlideLayerTwoCallback();
          }
        );
      } else if (
        answerCorrect == CorrectAnswer.incorrect &&
        currentQuestion.stepOne.incorrectAnswerProceeding == "1"
      ) {
        this.slideIndex = this.slideIndex + 1;
      } else if (
        this.course?.globalConfigs?.correctIncorrectAnswerExist &&
        answerCorrect == CorrectAnswer.incorrect &&
        currentQuestion.stepOne.incorrectAnswerProceeding == "2"
      ) {
        this.showLayerTwoContent(0,
          () => {
            this.handleQuizSlideLayerTwoCallback()
          }
        );
      } else if (
        this.course?.globalConfigs?.correctIncorrectAnswerExist &&
        answerCorrect == CorrectAnswer.incorrect &&
        currentQuestion.stepOne.incorrectAnswerProceeding == "3"
      ) {
        this.showLayerTwoContent(0, () => { });
      }
      if (
        oldIndex != this.slideIndex &&
        this.slideIndex < this.course.slides.length
      )
        this.slideContent = this.getSlideContent(this.slideIndex);
    }
    else {
      this.slideIndex = this.slideIndex + 1;
      if (this.slideIndex < this.course.slides.length)
        this.slideContent = this.getSlideContent(this.slideIndex);
    }

    if (isAnswerAttempted) {
      if (this.attempt?.id && !this.isBuilder) {
        this.updateAttempt().then(() => {
          this.getRegistration();
        });
      }
      if (!this.previewMode) {
        this.updateLastSlide();
      }
    }
  }

  showCongratsUpdateLastSlideAttempt() {
    const slideIndexCheck = this._isQuizCourse || this._isQuizSlide ? this.slideIndex : this.slideIndex + 1;

    if (slideIndexCheck >= this.course.slides.length) {
      this.slideContent = null;
      if (this.attempt?.id && !this.isBuilder) {
        this.updateAttempt().then(() => {
          this.getRegistration().then(() => {
            this.setFinalResults();
            if (this._isQuizCourse) {
              this.flgCongratulation = true;
            }
          });
        });
        this.updateLastSlide();
        if (this._isQuizCourse) {
          this.updateRegistration();
        }
      } else if (this._isQuizCourse) {
        this.flgCongratulation = true;
      }
    }
  }

  showLayerTwoContent(e: any, onOKFunction?: any) {
    if (e?.layerType == LAYER_OPTIONS.L1_ID) {
      if (this._isEventCourse && this.course.slides[this.slideIndex]?.stepOne?.layerOptions != LAYER_OPTIONS.L1_OP_5) {
        this.checkCourseSlideCompletionStatus.next(true);
        return;
      }
    }
    else if (
      e?.layerType == LAYER_OPTIONS.L1_OP_5
    ) {
      if (
        (this.registration?.completionPercentage != 100 || this.consentViewIteration == 0) &&
        !this.isBuilder
      ) {
        this.handleConsentUpdates(e);
      }
      else if (this.isBuilder) {
        e.index = 0;
      }
    }

    let index = e.index || e.index == 0 ? e.index : e;
    if (index > -1) {
      try {
        this.layerViewStatus[this.slideIndex][index] = true;
      } catch (ex) { }
    }
    if (e?.layerType == LAYER_OPTIONS.L1_OP_5) {
      return; // We do not need to call a modal on consentPage
    }

    let layerTwoContent: any = {};
    if (!e.missedClick) {
      layerTwoContent =
        this.course.slides[this.slideIndex]?.stepThree?.modalForms?.[index];
      if (!layerTwoContent) {
        console.error("Layer 2 content not found!");
        return;
      }
    }
    else {
      layerTwoContent =
        this.course.slides[this.slideIndex]?.stepThree?.modalForms?.[
        this.course?.slides?.[this.slideIndex]?.stepTwo.hotspots.length
        ];
    }

    let modal: any = {
      nzTitle: layerTwoContent?.slideTitle || " ",
      nzContent: LayerTwoModalComponent,
      nzData: {
        text: layerTwoContent?.editor,
        image: layerTwoContent?.image,
        data: layerTwoContent,
        globalConfigs: this.course?.globalConfigs,
      },
      nzMaskClosable: false,
      nzWidth: layerTwoContent?.editor?.length < 500 ? "50%" : "80%",
      nzOkText: this.translationService.translate("OK"),
      nzClosable: false,
      nzCancelText: null,
      nzOnOk: () => {
        if (this._isQuizCourse) {
          this.resetUserAnswers();
        }
        if (onOKFunction) {
          onOKFunction();
        }
      }      
    };

    if (this._isEventCourse) {
      this.checkCourseSlideCompletionStatus.next(this.isCurrentSlideFullyViewed())
    }

    return this.modalService.create(modal);
  }

  slideViewValidation() {
    return this.course?.slides?.[this.slideIndex] && !this.flgCongratulation;
  }

  redirectUrl() {
    window.location.href =
      this.registration?.exitUrl || LocalStorageUtils.getRedirectURL();
  }

  resumeCourse(slideIndex: number) {
    this.slideIndex = slideIndex;
    this.slideContent = this.getSlideContent(this.slideIndex);
    this.updateSlideQueryParam();
    this.showCongratsUpdateLastSlideAttempt();
  }

  resumeCourseHandler() {
    if (this._isQuizCourse) {
      if (this.attempt?.Status == AttemptStatus.PASS) {
        if (this.slideIndex == 0) this.slideIndex = this._LastSlideCompleted;
        if (this.slideIndex >= this.course.slides.length)
          this.flgCongratulation = true;
      } else if (
        this.registration &&
        this.registration?.attempts &&
        this.registration?.attempts?.length <
        this.course.globalConfigs.quizMaximumAttempt &&
        this.attempt?.Status == AttemptStatus.FAIL
      ) {
        this.createAttempt().then(() => {
          this.resetRegistration();
        });
        // We want to just let the user take a new quiz instead for now.
      } else if (
        this.registration &&
        this.registration?.attempts &&
        this.registration?.attempts?.length ==
        this.course.globalConfigs.quizMaximumAttempt &&
        this.attempt?.Status == AttemptStatus.FAIL
      ) {
        this.modalService.create({
          nzTitle: this.translationService.translate("INFORMATION"),
          nzContent: `You have reached max attempt(s) allowed to take this quiz.`,
          nzOkText: this.translationService.translate("OK"),
          nzClosable: false,
          nzCancelText: null,
          nzOnOk: () => {
            this.flgCongratulation = true;
          },
        });
      } else if (
        this.registration &&
        this.registration?.attempts &&
        this.attempt?.Status == AttemptStatus.INCOMPLETE
      ) {
        this.resumeCourse(this._LastSlideCompleted - 1);
        // By default, we want the user to continue for now.
      } else {
        if (this.registration) {
          this.createAttempt().then(() => {
            this.getRegistration();
            this.resumeCourse(0);
          });
        } else {
          this.resumeCourse(this.slideIndex);
        }
      }
    
      if (this.attempt?.Valid && this.attempt.Status != AttemptStatus.PASS) {
        this.attempt = undefined;
      }
    } else if (
      this._isEventCourse &&
      this.registration &&
      this.registration.lastSlideCompleted &&
      this.registration.lastSlideCompleted > 0 &&
      this.registration.lastSlideCompleted <= this.course.slides.length
    ) {
      this.resumeCourseModal();
    } else if (
      this._isQuizSlide &&
      this.registration &&
      this.registration.lastSlideCompleted &&
      this.registration.lastSlideCompleted > 0 &&
      this.registration.lastSlideCompleted <= this.course.slides.length
    ) {
      if (this.registration?.attempts) {
        this.resumeCourseModal();
      } else {
        this.createAttempt().then(() => {
          this.getRegistration();
          this.resumeCourse(0);
        });
      }
    }
  }

  selectSlideContent(slideIndex: number) {
    if (this._isQuizCourse) {
      if (!this.slideContent) {
        this.slideContent = this.getSlideContent(this.slideIndex);
      }
      return this.slideContent;
    }
    else if (this._isQuizSlide) {
      if (!this.slideContent) {
        this.slideContent = this.getSlideContent(this.slideIndex);
      }
      return this.slideContent;
    }
    else if (this._isConsentSlide) {
      let consentData = this.course?.slides?.[slideIndex]?.stepTwo;
      if (this.attempt && this.attempt?.QuizResult?.length > 0) {
        consentData.user_consent = this.attempt.QuizResult
          .find((data: any) =>
            data.slideId == this.course?.slides?.[this.slideIndex]?.stepOne.slideId)?.user_consent;
      }
      return consentData;
    }
    else if (this._isEventCourse) {
      return this.course?.slides?.[slideIndex]?.stepTwo;
    }
  }

  getSlideContent(slideIndex: number, skipUpdates?: boolean) {
    if (this._isEventCourse) {
      return this.course?.slides?.[slideIndex]?.stepTwo;
    }
    else if (this._isQuizCourse) {
      if (
        this.quizContent &&
        this.registration?.id &&
        this.attempt &&
        !this.isBuilder
      ) {
        if (!this.isUpdatingAttemptInProgress) {
          this.updateAttempt();
          if (this.quizContent.slideId) {
            this.updateLastSlide();
            if (this._isQuizCourse && !skipUpdates) {
              this.updateRegistration();
            }
          }
        }
      }
      this.createQuizContent(this.course?.slides?.[slideIndex]?.stepTwo);
      return {
        category: this.course?.category?.toString().toLowerCase(),
        tags: this.isBuilder
          ? this.course?.slides?.[slideIndex]?.stepTwo?.tags
          : [], //we want to display tags to Admin only
        quizContent: this.quizContent,
      };
    } else if (this._isQuizSlide) {
      if (
        this.quizContent &&
        this.registration?.id &&
        this.attempt &&
        !this.isBuilder &&
        !skipUpdates
      ) {
        this.updateAttempt();
        if (this.quizContent.slideId) {
          this.updateLastSlide();
        }
      }
      this.createQuizContent(this.course?.slides?.[slideIndex]?.stepTwo);
      return {
        category: this.course?.category?.toString().toLowerCase(),
        tags: this.isBuilder
          ? this.course?.slides?.[slideIndex]?.stepTwo?.tags
          : [], //we want to display tags to Admin only
        quizContent: this.quizContent,
      };
    }
  }

  get isMobile(): boolean {
    return window.innerWidth < 767 ? true : false;
  }

  get isTablet(): boolean {
    return window.innerWidth < 1024 && window.innerWidth > 767 ? true : false;
  }

  get isQuestionDisabled(): boolean {
    return this.course.globalConfigs?.enableBackButton == false &&
      this.attempt &&
      this.attempt?.QuizResult &&
      this.slideIndex < this.attempt?.QuizResult?.length - 1
      ? true
      : false;
  }

  arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (!CommonUtils.deepObjectEqualityCheck(arr1[i], arr2[i])) return false;
    }

    return true;
  }

  calculateQuizResults(): void {
    if (this.attempt) {
      let totalPoints = 0;
      let scoredPoints = 0;

      this.course?.slides
        ?.filter((x: any) => x.stepOne.isQuestionMandatoryForQssp)
        ?.forEach((slide: any) => {
          const questionType = this.mapQuestionType(slide.stepOne.questionType);
          switch (questionType) {
            case "multiChoice":
            case "multiSelect":
              totalPoints += 1;
              break;
            case "horizontalScale":
              let maxPoint = 0;
              slide.stepTwo.horizontalLabels.forEach((option: any) => {
                if (option.point > maxPoint) {
                  maxPoint = option.point;
                }
              });
              totalPoints += maxPoint;
              break;
          }
        });
      this.attempt?.QuizResult.filter(
        (x) => x.isQuestionMandatoryForQssp,
      )?.forEach((question: any) => {
        switch (question.questionType) {
          case "multiChoice":
          case "multiSelect":
            if (question.answerStatus === CorrectAnswer.correct) {
              scoredPoints += 1;
            }
            break;
          case "horizontalScale":
            let maxPoint = 0;
            question.horizontalScale.forEach((option: any) => {
              if (option.user_answer) {
                scoredPoints += option.point;
              }
            });
            break;
        }
      });

      this.attempt.TotalPoints = totalPoints;
      this.attempt.ScoredPoints = scoredPoints;
      this.attempt.Grade = (scoredPoints / totalPoints) * 100;
    }
  }

  allowFeedBackConfig(): boolean {
    let currentQuestion = this.course.slides[this.slideIndex];
    let questionType = this.mapQuestionType(
      currentQuestion.stepOne.questionType,
    );

    let res =
      (questionType == "multiSelect" || questionType == "multiChoice") &&
      (currentQuestion.stepOne.correctAnswerProceeding != "1" ||
        currentQuestion.stepOne.incorrectAnswerProceeding != "1") &&
      currentQuestion.stepOne.isQuestionMandatoryForQssp;

    return res;
  }

  verifyAnswer() {
    let answerCorrect: CorrectAnswer = CorrectAnswer.notTaken;
    let data = this.slideRef.slideContent.quizContent;

    if (data.questionType == "multiSelect") {
      let correctAnswers = data.multiSelect?.filter(
        (x: any) => x.correct_answer == true,
      );
      let selectedAnswers = data.multiSelect?.filter(
        (x: any) => x.user_answer == true,
      );
      if (selectedAnswers?.length && correctAnswers?.length) {
        answerCorrect = this.arraysEqual(correctAnswers, selectedAnswers)
          ? CorrectAnswer.correct
          : CorrectAnswer.incorrect;
      }
    } else if (data.questionType == "multiChoice") {
      let selectedAnswer = data.multiChoice?.find(
        (x: any) => x.user_answer == true,
      );
      if (selectedAnswer) {
        answerCorrect =
          selectedAnswer?.correct_answer == true
            ? CorrectAnswer.correct
            : CorrectAnswer.incorrect;
      }
    }
    let currentQuestion = this.course.slides[this.slideIndex];

    if (answerCorrect == CorrectAnswer.notTaken) {
      this.getDisableNextButton();
    } else if (answerCorrect == CorrectAnswer.correct && currentQuestion.stepOne.correctAnswerProceeding != "1") {
        this.showLayerTwoContent(this.getCorrectAnswerIndex(), () => { });
    } else if (answerCorrect == CorrectAnswer.incorrect && currentQuestion.stepOne.incorrectAnswerProceeding != "1") {
      this.showLayerTwoContent(0, () => { });
    }
  }

  getCorrectAnswerIndex() {
    let currentQuestion = this.course.slides[this.slideIndex];
    // return 0, when the page incorrectAnswerProceeding is 1(Proceed to Next Question)
    if(currentQuestion.stepOne.correctAnswerProceeding != "1" && currentQuestion.stepOne.incorrectAnswerProceeding == "1") 
      return 0;
    else
      return 1;
  }

  resetUserAnswers() {
    const data = this.slideRef.slideContent.quizContent;
    const currentQuestion = this.course.slides[this.slideIndex];
    const answerCorrect = this.getAnswerStatus(data);

    if (currentQuestion.stepOne.incorrectAnswerProceeding === "3" && answerCorrect == CorrectAnswer.incorrect) {
      if (data.questionType === "multiSelect") {
        data.multiSelect?.forEach((answer: any) => {
          answer.user_answer = false;
        });
      } else if (data.questionType === "multiChoice") {
        data.multiChoice?.forEach((answer: any) => {
          answer.user_answer = false;
        });
      }
    }
  }

  getAnswerStatus(data: any): CorrectAnswer {
    let answerCorrect: CorrectAnswer = CorrectAnswer.notTaken;
  
    if (data.questionType === "multiSelect") {
      let correctAnswers = data.multiSelect?.filter((x: any) => x.correct_answer == true);
      let selectedAnswers = data.multiSelect?.filter((x: any) => x.user_answer == true);
      
      if (selectedAnswers?.length && correctAnswers?.length) {
        answerCorrect = this.arraysEqual(correctAnswers, selectedAnswers) 
          ? CorrectAnswer.correct 
          : CorrectAnswer.incorrect;
      }
    } 
    else if (data.questionType === "multiChoice") {
      let selectedAnswer = data.multiChoice?.find((x: any) => x.user_answer == true);
      if (selectedAnswer) {
        answerCorrect = selectedAnswer?.correct_answer == true
          ? CorrectAnswer.correct
          : CorrectAnswer.incorrect;
      }
    }
  
    return answerCorrect;
  }

  restartCourseModal() {
    if (this._LastSlideCompleted == 1 && this.course.slides.length === 1) {
      this.resumeCourse(0);
    } else {
      this.modalService.create({
        nzTitle: this.translationService.translate("RESTART_COURSE"),
        nzContent: this.translationService.translate("RESTART_COURSE_MESSAGE"),
        nzWidth: "40%",
        nzOkText: this.translationService.translate("YES"),
        nzClosable: false,
        nzCancelText: this.translationService.translate("NO"),
        nzOnOk: () => {
          this.flgCongratulation = false;
          this.createAttempt().then(() => {
            this.resetRegistration();
          });
        },
        nzOnCancel: () => {
          this.resumeCourse(this._LastSlideCompleted - 1);
        },
      });
    }
  }

  resumeCourseModal() {
    if ((this._LastSlideCompleted == 1 && this.course.slides.length === 1) ||
      (this.registration?.completionPercentage == 100 &&
        this._LastSlideCompleted == this.course.slides.length &&
        this.course?.category?.toString().toLowerCase() == COURSE_CONST.COURSE_CATEGORY)
    ) {
      this.resumeCourse(0);
    }
    else {
      this.modalService.create({
        nzTitle: this.translationService.translate("RESUME_COURSE"),
        nzContent: this.translationService.translate("RESUME_COURSE_MESSAGE"),
        nzWidth: "40%",
        nzOkText: this.translationService.translate("YES"),
        nzClosable: false,
        nzCancelText: this.translationService.translate("NO"),
        nzOnOk: () => {
          this.resumeCourse(this._LastSlideCompleted);
        },
        nzOnCancel: () => {
          this.resumeCourse(0);
        },
      });
    }
  }

  deleteSlide() {
    const modalRef : NzModalRef = this.modalService.create({
      nzClosable: false,
      nzTitle: this.translationService.translate("CONFIRMATION"),
      nzContent: this.translationService.translate("ALERT_DELETE_SLIDE"),
      nzMaskClosable: false,
      nzFooter: [
        {
          label: this.translationService.translate("YES"),
          onClick: () => {
            if (this.course && this.course.id > 0) {
              this.course.slides.splice(this.slideIndex, 1);
              this.updateCourse();
            }
            modalRef.close(); 
          },
        },
        {
          label:  this.translationService.translate("CANCEL"),
          onClick: () => {
            modalRef.close(); 
          },
        }
      ]
    });
  }

  handleConsentUpdates(consentData: ConsentSlideInteractionEvent) {
    this.quizContent = {
      slideId: this.course?.slides?.[this.slideIndex]?.stepOne.slideId,
      slideType: SlideType.CONSENT,
      pageTitle: this.course?.slides?.[this.slideIndex]?.stepTwo?.slideTitle,
      pageText: this.course?.slides?.[this.slideIndex]?.stepTwo?.editor,
      user_consent: consentData?.consent,
      user_consent_button_label: consentData?.buttonName,
    }

    if (this.registration && !this.attempt) {
      this.createAttempt(this.quizContent).then(() => {
        this.getRegistration();
      });
    }
    else {
      this.updateAttempt();
    }

    this.checkCourseSlideCompletionStatus.next(true);
  }

  handleQuizSlideLayerTwoCallback() {
    let oldIndex = this.slideIndex;
    this.slideIndex = this.slideIndex + 1;

    if (oldIndex != this.slideIndex && this.slideIndex < this.course.slides.length) {
      this.slideContent = this.getSlideContent(this.slideIndex);
    }

    if (this.slideIndex > this.course.slides.length - 1) {
      this.flgCongratulation = true;
    }
  }

  setFinalResults() {
    if (this._isQuizCourse) {
      if (this.course?.globalConfigs.attempt == "1") {
        this.quizResultsToShow = this.registration?.attempts;
      }
      else if (this.course?.globalConfigs.attempt == "2") {
        this.quizResultsToShow = this.registration?.attempts?.filter(
          (x: any) => x.Valid
        );
      }
      else if (this.course?.globalConfigs?.attempt == "3") {
        this.quizResultsToShow = this.registration?.attempts
          ?.filter((x: any) => x.Valid).slice(-1);
      }
    }
    else if (this._isEventCourse || this._isQuizSlide || this._isConsentSlide) {
      this.quizResultsToShow = this.registration?.attempts;
    }
  }

  // Get Methods

  get _isEventCourse() {
    let currentQuestionType =
      this.course?.slides?.[this.slideIndex]?.stepOne.layerOptions;
    return (
      this.course?.category?.toString().toLowerCase() ==
      COURSE_CONST.COURSE_CATEGORY &&
      !(
        currentQuestionType == LAYER_ONE_AND_TWO_OPTIONS[3].value ||
        currentQuestionType == LAYER_ONE_AND_TWO_OPTIONS[4].value
      )
    );
  }

  get _isQuizCourse() {
    return (
      this.course?.category?.toString().toLowerCase() ==
      QUIZ_CONST.QUIZ_CATEGORY
    );
  }

  get _isQuizSlide() {
    let currentQuestionType =
      this.course?.slides?.[
        this.slideIndex < this.course.slides.length
          ? this.slideIndex
          : this.slideIndex - 1
      ]?.stepOne.layerOptions;
    return (
      currentQuestionType == LAYER_ONE_AND_TWO_OPTIONS[3].value ||
      currentQuestionType == LAYER_ONE_AND_TWO_OPTIONS[4].value
    );
  }

  get _isConsentSlide() {
    let currentQuestionType =
      this.course?.slides?.[
        this.slideIndex < this.course.slides.length
          ? this.slideIndex
          : this.slideIndex - 1
      ]?.stepOne.layerOptions;
    return (
      currentQuestionType == LAYER_ONE_OPTIONS[4].value
    );
  }

  get _currentSlideCategory() {
    return this._isEventCourse ? "courseContent" : "quiz";
  }

  get _CurrentSlideContainsAudio() {
    return this.course?.slides?.[this.slideIndex]?.stepTwo?.audios?.length > 0
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

enum CorrectAnswer {
  notTaken = 0,
  correct = 1,
  incorrect = 2,
}
