// src/app/models/auth-response.interface.ts

export interface AuthRequest {
  userId: string;
  secretKey: string;
  database: string;
}

export interface AuthResponse {
  user: {
    userFullName: string;
    username: string;
    isBuilder: boolean;
  };
  client: {
    id: number;
    clientCss: any; // Adjust the type based on your actual data
    clientLogo: string;
  };
  strapiToken: string;
}

export const USER_ROLE = {
  SUPER_ADMIN: "superAdmin",
  BUILDER: "builder",
  VIEWER: "viewer"
}