import { Component } from "@angular/core";
import { CourseService } from "src/app/services/course.service";
import { LocalStorageUtils } from "src/app/shared/utils/utils";

@Component({
  selector: "app-un-authorized",
  templateUrl: "./un-authorized.component.html",
  styleUrls: ["./un-authorized.component.scss"],
})
export class UnAuthorizedComponent {
  styleObject: any;

  constructor(private courseService: CourseService) {}
  ngOnInit(): void {
    this.courseService.applyStyles();
  }

  getRedirectUrl() {
    window.location.href = LocalStorageUtils.getRedirectURL();
  }
}
