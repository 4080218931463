<section class="courses-listing-slide">
  <header class="header">
    <h1 nz-typography>  {{ 'COURSES' | translate }} - {{ getDisplayText(category) }} </h1>
    <div class="header-buttons">
      <button
      [routerLink]="['/']"
      nz-button
      nzType="primary"
      nzSize="large"
      >
      <span nz-icon nzType="left" nzTheme="outline"></span>
      {{ ('GO_HOME' | translate) }}
    </button>
    </div>
  </header>
  <nz-tabset (nzSelectChange)="tabChanged($event)" [(nzSelectedIndex)]="selectedTabIndex">
    <nz-tab nzTitle="Active Events">
      <div class="table-container">
        <nz-table #coursesTable 
          [nzData]="activeCourses" 
          [nzShowPagination]="false"
          [nzFrontPagination]="false">
          <thead>
            <tr>
              <!-- Loop for columns excluding actions -->
              <th
                *ngFor="let column of _tableHeaders">
                {{ column }}
              </th>
              <!-- Action column without sorting and filtering -->
              <th>{{ 'ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of coursesTable.data">
              <td *ngFor="let column of listOfColumns">
                {{ getColumnValue(data, column.name) }}
              </td>
              <td>
                <a
                  (click)="previewCourse(data.id)"
                  class="courseButtonIcon"
                  nz-button
                  nzType="primary"
                  title="{{ ('VIEW_PARTICULAR_COURSE' | translate) | textCase:'sentence' }}"
                  *ngIf="data.totalSlideCount"
                >
                  <span nz-icon nzType="eye" nzTheme="outline"></span>
                </a>
                <a
                  (click)="editCourse(data)"
                  class="courseButtonIcon"
                  title="{{ ('EDIT_PARTICULAR_COURSE' | translate) | textCase:'sentence' }}"
                  nz-button
                  nzType="primary"
                >
                  <span nz-icon nzType="folder-open" nzTheme="outline"></span>
                </a>
                <a
                  (click)="createNextVersion(data)"
                  class="courseButtonIcon"
                  title="{{ ('DUPLICATE_PARTICULAR_COURSE' | translate) | textCase:'sentence' }}"
                  nz-button
                  nzType="primary"
                  *ngIf="checkForDuplicte(data)"
                >
                  <span nz-icon nzType="copy" nzTheme="outline"></span>
                </a>
                <a
                  (click)="createCopy(data)"
                  class="courseButtonIcon"
                  title="{{ ('COPY_PARTICULAR_COURSE' | translate) | textCase:'sentence' }}"
                  nz-button
                  nzType="primary"
                >
                  <span nz-icon nzType="file-add" nzTheme="outline"></span>
                </a>
                <a
                (click)="duplicateToLive(data)"
                class="duplicateToLiveIcon"
                title="{{ ('DUPLICATE_TO_LIVE_PARTICULAR_COURSE' | translate) | textCase:'sentence' }}"
                nz-button
                nzType="primary"
                *ngIf="!production"
              >
                <span nz-icon nzType="send" nzTheme="outline"></span>
              </a>
                <a
                  (click)="softDeleteCourse(data.id)"
                  title="{{ ('DELETE_PARTICULAR_COURSE' | translate) | textCase:'sentence' }}"
                  class="deleteBtn"
                  nz-button
                  nzType="primary"
                  *ngIf="checkCompletedStatus(data)"
                >
                  <span nz-icon nzType="delete" nzTheme="outline"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <br />
        <nz-pagination
          [nzPageIndex]="meta.pagination.page"
          [nzTotal]="meta.pagination.total"
          nzShowSizeChanger
          [nzPageSizeOptions]="pageOptions"
          [nzPageSize]="meta.pagination.pageSize"
          (nzPageIndexChange)="handlePageIndexChange($event)"
          (nzPageSizeChange)="handlePageSizeChange($event)"
        ></nz-pagination>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Soft Deleted Events" *ngIf="_isBuilder">
      <div class="table-container">
        <nz-table #softDeletedCoursesTable 
          [nzData]="softDeletedCourses" 
          [nzShowPagination]="false"
          [nzFrontPagination]="false">
          <thead>
            <tr>
              <!-- Loop for columns excluding actions -->
              <th
                *ngFor="let column of _tableHeaders">
                {{ column }}
              </th>
              <!-- Action column without sorting and filtering -->
              <th>{{ 'ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of softDeletedCoursesTable.data">
              <td *ngFor="let column of listOfColumns">
                {{ getColumnValue(data, column.name) }}
              </td>
              <td>
                <a
                  (click)="previewPage(data.id)"
                  class="courseButtonIcon"
                  nz-button
                  nzType="primary"
                  title="{{ ('VIEW_PARTICULAR_COURSE' | translate) | textCase:'sentence' }}"
                  *ngIf="data.totalSlideCount"
                >
                  <span nz-icon nzType="eye" nzTheme="outline"></span>
                </a>
                <a
                  (click)="restoreCourse(data.id)"
                  class="courseButtonIcon"
                  title="{{ ('RESTORE_PARTICULAR_COURSE' | translate) | textCase:'sentence' }}"
                  nz-button
                  nzType="primary"
                >
                  <span nz-icon nzType="undo" nzTheme="outline"></span>
                </a>
                <a
                  (click)="permenantDeleteCourse(data.id)"
                  title="{{ ('DELETE_PARTICULAR_COURSE' | translate) | textCase:'sentence' }}"
                  class="deleteBtn"
                  nz-button
                  nzType="primary"
                  *ngIf="_isSuperAdmin"
                >
                  <span nz-icon nzType="delete" nzTheme="outline"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <br />
        <nz-pagination
          [nzPageIndex]="meta.pagination.page"
          [nzTotal]="meta.pagination.total"
          nzShowSizeChanger
          [nzPageSizeOptions]="pageOptions"
          [nzPageSize]="meta.pagination.pageSize"
          (nzPageIndexChange)="handlePageIndexChange($event)"
          (nzPageSizeChange)="handlePageSizeChange($event)"
        ></nz-pagination>
      </div>
    </nz-tab>
  </nz-tabset>
</section>

<ng-template #customCloseIcon>
  <span class="ant-btn-primary ant-btn-lg custom-close-btn font-weight-400">
    {{ "EXIT_HOME" | translate }}
  </span>
</ng-template>