import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CourseApiService } from '../services/course.api.service';
import { SpinnerService } from '../services/spinner.service';
import { TranslationService } from "../services/translation.service";
import { PreviewComponent } from '../view-course/components/preview/preview.component';
import { Course } from '../create-course/create-course.interfaces';
import { CourseCategory } from '../models/enum/course-category.enum';
import { CourseRequest } from '../models/course-interface';
import { COURSE_CONST } from '../create-course/data';
import { SwapDirection } from '../models/enum/swap-direction.enum';

@Component({
  selector: 'app-manage-pages',
  templateUrl: './manage-pages.component.html',
  styleUrls: ['./manage-pages.component.scss']
})
export class ManagePagesComponent implements OnInit {
  @ViewChild("customCloseIcon") CustomCloseBtn!: TemplateRef<any>;
  private destroy$ = new Subject<void>();
  private readonly PREVIEW_WIDTH = '80%';
  private readonly MOVE_HIGHLIGHT_DELAY = 3000;

  course: Course;
  courseId: number = -1;
  courseCategory: CourseCategory;
  eventName: string = '';
  recentlyMovedPage: number = -1;
  isCourseCompleted: boolean = false;
  swapDirection = SwapDirection;

  constructor(
    private readonly courseApiService: CourseApiService,
    private readonly modalService: NzModalService,
    public readonly translationService: TranslationService,
    private readonly spinner: SpinnerService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.initializeCourse();
  }

  private initializeCourse(): void {
    const id = this.route.snapshot.paramMap?.get(COURSE_CONST.ID);
    this.courseId = Number(id);

    if (this.courseId > -1) {
      this.loadCourseData();
    } else {
      this.goToEventList();
    }
  }

  private loadCourseData(): void {
    this.spinner.show();
    this.courseApiService.getCourse(this.courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.handleCourseData(response.data.attributes);
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.goToEventList();
        }
      });
  }

  private handleCourseData(courseData: Course | any): void {
    this.course = courseData;
    this.eventName = courseData.title;
    this.courseCategory = courseData?.category as CourseCategory.COURSE;
    this.isCourseCompleted = courseData?.status == "Completed" ? true : false;
  }

  moveQuestion(pageIndex: number, direction: SwapDirection): void {
    const newIndex = direction == this.swapDirection.UP ? pageIndex - 1 : pageIndex + 1;
    if (direction == this.swapDirection.UP) {
      this.course.slides = this.swapPages(pageIndex - 1, pageIndex);
    }
    else {
      this.course.slides = this.swapPages(pageIndex, pageIndex + 1);
    }
    this.recentlyMovedPage = newIndex;

    this.updateCourseSlides();
    this.resetMoveHighlight();
  }

  private resetMoveHighlight(): void {
    setTimeout(() => {
      this.recentlyMovedPage = -1;
    }, this.MOVE_HIGHLIGHT_DELAY);
  }

  private swapPages(firstIndex: number, secondIndex: number): any[] {
    const slides = [...this.course.slides];
    [slides[firstIndex], slides[secondIndex]] = [slides[secondIndex], slides[firstIndex]];
    return slides;
  }

  previewPage(pageIndex: number): void {
    const url = this.generatePreviewUrl(pageIndex);
    this.openPreviewModal(url);
  }

  private generatePreviewUrl(pageIndex: number): string {
    return this.router.createUrlTree(
      [`/view-courses/${this.courseId}`],
      {
        queryParams: {
          previewMode: 'true',
          preViewLastSlide: true,
          slide: pageIndex + 1
        }
      }
    ).toString();
  }

  private openPreviewModal(url: string): void {
    this.modalService.closeAll();
    const modal = this.modalService.create({
      nzTitle: this.translationService.translate('PREVIEW_MODE'),
      nzContent: PreviewComponent,
      nzCloseIcon: this.CustomCloseBtn ,
      nzData: { url },
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: this.PREVIEW_WIDTH
    });

    modal.afterClose.subscribe(() => this.modalService.closeAll());
  }

  editPage(pageIndex: number): void {
    this.router.navigate(
      [`/edit-${this.courseCategory}/${this.courseId}`],
      { queryParams: { step: 2, slide: pageIndex + 1 } }
    );
  }

  insertPage(pageIndex: number): void {
    const updatedSlides = [...this.course.slides];
    updatedSlides.splice(pageIndex + 1, 0, {});

    const courseRequest = this.createCourseRequest(updatedSlides);
    this.updateCourse(courseRequest, () => this.editPage(pageIndex + 1));
  }

  deletePage(pageIndex: number): void {
    const modalRef : NzModalRef  = this.modalService.create({
      nzClosable: false,
      nzTitle: this.translationService.translate('CONFIRMATION'),
      nzContent: this.translationService.translate('ALERT_DELETE_SLIDE'),
      nzMaskClosable: false,
      nzFooter: [
        {
          label: this.translationService.translate("YES"),
          onClick: () => {
            const updatedSlides = this.course.slides.filter((_, index) => index !== pageIndex);
            this.course.slides = updatedSlides;
            this.updateCourseSlides();
            modalRef.close(); 
          },
        },
        {
          label: this.translationService.translate("CANCEL"),
          onClick: () => modalRef.close(), 
        }
      ]
    });
  }

  private updateCourseSlides(): void {
    const courseRequest = this.createCourseRequest(this.course.slides);
    this.updateCourse(courseRequest);
  }

  private createCourseRequest(slides: any[]): CourseRequest {
    return {
      data: {
        slides: JSON.stringify(slides || [])
      }
    };
  }

  private updateCourse(courseData: CourseRequest, callback?: () => void): void {
    this.spinner.show();
    this.courseApiService.updateCourse(this.courseId, courseData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide();
          if (callback) callback();
        },
        error: () => this.spinner.hide()
      });
  }

  navigateToGlobalSettings(): void {
    this.router.navigate(
      [`/${this.courseCategory}`],
      { queryParams: { courseId: this.courseId } }
    );
  }

  goToEventList(): void {
    this.router.navigate(
      ['/view-courses'],
      { queryParams: { category: this.courseCategory } }
    );
  }

  goToStep0() {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([`/edit-${this.courseCategory}/${this.courseId}`], {
        queryParams: {
          step: 1,
          slide: this.course.slides?.length,
        },
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}