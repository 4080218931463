import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import exifr from "exifr";
import { NzUploadFile } from "ng-zorro-antd/upload";

@Injectable({
    providedIn: "root",
})
export class ImageHandlerService {
    constructor(private http: HttpClient) { }

    removeExifMetadata(file: NzUploadFile): Promise<NzUploadFile> {
        return new Promise(async (resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    const img = new Image();
                    img.src = event.target.result;

                    img.onload = () => {
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");

                        if (!ctx) return reject("Canvas context not supported");

                        canvas.width = img.width;
                        canvas.height = img.height;

                        ctx.drawImage(img, 0, 0, img.width, img.height);

                        canvas.toBlob((blob) => {
                            if (!blob) return reject("Failed to process image");
                            const newFile = new File([blob], file.name, { type: file.type });
                            resolve(newFile as unknown as NzUploadFile);
                        }, file.type);
                    };
                };
                reader.readAsDataURL(file as unknown as File);
            } catch (error) {
                reject(error);
            }
        });
    }
}
