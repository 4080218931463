import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslationPipe } from "./pipes/translation.pipe";
import { LazyLoadDirective } from "./directives/lazy-loading-image.directive";
import { TextCasePipe } from "./pipes/text-case.pipe";

@NgModule({
  declarations: [TranslationPipe, LazyLoadDirective, TextCasePipe],
  imports: [CommonModule],
  exports: [TranslationPipe, LazyLoadDirective, TextCasePipe],
})
export class SharedModule {}
