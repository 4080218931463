import { LAYER_OPTIONS } from "../data";

export class CourseUtilityHelper {

    static isSlideFullyCompleted(slide: any): boolean {
        if (!slide) return false;

        switch (slide?.stepOne?.layer) {
            case LAYER_OPTIONS.L1:
                return slide.stepTwo?.isStepValid ?? false;
            case LAYER_OPTIONS.L2:
                return (slide.stepTwo?.isStepValid ?? false) && (slide.stepThree?.isStepValid ?? false);
            default:
                return false;
        }
    }

    static isEventCourseFullyComplete(slides: any): boolean {
        return slides?.every((slide: any) => this.isSlideFullyCompleted(slide)) ?? false;
    }

    static isEventCourseSlideCompleted(slides: any[], index: number): boolean {
        const slide = slides?.[index - 1] ?? null;
        return this.isSlideFullyCompleted(slide);
    }
}

