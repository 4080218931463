<div class="headings">
  <h4 nz-typography>{{ 'CREATE_EDIT_COURSE' | translate }}</h4>
</div>
<div class="buttons-wrapper" nz-row [nzGutter]="[24, 24]">
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handlePreviewLastSlide()"
    >
      {{ "PREVIEW_LAST_PAGE" | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handleEditLastSlide()"
    >
      {{ "EDIT_LAST_PAGE" | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handleAddSlide()"
    >
      {{ 'ADD_SLIDE' | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handlePreview()"
      [disabled]="!flgPreview"
    >
      {{ 'PREVIEW' | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handleExit()"
    >
      {{ 'SAVE_EXIT' | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handleFinish()"
    >
      {{ 'FINISH' | translate }}
    </button>
  </div>
</div>
