import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NZ_I18N } from "ng-zorro-antd/i18n";
import { en_US } from "ng-zorro-antd/i18n";
import en from "@angular/common/locales/en";
import fr from "@angular/common/locales/fr";
import { registerLocaleData } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzMessageModule } from "ng-zorro-antd/message";
import { QuillModule } from "ngx-quill";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzGridModule } from "ng-zorro-antd/grid";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CourseHomeComponent } from "./create-course/components/course-home/course-home.component";
import { CreateCourseComponent } from "./create-course/create-course.component";
import { StepOneComponent } from "./create-course/components/step-one/step-one.component";
import { StepTwoComponent } from "./create-course/components/step-two/step-two.component";
import { StepThreeComponent } from "./create-course/components/step-three/step-three.component";
import { ViewCoursesComponent } from "./view-courses/view-courses.component";
import { ViewCourseComponent } from "./view-course/view-course.component";
import { LayerTwoModalComponent } from "./view-course/components/layer-two-modal/layer-two-modal.component";
import { NzModalModule } from "ng-zorro-antd/modal";
import { CategoriesComponent } from "./categories/categories.component";
import { StepZeroComponent } from "./create-course/components/step-zero/step-zero.component";
import { CongratulationComponent } from "./view-course/congratulation/congratulation.component";
import { StartComponent } from "./start/start.component";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NgxSpinnerModule } from "ngx-spinner";
import { TranslationService } from "./services/translation.service";
import { JwtInterceptor } from "./core/jwt.interceptor";
import { ForbiddenComponent } from "./error-handler/forbidden/forbidden.component";
import { NotFoundComponent } from "./error-handler/not-found/not-found.component";
import { UnAuthorizedComponent } from "./error-handler/un-authorized/un-authorized.component";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { PreviewComponent } from "./view-course/components/preview/preview.component";
import { SpinnerComponent } from "./core/component/spinner/spinner.component";
import { DomSanitizerPipe } from "./core/pipes/dom-sanitizer.pipe";
import { SlideComponent } from "./view-course/components/slide/slide.component";
import { VideoPlayerComponent } from "./core/component/video-player/video-player.component";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { LanguageSelectorComponent } from "./core/language-selector/language-selector.component";
import { ContentEditorComponent } from "./create-course/components/shared/content-editor/content-editor.component";
import { AudioPlayerComponent } from "./core/audio-player/audio-player.component";
import { AudioRecordingComponent } from "./core/audio-recording/audio-recording.component";
import { CreateQuizComponent } from "./create-quiz/create-quiz.component";
import { QuizHomeComponent } from "./create-quiz/components/quiz-home/quiz-home.component";
import { QuizStepZeroComponent } from "./create-quiz/components/quiz-step-zero/quiz-step-zero.component";
import { QuizStepOneComponent } from "./create-quiz/components/quiz-step-one/quiz-step-one.component";
import { QuizStepTwoComponent } from "./create-quiz/components/quiz-step-two/quiz-step-two.component";
import { QuizStepThreeComponent } from "./create-quiz/components/quiz-step-three/quiz-step-three.component";
import { QuizContentEditorComponent } from "./create-quiz/components/shared/quiz-content-editor/quiz-content-editor.component";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { HotspotImgModule } from "./hotspot-img/hotspot-img.module";
import Quill from "quill";
import { SharedModule } from "./shared/shared.module";
import { ManagePagesComponent } from './manage-pages/manage-pages.component';

registerLocaleData(en);
registerLocaleData(fr);

// Define custom size and font options
const SizeStyle = Quill.import("attributors/style/size");
SizeStyle.whitelist = [
  "12pt",
  "14pt",
  "16pt",
  "18pt",
  "19pt",
  "20pt",
  "21pt",
  "22pt",
  "24pt",
  "26pt",
  "28pt",
  "30pt",
  "32pt",
  "34pt",
  "36pt",
  "38pt",
  "40pt",
];
Quill.register(SizeStyle, true);

const FontStyle = Quill.import("attributors/style/font");
FontStyle.whitelist = [
  "Arial",
  "Mirza",
  "Roboto",
  "Aref Ruqaa",
  "Serif",
  "Sans-serif",
  "Monospace",
  "Times New Roman",
];
Quill.register(FontStyle, true);

@NgModule({
  declarations: [
    // Components
    AppComponent,
    CourseHomeComponent,
    CreateCourseComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    ViewCoursesComponent,
    ViewCourseComponent,
    LayerTwoModalComponent,
    CategoriesComponent,
    StepZeroComponent,
    NotFoundComponent,
    ForbiddenComponent,
    CongratulationComponent,
    StartComponent,
    ForbiddenComponent,
    UnAuthorizedComponent,
    PreviewComponent,
    SpinnerComponent,
    SlideComponent,
    VideoPlayerComponent,
    LanguageSelectorComponent,
    ContentEditorComponent,
    AudioPlayerComponent,
    AudioRecordingComponent,
    CreateQuizComponent,
    QuizHomeComponent,
    QuizStepZeroComponent,
    QuizStepOneComponent,
    QuizStepTwoComponent,
    QuizStepThreeComponent,
    QuizContentEditorComponent,
    ManagePagesComponent,
    // Pipes
    DomSanitizerPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzTypographyModule,
    NzButtonModule,
    NzStepsModule,
    NzIconModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
    NzDividerModule,
    NzSelectModule,
    NzUploadModule,
    NzMessageModule,
    NzTableModule,
    NzCollapseModule,
    NzGridModule,
    NzModalModule,
    NzTabsModule,
    NzSpinModule,
    NzPaginationModule,
    NzCardModule,
    NzToolTipModule,
    NzAlertModule,
    NzSwitchModule,
    NzPopconfirmModule,
    NzCheckboxModule,
    NzInputNumberModule,
    HotspotImgModule,
    SharedModule,
    NgxSpinnerModule.forRoot({ type: "ball-scale-multiple" }),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          //[{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction
          [{ size: SizeStyle.whitelist }],
          [{ font: FontStyle.whitelist }],
          [{ align: [] }], // Text alignment
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["link", 'image'], // Add the link button
          ["clean"],
        ],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: APP_INITIALIZER,
      useFactory: (translationService: TranslationService) => () =>
        translationService.loadTranslations("en"),
      deps: [TranslationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
