<div class="steps-button-header">
  <button
    nz-button
    nzType="primary"
    [hidden]="currentStep == 1 || currentStep == 0"
    nzSize="large"
    (click)="handleSave()"
  >
    {{ "SAVE_HOME" | translate }}
  </button>
  <button
  nz-button
  nzType="primary"
  [hidden]="currentStep == 1 || currentStep == 0"
  nzSize="large"
  (click)="handlePreviewCallBack(true,  this.slideNumber + 1, true)"
  >
  {{ "PREVIEW_HOME" | translate }}
</button>
  <button
    nz-button
    nzType="primary"
    [hidden]="currentStep == 1 || currentStep == 0"
    nzSize="large"
    (click)="handleExit()"
  >
    {{ "EXIT_HOME" | translate }}
  </button>
</div>

<p>Page : {{this.slideNumber + 1}}  | Step: {{ this.currentStep }}</p>


<div
  [class.hide]="onLoad"
  class="step-content-wrapper"
  [class.step-two]="currentStep === 1"
  [class.step-three]="currentStep === 2"
>
  <app-quiz-step-zero
    *ngIf="this.currentStep === 0"
    [flgPreview]="flgPreview"
    (addAnotherSlide)="handleAddAnotherSlide()"
    (finish)="handleFinishCallBack()"
    (preview)="handlePreviewCallBack()"
    (previewLastQuestion)="handlePreviewLastQuestion()"
    (editLastQuestion)="handleEditLastQuestion()"
  >
  </app-quiz-step-zero>
  <app-quiz-step-one
    *ngIf="this.currentStep === 1"
    [globalConfigs]="this.course?.globalConfigs"
    (formSubmitted)="handleSubmitCallBack($event, 'stepOne')"
  >
  </app-quiz-step-one>
  <app-quiz-step-two
    *ngIf="this.currentStep === 2"
    (formSubmitted)="handleSubmitCallBack($event, 'stepTwo')"
    (imageDelete)="handleImageDeleteCallBack($event)"
  >
  </app-quiz-step-two>
  <app-quiz-step-three
    *ngIf="this.currentStep === 3"
    (formSubmitted)="handleSubmitCallBack($event, 'stepThree')"
  >
  </app-quiz-step-three>
</div>

<div class="steps-footer">
  <button
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="goBack()"
    *ngIf="currentStep >= 0"
  >
    <span nz-icon nzType="left" nzTheme="outline"></span>
    {{ "BACK" | translate }}
  </button>

  <button
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="goForward()"
    *ngIf="currentStep > 0"
  >
    {{ "NEXT" | translate }}
    <span nz-icon nzType="right" nzTheme="outline"></span>
  </button>
</div>
