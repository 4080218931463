<div class="headings">
  <h4 nz-typography>{{ "CREATE_EDIT_QUIZ" | translate }}</h4>
</div>
<div class="buttons-wrapper" nz-row [nzGutter]="[24, 24]">
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handlePreviewLastQuestion()"
    >
      {{ "PREVIEW_LAST_QUESTION" | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handleEditLastQuestion()"
    >
      {{ "EDIT_LAST_QUESTION" | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handleAddQuestion()"
    >
      {{ "ADD_NEW_QUESTION" | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handlePreview()"
      [disabled]="!flgPreview"
    >
      {{ "PREVIEW_QUESTION_FROM_START" | translate }}
    </button>
  </div>
  
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handleExit()"
    >
      {{ "SAVE_EXIT" | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      class="card-button"
      nz-button
      nzType="primary"
      (click)="handleFinish()"
    >
      {{ "FINISH" | translate }}
    </button>
  </div>
</div>
