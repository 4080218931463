import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textCase'
})
export class TextCasePipe implements PipeTransform {
  transform(value: string, caseType: 'lower' | 'upper' | 'capitalize' | 'sentence'): string {
    if (!value) return '';
    
    switch (caseType) {
      case 'lower':
        return value.toLowerCase();
      case 'upper':
        return value.toUpperCase();
      case 'capitalize':
        return value.replace(/\b\w/g, char => char.toUpperCase());
      case 'sentence':
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      default:
        return value;
    }
  }
}