import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SafeType } from "../../../models/enum/safe-type.enum";
import { COURSE_CONST, LAYER_ONE_AND_TWO_OPTIONS, LAYER_ONE_OPTIONS, LAYER_OPTIONS, VIDEO_PLAYER_CONSTANT } from "src/app/create-course/data";
import { CourseService } from "src/app/services/course.service";
import { SlideService } from "src/app/services/slide.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { QUIZ_CONST } from "src/app/create-quiz/data";

@Component({
  selector: "app-slide",
  templateUrl: "./slide.component.html",
  styleUrls: ["./slide.component.scss"],
})
export class SlideComponent implements OnInit, OnChanges {
  @Output() assetClicked: EventEmitter<any> = new EventEmitter();
  @Output() quizClicked: EventEmitter<any> = new EventEmitter();

  @Input() slideContent: any;
  @Input() stepOne: any;

  @Input() videoPlayOption: string = VIDEO_PLAYER_CONSTANT.AUTOMATIC;
  @Input() globalConfig!: any;
  @Input() openedInModal: boolean = false;
  @Input() buttonConfig!: any;
  @Input() isLoading: boolean = false;
  @Input() previewMode: boolean = false;
  @Input() preViewLastSlide: boolean = false;

  @Input() disabled: boolean = false;
  @Input() courseCategory?: string = "";
  @Input() completionPercentage?: number = 0;
  @Input() consentViewIteration: number = 0;

  assetImageCount: number = 0;
  assetContainsVideo: boolean = false;
  assetContainsButton: boolean = false;
  assetContainsAudio: boolean = false;
  viewedButton: any = [];
  courseOrSlideCategory: string = "";

  isIOS: any = false;
  // This video format remains same for any video type uploaded
  defaultVideoFormat: string = "video/mp4";
  safeType = SafeType;
  disableBtnSelection: boolean = false;

  constructor(
    public courseService: CourseService,
    public slideService: SlideService,
    public deviceService: DeviceDetectorService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isLoading = true;
    this.setHTMLEditorImageStyle();
    this.setCurrentSlideCategory();
    this.assetContainsVideo =
      this.slideContent?.videos?.length > 0 ? true : false;
    this.assetContainsButton =
      this.slideContent?.buttons?.length > 0 ? true : false;
    this.assetContainsAudio =
      this.slideContent?.audios?.length > 0 ? true : false;
    this.assetImageCount = this.slideContent?.images?.length
      ? this.slideContent?.images?.length
      : 0;
    if (this.slideContent && this.slideContent?.hotspots?.length == 0) {
      this.slideContent.hotspots = null;
    }
    this.viewedButton = [];

    // Consent Button Default Selection Handler
    this.handleConsentButtonSelection();

    this.isIOS =
      this.deviceService.os == "iOS" || this.deviceService.os == "Mac"
        ? true
        : false;
    setTimeout(() => {
      this.isLoading = false;
    }, 100);
  }

  setHTMLEditorImageStyle() {
    if(this.slideContent && this.slideContent.editor)
      this.slideContent.editor = this.slideContent.editor.replace(
      /<img /g,
      '<img style="display: block; margin: 0 auto; max-width: 100%; height: auto;" '
    );
  }
  onRadioChange(selectedAnswer: any, data: any) {
    data.forEach((answer: any) => {
      answer.user_answer = answer === selectedAnswer;
    });
  }

  /* Getter Functions */

  get getAssetContainerClasses(): string {
    let combinedClass = "";
    if (
      this.assetImageCount === 1 ||
      this.assetContainsButton ||
      (this.slideContent.editor && this.assetContainsVideo)
    ) {
      combinedClass = `
      col-md-6 col-lg-6 col-sm-12 flex-col justify-content-between 
      ${this.isMobile && !this.assetContainsButton ? "h-50vh" : ""} 
      ${this.isMobile && this.assetContainsButton ? "h-fit" : ""}`;
    }
    else if (this.assetImageCount === 2) {
      combinedClass = `
      d-flex col-md-6 col-lg-6 col-sm-12 flex-col justify-content-between 
      ${this.isMobile ? "h-auto" : ""}`;
    }
    else if (this.assetImageCount > 2) {
      combinedClass = `
      d-flex col-md-12 
      ${this.isMobile ? "flex-col" : "flex-row"} 
      ${this.assetContainsAudio && !this.isMobile && !this.openedInModal ? "h-48" : ""}
      ${this.openedInModal ? "h-40" : ""}
      justify-content-between 
      ${this.isMobile ? "h-auto" : ""}`;
    }
    else if (!this.slideContent.editor && this.assetContainsVideo) {
      combinedClass = `
      d-flex col-md-12 flex-row h-100 justify-content-between 
      ${this.isMobile ? "h-auto" : ""}`;
    }
    else {
      combinedClass = `
      d-flex col-md-12 flex-row h-100 justify-content-between 
      ${this.isMobile ? "h-auto" : ""}`;
    }
    // Additional Adjustment
    if (this.assetContainsAudio && !this.isMobile && !(this.assetImageCount > 2)) {
      combinedClass = combinedClass + (this.openedInModal ? " h-90" : " h-100")
    }
    else if(this.isMobile){
      combinedClass = combinedClass + " mt-10"
    }

    return combinedClass;
  }


  get assetArray(): any[] {
    if (this.slideContent.images && this.slideContent.images.length > 0) {
      return this.slideContent.images;
    } else if (
      this.slideContent.videos &&
      this.slideContent.videos.length > 0
    ) {
      return this.slideContent.videos;
    } else if (
      this.slideContent.buttons &&
      this.slideContent.buttons.length > 0
    ) {
      return [this.slideContent.buttons];
    }
    return [];
  }

  get audioAsset(): any {
    if (this.slideContent.audios && this.slideContent.audios.length > 0) {
      return this.slideContent.audios[0].url;
    }
    return null;
  }

  get autoplayVideo(): boolean {
    return this.videoPlayOption == VIDEO_PLAYER_CONSTANT.AUTOMATIC
      ? true
      : false;
  }

  get isWidthGreaterThanHeight(): boolean {
    return window.innerWidth > window.innerHeight;
  }

  get isMobile(): boolean {
    return window.innerWidth < 767 ? true : false;
  }

  get isTablet(): boolean {
    return window.innerWidth < 1024 && window.innerWidth > 767 ? true : false;
  }
  /* */

  getVideoFileExtension(url: string): string {
    let videoExtension: any = url.split(/[#?]/)[0].split(".").pop()?.trim();
    if (videoExtension) {
      return `video/${url.split(/[#?]/)[0].split(".").pop()?.trim()}`;
    }
    return this.defaultVideoFormat;
  }

  clickAsset(i: number, extraValue?: any) {
    // Slide Action Emitter
    if (this.stepOne.layerOptions != LAYER_ONE_OPTIONS[4].value) {
      this.assetClicked.emit(i);
    }
    else {
      // Specific Configuration For Consent Type Slide
      this.assetClicked.emit({
        layerType: this.stepOne.layerOptions,
        buttonName: extraValue?.name,
        consent: typeof extraValue == "object" ? extraValue?.consent : extraValue
      })
    }
    // Slide View Status
    if (this.assetContainsButton) {
      if (this.stepOne.layerOptions === LAYER_ONE_AND_TWO_OPTIONS[1].value) this.viewedButton.push(i);
      else if (this.stepOne.layerOptions === LAYER_ONE_OPTIONS[4].value) {
        this.viewedButton = [];
        this.viewedButton.push(i);
      }
    }
    if (this.assetContainsAudio) {
      this.slideService._stopAudioPlayer.next(true);
    }
  }

  get buttonHeightControl() {
    let characterMoreThen40 = this.slideContent.buttons.findIndex(
      (x: string) => x.length > 40,
    );
    let characterMoreThen20 = this.slideContent.buttons.findIndex(
      (x: string) => x.length > 20,
    );
    if (characterMoreThen40 > -1) {
      return "h-80";
    } else if (characterMoreThen20 > -1) {
      return "h-60";
    } else {
      return "h-60";
    }
  }

  get _globalConfig() {
    if (this.globalConfig) {
      if (typeof this.globalConfig == "string") {
        return JSON.parse(this.globalConfig);
      } else {
        return this.globalConfig;
      }
    } else {
      return null;
    }
  }

  setCurrentSlideCategory() {
    if (this.stepOne) {
      if (
        this.stepOne?.layerOptions == LAYER_ONE_AND_TWO_OPTIONS[3].value ||
        this.stepOne?.layerOptions == LAYER_ONE_AND_TWO_OPTIONS[4].value
      ) {
        this.courseOrSlideCategory = QUIZ_CONST.QUIZ_CATEGORY;
      }
      else {
        if (this.stepOne?.layerOptions?.includes(LAYER_OPTIONS.L1_ID)) {
          this.assetClicked.emit({ layerType: LAYER_OPTIONS.L1_ID })
        }
        this.courseOrSlideCategory = COURSE_CONST.COURSE_CATEGORY;
      }
    }
  }

  checkMethod() {
    if (this.slideContent?.quizContent?.questionType) {
      this.quizClicked.emit(this.slideContent.quizContent);
    }
  }

  clickHotspot(name: string) {
    let a = this.slideContent?.hotspots.findIndex((x: any) => x.data == name);
    this.assetClicked.emit(a);
  }

  handleMissedCount(count: any) {
    this.assetClicked.emit({ index: 0 - count, missedClick: true });
  }

  handleConsentButtonSelection() {
    if (this.stepOne && (this.stepOne?.layerOptions === LAYER_ONE_OPTIONS[4].value)) {
      if (this.slideContent) {
        let findCheckButtonIndex = this.slideContent.buttons
          .findIndex((button: any) => button.consent == this.slideContent?.user_consent);
        if (findCheckButtonIndex > -1) {
          this.viewedButton.push(findCheckButtonIndex);
          if (this.completionPercentage == 100 && this.consentViewIteration > 0) {
            this.disableBtnSelection = true;
          }
        }
      }
    }
    else {
      this.disableBtnSelection = false;
    }
  }

  buttonDetails(button: any) {
    return (typeof button) == "object" ? button?.name : button
  }

  buttonBackgroundColor(buttonConfig: any): string {
    const defaultColor = this.courseService.getStyle()['primaryColor'];

    if (this.stepOne.layerOptions === LAYER_ONE_AND_TWO_OPTIONS[1].value) {
      return this.buttonConfig?.backgroundColor !== 'default'
        ? this.buttonConfig?.backgroundColor
        : defaultColor;
    }

    if (this.stepOne.layerOptions === LAYER_ONE_OPTIONS[4].value) {
      if (buttonConfig) {
        let buttonBgColor = buttonConfig?.consent
          ? this.buttonConfig?.consentTrueBgColor
          : this.buttonConfig?.consentFalseBgColor
        return buttonBgColor == "default" ? defaultColor : buttonBgColor;
      }
      return defaultColor;
    }

    return defaultColor;
  }

}
