<div class="headings" *ngIf="notMode">
  <h4 nz-typography>
    {{ "QUIZ_BUILDER" | translate }}
  </h4>
</div>

<div class="categories-wrapper" nz-row [nzGutter]="[24, 24]" *ngIf="notMode">
  <div
    nz-col
    class="gutter-row column"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      nz-button
      class="card-button"
      (click)="handleCreateNewCourse()"
      nzType="primary"
      nzSize="large"
    >
      {{ "CREATE_NEW_QUIZ" | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row column"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <a
     [routerLink]="['/view-courses']"
     [queryParams]="{ category: 'quiz' }"
      class="card-button"
      nz-button
      nzType="primary"
      nzSize="large"
    >
      {{ "VIEW_QUIZES" | translate }}
    </a>
  </div>
</div>
<div class="alert-container">
  <nz-alert
    *ngIf="course?.status === 'Completed' && !notMode"
    nzType="warning"
    nzMessage="Warning"
    [nzDescription]="descriptionTpl"
    nzShowIcon
  >
    <ng-template #descriptionTpl>
      <div>
        {{ "READ_ONLY_HOME" | translate }}
      </div>
    </ng-template>
  </nz-alert>
</div>
<div class="steps-header" *ngIf="validateUpdateOrCreate">
    <button nz-button nzType="primary" nzSize="large" (click)="navigateManagePage()">
      {{ "MANAGE_PAGES" | translate }}
    </button>
    <button nz-button nzType="primary" nzSize="large" (click)="handleExit()">
      {{ "EXIT_HOME" | translate }}
    </button>
  </div>

<div
  *ngIf="validateUpdateOrCreate"
  [formGroup]="quizGlobalConfiguration"
  class="quiz-global-configuration-form"
>
  <div class="headings">
    <h4 nz-typography>
      {{ "ENTER_EVENT_QUIZ_NAME" | translate }}
    </h4>
    <p nz-typography>
      <span nz-typography>*{{ "MANDATORY" | translate }}</span>
    </p>
  </div>
  <nz-input-group class="event-name-field" nzSize="large" nzSearch>
    <input
      type="text"
      nz-input
      placeholder="{{ 'ENTER_EVENT_NAME' | translate }}"
      [(ngModel)]="eventName"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="course?.status === 'Completed'"
    />
  </nz-input-group>
  <br />
  <div class="headings">
    <h4 nz-typography>
      {{ "ENTER_FILE_NAME" | translate }}
    </h4>
    <p nz-typography>
      <span nz-typography>*{{ "MANDATORY" | translate }}</span>
    </p>
  </div>
  <nz-input-group class="event-name-field" nzSize="large" nzSearch>
    <input type="text" nz-input placeholder="{{ 'ENTER_FILE_NAME' | translate }}" formControlName="fileName" />
  </nz-input-group>
  <br />
  <div>
    <h5 nz-typography>
      {{ "IS_PROGRESS_DETAILS_ENABLED" | translate }}
    </h5>
    <nz-switch formControlName="progressDetailsEnabled" [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate">
    </nz-switch>
  </div>
  <br />
<div>
  <h5 nz-typography>
    {{ "ENABLE_LOGO_AND_TITLE" | translate }}
  </h5>
  <nz-switch
    formControlName="enableLogoandtitle"
    [nzCheckedChildren]="checkedTemplate"
    [nzUnCheckedChildren]="unCheckedTemplate"
  ></nz-switch>
</div>
  <br />
  <div>
    <h5 nz-typography>
      {{ "ENABLE_BACK_BUTTON" | translate }}
    </h5>
    <nz-switch
      formControlName="enableBackButton"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
    >
    </nz-switch>
  </div>
  <br />
  <div>
    <h5 nz-typography>
      {{ "CAN_USER_CONTINUE" | translate }}
    </h5>
    <nz-switch
      formControlName="canUserContinue"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
    >
    </nz-switch>
  </div>
  <br />
  <div>
    <h5 nz-typography>
      {{ "CORRECT_INCORRECT_ANSWER_EXIST" | translate }}
    </h5>
    <nz-switch
      formControlName="correctIncorrectAnswerExist"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
      [nzDisabled]="courseId > 0"
    >
    </nz-switch>
  </div>
  <br *ngIf="_correctIncorrectAnswerExist" />
  <div class="w-70" *ngIf="_correctIncorrectAnswerExist">
    <br>
    <h5 nz-typography>
      {{ "QUIZ_MAX_ATTEMPTS" | translate }}
    </h5>
    <nz-input-number id="quizMaximumAttempt"
    formControlName="quizMaximumAttempt" [nzMin]="1" [nzMax]="1000000" [nzStep]="1"></nz-input-number>
  </div>
  <br />
  <div *ngIf="_correctIncorrectAnswerExist">
    <h5 nz-typography>
      {{ "IS_THERE_PASSING_GRADE" | translate }}
    </h5>
    <nz-switch
      formControlName="isTherePassingGrade"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
    >
    </nz-switch>
    <div class="w-70" *ngIf="_isTherePassingGrade">
      <br>
      <h5 nz-typography>
        {{ "PASSING_PERCENTAGE" | translate }}
      </h5>
      <nz-input-number id="passingPercentage"
      formControlName="passingPercentage" [nzMin]="0" [nzMax]="100" [nzStep]="1"></nz-input-number>
    </div>
  </div>
  <br *ngIf="_correctIncorrectAnswerExist" />
  <div>
    <h5 nz-typography>
      {{ "DISPLAY_ANSWER_COPY" | translate }}
    </h5>
    <nz-switch
      formControlName="displayAnswerCopy"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
    >
    </nz-switch>
  </div>
  <div class="w-70">
    <br />
    <nz-form-item *ngIf="_displayAnswerCopy">
      <nz-form-label nzRequired nzFor="attempt">
        {{ "WHICH_ATTEMPT" | translate }}
      </nz-form-label>
      <nz-form-control
        nzErrorTip="{{ 'SELECT_PAGE_LAYOUT_OPTION_PLACEHOLDER' | translate }}"
      >
        <nz-select
          id="attempt"
          formControlName="attempt"
          nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
        >
          <nz-option
            *ngFor="let option of attemptData"
            [nzValue]="option.value"
            [nzLabel]="option.label"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <br />
    <nz-form-item *ngIf="_displayAnswerCopy">
      <nz-form-label nzRequired nzFor="validAttemptCount">
        {{ "WHAT_COUNT_AS_A_VALID_ATTEMPT" | translate }}
      </nz-form-label>
      <nz-form-control
        nzErrorTip="{{ 'SELECT_PAGE_LAYOUT_OPTION_PLACEHOLDER' | translate }}"
      >
        <nz-select
          id="validAttemptCount"
          formControlName="validAttemptCount"
          nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
        >
          <nz-option
            *ngFor="let option of validAttemptData"
            [nzValue]="option.value"
            [nzLabel]="option.label"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <br />
    <nz-form-item *ngIf="_displayAnswerCopy">
      <nz-form-label nzRequired nzFor="qsspInvalidation">
        {{ "WHAT_DELINEATES_QSSP" | translate }}
      </nz-form-label>
      <nz-form-control
        nzErrorTip="{{ 'SELECT_PAGE_LAYOUT_OPTION_PLACEHOLDER' | translate }}"
      >
        <nz-select
          id="qsspInvalidation"
          formControlName="qsspInvalidation"
          nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
        >
          <nz-option
            *ngFor="let option of qsspCompleteStatusData"
            [nzValue]="option.value"
            [nzLabel]="option.label"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>

<div class="steps-footer">
  <button
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="goBackToHome()"
    *ngIf="notMode"
  >
    <span nz-icon nzType="left" nzTheme="outline"></span>
    {{ ("BACK" | translate) }}
  </button>
  <button
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="goBack()"
    *ngIf="validateUpdateOrCreate"
  >
    <span nz-icon nzType="left" nzTheme="outline"></span>
    {{ ("BACK" | translate) }}
  </button>
  <button
    nz-button
    nzType="primary"
    nzSize="large"
    *ngIf="validateUpdateOrCreate &&  this.course?.status !== 'Completed'"
    (click)="handleNavigation()"
  >
    {{ ("NEXT" | translate) }}
    <span nz-icon nzType="right" nzTheme="outline"></span>
  </button>
</div>

<!-- Toggle Switch Template -->
<ng-template #checkedTemplate>
  <span nz-icon nzType="check"></span>
</ng-template>
<ng-template #unCheckedTemplate>
  <span nz-icon nzType="close"></span>
</ng-template>
