<div class="headings top-bar">
  <h4 nz-typography>{{ "SELECT_CATEGORY" | translate }}</h4>
  <p nz-typography>*{{ "REQUIRED" | translate }}</p>
  <div class="right-button">
    <button  
      (click)="redirect()"
      nz-button
      nzType="primary"
      nzSize="large"
      class="navigation">
     {{ "RETURN TO " | translate }}{{getClientName()}}
    </button>
  </div>
</div>
<div class="categories-wrapper" nz-row [nzGutter]="[24, 24]">
  <div
    nz-col
    class="gutter-row column"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
    *ngFor="let category of categories"
  >
    <button
      nz-button
      nzType="primary"
      class="card-button"
      disabled="{{ category.disabled }}"
      nzSearch
      (click)="categoryOnClick(category.id)"
    >
      "{{ category.name }}" {{ "EVENT" | translate }}
    </button>
  </div>
</div>
