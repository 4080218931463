import { Component, EventEmitter, Output } from "@angular/core";
import { Option } from "../../../create-course/create-course.interfaces";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { COURSE_CONST, PART_1_SUBTITLES } from "../../../create-course/data";
import { QUIZ_CONST } from "../../data";
import { CourseService } from "../../../services/course.service";
import { Utils } from "../../../core/utils";
import { SupportedAsset } from "src/app/models/enum/supported-asset.enum";
import { TranslationService } from "src/app/services/translation.service";
import { GlobalService } from "src/app/services/global.service";
import { QUESTION_TYPE_DATA } from "../../data";

@Component({
  selector: "app-quiz-step-two",
  templateUrl: "./quiz-step-two.component.html",
  styleUrls: ["./quiz-step-two.component.scss"],
})
export class QuizStepTwoComponent {
  validateForm!: UntypedFormGroup;
  currentLayerOptions!: Option[];
  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() imageDelete: EventEmitter<any> = new EventEmitter();
  questionType!: Option;
  enableFreeFormAnswer: boolean = false;
  // totalParts: string = COURSE_CONST.ONE;
  isFormSubmit: boolean = false;
  styleObject: any;
  router: any;
  disableUploadButton: boolean = false;
  subtitle: any = PART_1_SUBTITLES;
  supportedAsset = SupportedAsset;
  reloadingPreview: boolean = false;
  courseConst = COURSE_CONST;

  public selectedQuestionType!: number;

  constructor(
    private fb: UntypedFormBuilder,
    private courseService: CourseService,
    public translationService: TranslationService,
    public globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
    this.isFormSubmit = false;
    this.prepareStepTwo();
  }

  prepareStepTwo() {
    const courseData = this.courseService.retrieveCourseObject();
    const questionType = QUESTION_TYPE_DATA.find(
      (option) => option.value === courseData?.stepOne?.questionType,
    );

    this.enableFreeFormAnswer = courseData?.stepOne?.enableFreeFormAnswer;

    if (questionType) {
      this.questionType = questionType;
      this.setupFormInstance();
      this.populateImagesFromLocalStorage();
    }
  }

  private populateImagesFromLocalStorage(): void {
    const courseBuilderSteps: any =
      this.courseService.retrieveCourseObject() || {};

    const freeFormQuestion =
      courseBuilderSteps?.stepTwo?.freeFormQuestion || "";
    const questionTitle = courseBuilderSteps?.stepTwo?.questionTitle || "";
    const questionText = courseBuilderSteps?.stepTwo?.questionText || "";
    const question = courseBuilderSteps?.stepTwo?.question || "";
    const tags = courseBuilderSteps?.stepTwo?.tags || [];
    const possibleAnswer = courseBuilderSteps?.stepTwo?.possibleAnswer || [];
    const answerLabel = courseBuilderSteps?.stepTwo?.answerLabels || [];
    const answer = courseBuilderSteps?.stepTwo?.answer || [];
    const correctAnswer = courseBuilderSteps?.stepTwo?.correctAnswer || [];
    const horizontalLabels =
      courseBuilderSteps?.stepTwo?.horizontalLabels || [];
    // const pointSystem = courseBuilderSteps?.stepTwo?.pointSystem || [];
    // const options = courseBuilderSteps?.stepTwo?.options || [];

    this.validateForm
      ?.get(QUIZ_CONST.FREE_FORM_QUESTION)
      ?.setValue(freeFormQuestion);
    this.validateForm?.get(QUIZ_CONST.QUESTION_TITLE)?.setValue(questionTitle);
    this.validateForm?.get(QUIZ_CONST.QUESTION_TEXT)?.setValue(questionText);
    this.validateForm?.get(QUIZ_CONST.QUESTION)?.setValue(question);

    this.populateFormArrayValues(
      tags,
      possibleAnswer,
      answerLabel,
      answer,
      correctAnswer,
      horizontalLabels,
      // pointSystem,
      // options
    );
  }

  private populateFormArrayValues(
    tags: any,
    possibleAnswer: any,
    answerLabel: any,
    answers: any,
    correctAnswer: any,
    horizontalLabel: any,
    // pointSystem: any,
    // options: any
  ) {
    const tagsArray = this.validateForm?.get(QUIZ_CONST.TAGS) as FormArray;
    tags.forEach((tag: any) => {
      tagsArray.push(this.fb.control(tag));
    });

    const possibleAnswerArray = this.validateForm?.get(
      QUIZ_CONST.POSSIBLE_ANSWER,
    ) as FormArray;
    possibleAnswer.forEach((ans: any) => {
      possibleAnswerArray.push(this.fb.control(ans));
    });

    const answerLabelArray = this.validateForm?.get(
      QUIZ_CONST.ANSWER_LABEL,
    ) as FormArray;
    // answerLabel.forEach((label: any) => {
    //   answerLabelArray.push(this.fb.control(label));
    // });

    answerLabel.forEach((labelObj: any) => {
      const labelGroup = this.fb.group({
        label: [labelObj.label, Validators.maxLength(200)], // Assuming you want to set the label value
        correct_answer: [labelObj.correct_answer], // Assuming correct_answer is a boolean
      });
      answerLabelArray.push(labelGroup);
    });

    const answersArray = this.validateForm?.get(
      QUIZ_CONST.ANSWERS,
    ) as FormArray;
    answers.forEach((answers: string) => {
      answersArray.push(this.fb.control(answers));
    });

    const correctAnswerArray = this.validateForm?.get(
      QUIZ_CONST.CORRECT_ANSWER,
    ) as FormArray;
    correctAnswer.forEach((answers: string) => {
      correctAnswerArray.push(this.fb.control(answers));
    });

    const horizontalLabelArray = this.validateForm?.get(
      QUIZ_CONST.HORIZONTAL_LABEL,
    ) as FormArray;
    horizontalLabel.forEach((labelObj: any) => {
      const labelGroup = this.fb.group({
        label: [labelObj.label, Validators.maxLength(200)], // Assuming you want to set the label value
        point: [labelObj.point, Validators.min(0)], // Assuming correct_answer is a boolean
      });
      horizontalLabelArray.push(labelGroup);
    });

    // const pointSystemArray = this.validateForm?.get(
    //   QUIZ_CONST.POINT_SYSTEM,
    // ) as FormArray;
    // pointSystem.forEach((answers: string) => {
    //   pointSystemArray.push(this.fb.control(answers));
    // });

    // const optionsArray = this.validateForm?.get(QUIZ_CONST.OPTIONS) as FormArray;
    // options.forEach((option: any) => {
    //   optionsArray.push(this.fb.control(option));
    // });
  }

  //Setup Form
  private setupFormInstance(): void {
    this.validateForm = this.fb.group({
      questionTitle: ["", []],
      question: ["", [Validators.required]], // Quill editor,
      tags: this.fb.array([]),
      freeFormQuestion: ["", [Validators.required]],
      answerLabels: this.fb.array([]),
      // options: this.fb.array([]),
      // rightAnswer: this.fb.array([]),
      // pointSystem: this.fb.array([]),
      horizontalLabels: this.fb.array([]),
      // horizontalPoints: this.fb.array([]),
    });
  }

  private isAnyCorrect(answerLabels: { correct_answer: boolean }[]): boolean {
    return answerLabels.some((label) => label.correct_answer);
  }

  private areAllPoints(labels: { point: boolean }[]): boolean {
    return labels.every((label) => label.point);
  }

  submitForm(handleSaveOnly?: boolean): boolean {
    this.isFormSubmit = true;
    // Check if any correct answer is marked
    if (
      !this.isAnyCorrect(this.validateForm.value.answerLabels) &&
      (this.questionType.value == "1" || this.questionType.value == "2")
    ) {
      return false;
    }

    if (
      !this.areAllPoints(this.validateForm.value.horizontalLabels) &&
      this.questionType.value == "4"
    ) {
      return false;
    }

    if (this.validateForm.valid || handleSaveOnly) {
      const stepTwo = this.validateForm.value;
      this.courseService.updateCourseObject(
        this.validateForm.value,
        COURSE_CONST.STEP_TWO,
      );
      // Emit the form data for any parent component or service that might be listening
      this.formSubmitted.emit(stepTwo);
    } else {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsTouched();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    return this.validateForm.valid;
  }
}
