import { ViewportScroller } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Utils } from "src/app/shared/utils/utils";
import { CourseService } from "src/app/services/course.service";

@Component({
  selector: "app-step-zero",
  templateUrl: "./step-zero.component.html",
  styleUrls: ["./step-zero.component.scss"],
})
export class StepZeroComponent {
  @Output() addAnotherSlide: EventEmitter<any> = new EventEmitter();
  @Output() finish: EventEmitter<any> = new EventEmitter();
  @Output() preview: EventEmitter<any> = new EventEmitter();
  @Output() previewLastSlide: EventEmitter<any> = new EventEmitter();
  @Output() editLastSlide: EventEmitter<any> = new EventEmitter();

  @Input() flgPreview: boolean = false;

  constructor(
    private router: Router,
    private courseService: CourseService,
    private viewportScorller: ViewportScroller,
  ) {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
  }

  handleAddSlide(): void {
    this.addAnotherSlide.emit();
  }

  handlePreview(): void {
    this.preview.emit();
  }
  
  handleExit(): void {
    this.router.navigate(["/"]);
  }

  handleFinish(): void {
    this.finish.emit();
  }

  handlePreviewLastSlide(): void {
    this.previewLastSlide.emit();
  }

  handleEditLastSlide(): void {
    this.editLastSlide.emit();
  }

  submitForm(): boolean {
    return true;
  }
}
