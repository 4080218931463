import { Component, OnInit } from "@angular/core";
import { NzI18nService } from "ng-zorro-antd/i18n";
import { TranslationService } from "./services/translation.service";
import { LocalStorageUtils } from "src/app/shared/utils/utils";
import { CourseService } from "./services/course.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "course-builder";
  selectedLanguage: string = "en"; // Default selected language
  userFullName: string = "";

  constructor(
    public translationService: TranslationService,
    private courseService: CourseService,
    public router: Router,
  ) {
    this.userFullName = LocalStorageUtils.getUser()?.userFullName;
    this.courseService.applyStyles();
  }

  ngOnInit(): void {
    if (window.innerWidth < 700) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          console.log("Audio Permission Granted !");
          var x: any = document.getElementById("myAudio");
          x?.play();
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
        });
    }
  }

  changeLanguage(language: string): void {
    this.selectedLanguage = language;
    this.translationService.loadTranslations(language).subscribe();
  }

  get _disableHeaderInViewCourse(): boolean {
    return true; //this.router.url.includes("view-course");
  }
}
