import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, catchError, map } from "rxjs";
import { environment } from "../../environments/environment";
import { LocalStorageUtils } from "../core/utils";
import {
  CourseListResponse,
  CourseRequest,
  CourseResponse,
  RegistrationRequest,
  RegistrationResponse,
  RegistrationGetResponse,
  AttemptRequest,
  AttemptResponse,
} from "../models/course-interface";
import { QUIZ_CONST } from "../create-quiz/data";
import { COURSE_CONST } from "../create-course/data";

@Injectable({
  providedIn: "root",
})
export class CourseApiService {
  private apiUrl = `${environment.apiUrl}courses`;
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.getToken()}`, // Replace with your token retrieval logic
    });
  }

  private getToken(): string {
    // Implement your logic to retrieve the token (e.g., from local storage)
    return JSON.parse(LocalStorageUtils.retrieveFromLocalStorage("authData"))
      ?.strapiToken;
  }

  createCourse(courseRequest: CourseRequest): Observable<CourseResponse> {
    return this.http.post<CourseResponse>(this.apiUrl, courseRequest, {
      headers: this.headers,
    });
  }

  updateCourse(
    courseId: number,
    courseRequest: CourseRequest,
  ): Observable<CourseResponse> {
    return this.http.put<CourseResponse>(
      `${this.apiUrl}/${courseId}`,
      courseRequest,
      { headers: this.headers },
    );
  }

  getCourse(courseId: number): Observable<CourseResponse> {
    return this.http.get<CourseResponse>(`${this.apiUrl}/${courseId}`, {
      headers: this.headers,
    });
  }

  //createa new version
  duplicateCourse(courseId: number): Observable<CourseResponse> {
    return this.http.get<CourseResponse>(
      `${this.apiUrl}/duplicate/${courseId}`,
      {
        headers: this.headers,
      },
    );
  }

  duplicateToLive(courseId: number): Observable<CourseResponse> {
    return this.http.get<CourseResponse>(
      `${this.apiUrl}/duplicateToLive/${courseId}`,
      {
        headers: this.headers,
      },
    );
  }

  createCopy(courseId: number): Observable<CourseResponse> {
    return this.http.get<CourseResponse>(
      `${this.apiUrl}/createCopy/${courseId}`,
      {
        headers: this.headers,
      },
    );
  }

  updateRegistration(
    registrationRequest: RegistrationRequest,
  ): Observable<RegistrationResponse> {
    const apiUrl = `${environment.apiUrl}registrations/updateRegistration`;
    return this.http.put<RegistrationResponse>(apiUrl, registrationRequest, {
      headers: this.headers,
    });
  }

  getRegistration(regId: string): Observable<RegistrationGetResponse> {
    const apiUrl = `${environment.apiUrl}registrations/getRegistration/${regId}`;

    return this.http.get<RegistrationGetResponse>(apiUrl, {
      headers: this.headers,
    });
  }

  createAttempt(req: AttemptRequest): Observable<AttemptResponse> {
    const apiUrl = `${environment.apiUrl}attempts`;

    return this.http.post<AttemptResponse>(apiUrl, req, {
      headers: this.headers,
    });
  }

  updateAttempt(
    attemptId: number,
    req: AttemptRequest,
  ): Observable<AttemptResponse> {
    const apiUrl = `${environment.apiUrl}attempts`;

    return this.http.put<AttemptResponse>(`${apiUrl}/${attemptId}`, req, {
      headers: this.headers,
    });
  }

  getCourses(
    pageIndex: number = 1,
    pageSize: number = 5,
    category: string = COURSE_CONST.COURSE_CATEGORY,
  ): Observable<CourseListResponse> {
    const clientId = LocalStorageUtils.getClient()?.id.toString();

    let params =
      `fields[0]=category&` +
      `fields[1]=totalSlideCount&` +
      `fields[2]=version&` +
      `fields[3]=duplicated&` +
      `fields[4]=title&` +
      `fields[5]=status&` +
      `fields[6]=currentVersion&` +
      `fields[7]=createdAt&` +
      `fields[8]=updatedAt&` +
      `fields[9]=courseVersionIdentifier&` +
      `populate[user][fields][0]=firstName&` +
      `populate[user][fields][1]=lastName&` +
      `filters[client]=${clientId}&` +
      `filters[category]=${category}&` +
      `pagination[withCount]=true&` +
      `pagination[page]=${pageIndex}&` +
      `pagination[pageSize]=${pageSize}&` +
      `sort[0]=updatedAt:desc`;

    if (category == QUIZ_CONST.QUIZ_CATEGORY) {
      params += `&fields[10]=globalConfigs`;
    }
    const url = `${this.apiUrl}?${params}`;
    return this.http.get<CourseListResponse>(url, { headers: this.headers });
  }

  deleteCourse(courseId: number): Observable<CourseResponse> {
    return this.http.delete<CourseResponse>(`${this.apiUrl}/${courseId}`, {
      headers: this.headers,
    });
  }

  uploadFiles(files: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}upload`, files, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.getToken()}`, // Replace with your token retrieval logic
      }),
    });
  }
  deleteFiles(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}upload/files/${id}`, {
      headers: this.headers,
    });
  }
}
