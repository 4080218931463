<div class="headings" *ngIf="notMode()">
  <h4 nz-typography>
    {{ "COURSE_BUILDER" | translate }}
  </h4>
</div>
<div class="alert-container">
  <nz-alert
  *ngIf="course?.status === 'Completed' && !notMode()"
  nzType="warning"
    nzMessage="Warning"
    [nzDescription]="descriptionTpl"
    nzShowIcon
  >
    <ng-template #descriptionTpl>
      <div>
        {{ "READ_ONLY_HOME" | translate }}
      </div>
    </ng-template>
  </nz-alert>
</div>
<div class="steps-header" *ngIf="validateUpdateOrCreate()">
  <button nz-button nzType="primary" nzSize="large" (click)="navigateManagePage()">
    {{ "MANAGE_PAGES" | translate }}
  </button>
  <button nz-button nzType="primary" nzSize="large" (click)="handleExit()">
    {{ "EXIT_HOME" | translate }}
  </button>
</div>
<div class="categories-wrapper" nz-row [nzGutter]="[24, 24]" *ngIf="notMode()">
  <div
    nz-col
    class="gutter-row column"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <button
      nz-button
      class="card-button"
      (click)="handleCreateNewCourse()"
      nzType="primary"
      nzSize="large"
    >
      {{ "CREATE_NEW_COURSE" | translate }}
    </button>
  </div>
  <div
    nz-col
    class="gutter-row column"
    [nzXs]="24 / 1"
    [nzSm]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
  >
    <a
      [routerLink]="['/view-courses']"
      [attr.href]="'/view-courses?category=coursecontent'"
      class="card-button"
      nz-button
      nzType="primary"
      nzSize="large"
    >
      {{ "VIEW_COURSES" | translate }}
    </a>
  </div>
</div>
<div class="headings" *ngIf="validateUpdateOrCreate()">
  <h4 nz-typography>
    {{ "ENTER_EVENT_COURSE_NAME" | translate }}
  </h4>
  <p nz-typography>
    <span nz-typography>*{{ "MANDATORY" | translate }}</span>
  </p>
</div>
<nz-input-group
  class="event-name-field"
  *ngIf="validateUpdateOrCreate()"
  nzSize="large"
  nzSearch
>
  <input
    type="text"
    nz-input
    placeholder="{{ 'ENTER_EVENT_NAME' | translate }}"
    [(ngModel)]="eventName"
    [disabled]="course?.status === 'Completed'"
  />
</nz-input-group>
<br />
<div *ngIf="validateUpdateOrCreate()">
  <h4 nz-typography>
    {{ "ENABLE_LOGO_AND_TITLE" | translate }}
  </h4>
  <nz-switch
    [(ngModel)]="enableLogoandtitle"
    [nzCheckedChildren]="checkedTemplate"
    [nzUnCheckedChildren]="unCheckedTemplate"
    [disabled]="course?.status === 'Completed'"
  ></nz-switch>
  <ng-template #checkedTemplate
    ><span nz-icon nzType="check"></span
  ></ng-template>
  <ng-template #unCheckedTemplate
    ><span nz-icon nzType="close"></span
  ></ng-template>
</div>
<div *ngIf="validateUpdateOrCreate()">
  <h4 nz-typography>
    {{ "ENABLE_AUDIO_AUTOPLAY" | translate }}
  </h4>
  <nz-switch
    [(ngModel)]="enableAudioAutoplay"
    [nzCheckedChildren]="checkedTemplate2" 
    [nzUnCheckedChildren]="unCheckedTemplate2"
    [disabled]="course?.status === 'Completed'"
  ></nz-switch>
  <ng-template #checkedTemplate2
    ><span nz-icon nzType="check"></span
  ></ng-template>
  <ng-template #unCheckedTemplate2
    ><span nz-icon nzType="close"></span
  ></ng-template>
</div>
<div class="steps-footer">
  <button
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="goBackToHome()"
    *ngIf="notMode()"
  >
    <span nz-icon nzType="left" nzTheme="outline"></span>
    {{ ("BACK" | translate) }}
  </button>
  <button
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="goBack()"
    *ngIf="validateUpdateOrCreate()"
  >
    <span nz-icon nzType="left" nzTheme="outline"></span>
    {{ ("BACK" | translate) }}
  </button>

  <button
    nz-button
    nzType="primary"
    nzSize="large"
    *ngIf="validateUpdateOrCreate() &&  this.course?.status !== 'Completed'"
    (click)="handleNavigation()"
  >
    {{ ("NEXT" | translate) }}
    <span nz-icon nzType="right" nzTheme="outline"></span>
  </button>
</div>
