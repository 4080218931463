
export class QuizUtilityHelper {

    public static quizFeedbackPopupOptions: string[] = ["2", "3"];

    static isSlideFullyCompleted(slide: any, isThereCorrectIncorrect: boolean): boolean {
        if (!slide) return false;

        const stepOneValid =
            slide?.stepOne &&
            [slide.stepOne.correctAnswerProceeding, slide.stepOne.incorrectAnswerProceeding]
                .some((answer) => QuizUtilityHelper.quizFeedbackPopupOptions.includes(answer));

        const stepTwoValid = slide?.stepTwo?.isStepValid;
        const stepThreeValid = slide?.stepThree?.isStepValid;

        if (isThereCorrectIncorrect && stepOneValid) {
            return (stepTwoValid && stepThreeValid)
        }
        else {
            return stepTwoValid
        }
    }

    static isEventQuizSlideCompleted(slides: any[], index: number, isThereCorrectIncorrect: boolean): boolean {
        return QuizUtilityHelper.isSlideFullyCompleted(slides[index - 1], isThereCorrectIncorrect);
    }

    static isEventQuizFullyComplete(slides: any, isThereCorrectIncorrect: boolean): boolean {
        return slides?.every((slide: any) => QuizUtilityHelper.isSlideFullyCompleted(slide, isThereCorrectIncorrect));
    }
}
