
<div [ngClass]="{'hide': this.isLoading}">
  <audio
    *ngIf="!openedInModal"
    class="audio-player-width"
    [ngClass]="{
      'player-desk': !previewMode,
      'player-preview-mode': previewMode,
      }"
    #AudioPlayer
    controls
    [autoplay]="audioAutoplay"
    preload="metadata"
  >
    <source [src]="audioSource" type="audio/wav" />
  </audio>

  <audio
    *ngIf="openedInModal"
    class="audio-player-width"
    #ModalAudioPlayer
    controls
    [autoplay]="audioAutoplay"
    preload="metadata"
  >
    <source [src]="audioSource" type="audio/wav" />
  </audio>
</div>
<div 
  *ngIf="isLoading" 
  class="audio-loading" 
  [ngClass]="{'player-desk': !openedInModal && !previewMode, 'player-preview-mode': previewMode && !openedInModal}"
  >
  <div class="d-flex align-items-center justify-content-start" *ngIf="!isError">
    <img src="../../../../assets/images/loading.gif" class="audio-spinner"/>
    <p class="loading-text">{{ (openedInModal ? 'AUDIO_LOADING_MOBILE' : 'AUDIO_LOADING') | translate }}</p>
  </div>
  <div class="d-flex align-items-center justify-content-start" *ngIf="isError">
    <img src="../../../../assets/images/error-icon.png" class="audio-error"/>
    <p class="loading-text">{{ (openedInModal ? 'AUDIO_LOADING_ERR_MOBILE' : 'AUDIO_LOADING_ERR') | translate }}</p>
  </div>
</div>