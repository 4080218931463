import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormArray,
} from "@angular/forms";
import { Option } from "../../../create-course.interfaces";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { Observable } from "rxjs";
import { CommonUtils } from "src/app/core/utils";
import {
  COURSE_CONST,
  PART_1_SUBTITLES,
  LAYER_OPTIONS,
  PART_2_UPLOADING_INSTRUCTIONS,
} from "src/app/create-course/data";
import { SupportedAsset } from "src/app/models/enum/supported-asset.enum";
import { CourseApiService } from "src/app/services/course.api.service";
import { GlobalService } from "src/app/services/global.service";
import { TranslationService } from "src/app/services/translation.service";
import { environment } from "src/environments/environment";
import { CourseService } from "src/app/services/course.service";
import { ManageHotspotImgComponent } from "src/app/hotspot-img/manage-hotspot-img/manage-hotspot-img.component";
@Component({
  selector: "app-content-editor",
  templateUrl: "./content-editor.component.html",
  styleUrls: ["./content-editor.component.scss"],
})
export class ContentEditorComponent implements OnInit, OnChanges {
  currentLayerOptions!: Option[];
  //
  @Input() courseCategory: string = "";
  @Input() parentForm!: UntypedFormGroup;
  @Input() componentName: string = COURSE_CONST.STEP_TWO;
  @Input() fileList: NzUploadFile[] = [];
  @Input() indexOfContentEditor: any = 0;
  @Input() indexOfActiveContentEditor: any = 0;
  @Input() audioFileList: NzUploadFile[] = [];
  @Input() selectedOption: any | null = null;
  @Input() isFormSubmit: boolean = false;
  @Input() buttonConfig: any;
  @Input() disableUploadButton: boolean = false;
  @Input() disableAudioUploadButton: boolean = false;
  @Input() hotspots: any[] = [];
  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() imageDelete: EventEmitter<any> = new EventEmitter();

  //
  totalParts: string = COURSE_CONST.ONE;
  styleObject: any;
  router: any;
  imageConfig: any = { ...environment.imageConfig };
  videoConfig: any = { ...environment.videoConfig };
  audioConfig: any = { ...environment.audioConfig };
  subtitle: any = PART_1_SUBTITLES;
  supportedAsset = SupportedAsset;
  reloadingPreview: boolean = false;
  buttonName: string = "";
  consentType: boolean = true;
  courseConst = COURSE_CONST;
  layerOpt = LAYER_OPTIONS;

  constructor(
    private fb: UntypedFormBuilder,
    private courseApiService: CourseApiService,
    public courseService: CourseService,
    public translationService: TranslationService,
    private modalService: NzModalService,
    public globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    this.handleUploadButtonState();
    this.handleUploadButtonStateForAudio();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleUploadButtonState();
    this.handleUploadButtonStateForAudio();
  }

  /* Default Asset Type Setting  */
  get assetType(): any {
    if (
      this.selectedOption?.value === LAYER_OPTIONS.L1_OP_2 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_L2_OP_1 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_L2_OP_3
    ) {
      return SupportedAsset.IMAGE;
    } else if (
      this.selectedOption?.value === LAYER_OPTIONS.L1_OP_3 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_OP_4
    ) {
      return SupportedAsset.VIDEO;
    } else {
      return null;
    }
  }

  get assetConstantAudio(): any {
    return PART_2_UPLOADING_INSTRUCTIONS.audio;
  }

  get assetConfigurationAudio(): any {
    return this.audioConfig;
  }

  get assetConfiguration(): any {
    if (this.assetType == SupportedAsset.IMAGE) {
      let c = this.imageConfig;
      if (this.selectedOption?.value == LAYER_OPTIONS.L1_L2_OP_3) {
        c.maxFileCount = 1;
      }
      return c;
    } else if (this.assetType == SupportedAsset.VIDEO) {
      return this.videoConfig;
    } else if (this.assetType == SupportedAsset.AUDIO) {
      return this.audioConfig;
    } else {
      return null;
    }
  }

  get assetConstant(): any {
    if (this.assetType == SupportedAsset.IMAGE) {
      return PART_2_UPLOADING_INSTRUCTIONS.image;
    } else if (this.assetType == SupportedAsset.VIDEO) {
      return PART_2_UPLOADING_INSTRUCTIONS.video;
    } else if (this.assetType == SupportedAsset.AUDIO) {
      return PART_2_UPLOADING_INSTRUCTIONS.audio;
    } else {
      return null;
    }
  }

  get buttonArray(): any {
    return this.parentForm?.get("buttons") as FormArray;
  }
  /*  */

  handleUploadClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    // Check if the click was on the remove icon or its parent
    const isRemoveIcon =
      target.classList.contains("ant-upload-list-item-remove") ||
      target.parentElement?.classList.contains("ant-upload-list-item-remove");

    // If not the remove icon and the button is disabled, proceed with your logic
    if (!isRemoveIcon && this.disableUploadButton) {
      var message = this.translationService.translate(
        this.assetConstant.ALERT_MAX_ALLOWED_COUNT,
      );
      this.showUploadDialog(message);
      event.preventDefault(); // Prevent the default action
      event.stopPropagation(); // Stop event propagation
    }
  }

  handleAudioUploadClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    // Check if the click was on the remove icon or its parent
    const isRemoveIcon =
      target.classList.contains("ant-upload-list-item-remove") ||
      target.parentElement?.classList.contains("ant-upload-list-item-remove");

    // If not the remove icon and the button is disabled, proceed with your logic
    if (!isRemoveIcon && this.disableAudioUploadButton) {
      var message = this.translationService.translate(
        "ALERT_MAX_AUDIO_CONDITION",
      );
      this.showUploadDialog(message);
      event.preventDefault(); // Prevent the default action
      event.stopPropagation(); // Stop event propagation
    }
  }

  showUploadDialog(message: string) {
    this.modalService.create({
      nzTitle: "Information",
      nzContent: message,
      nzOkText: "OK",
      nzClosable: false,
      nzCancelText: null, // or nzCancelText: ''
      nzWidth: "40%",
      nzOnOk: () => {
        // Ok button callback logic
      },
    });
  }

  showManageHotspotsDialog(event: any) {
    const modalRef : NzModalRef  = this.modalService.create({
      nzTitle: "Manage Hotspots",
      nzContent: ManageHotspotImgComponent,
      nzData: {
        imageUrl: this.fileList[0]?.url ?? "",
        hotspots: this.hotspots,
      },
      nzOkText: "OK",
      nzClosable: false,
      nzCancelText: null,
      nzKeyboard: false,
      nzWidth: "60%",
      nzOnOk: () => {
        const instance =
          modalRef.getContentComponent() as ManageHotspotImgComponent;
        instance.closeModal();
      },
    });

    modalRef.afterClose.subscribe((selectedHotspots) => {
      if (selectedHotspots) {
        const formAssetArray = this.parentForm?.get("hotspots") as FormArray;
        formAssetArray.clear();
        selectedHotspots.forEach((hotspot: any) => {
          formAssetArray.push(this.fb.control(hotspot));
        });
      }
      this.hotspots = selectedHotspots;
    });
  }

  handleImageRemove = (
    file: NzUploadFile,
    assetType?: any,
  ): Observable<boolean> => {
    assetType = assetType && typeof assetType == "object" ? null : assetType;
    return new Observable((observer) => {
      const modalRef : NzModalRef  = this.modalService.create({
        nzTitle: this.translationService.translate("CONFIRMATION"),
        nzContent: this.translationService.translate(
          "ALERT_DELETING_IMAGE_LAYER_2",
        ),
        nzWidth: "40%",
        nzClosable: false,
        nzMaskClosable: false,
        nzFooter: [
          {
            label: this.translationService.translate("YES"),
            onClick: () => {
              this.performImageDeletion(file, observer, assetType);
              if (this.selectedOption?.value == this.layerOpt.L1_L2_OP_3) {
                this.hotspots = [];
              }
              modalRef.close(); 
            },
          },
          {
            label: this.translationService.translate("CANCEL"),
            onClick: () => {
              observer.next(false);
              observer.complete();
              modalRef.close();
            },
          }
        ]
      });
    });
  };

  handleAudioRemove = (file: NzUploadFile): Observable<boolean> => {
    return this.handleImageRemove(file, SupportedAsset.AUDIO);
  };

  private performImageDeletion(
    file: NzUploadFile,
    observer: any,
    assetType?: any,
  ): void {
    if (file["id"] > 0) {
      this.deleteFileById(file, observer, assetType);
    } else {
      this.deleteFileByUrl(file, observer, assetType);
    }
  }

  private deleteFileById(
    file: NzUploadFile,
    observer: any,
    assetType?: any,
  ): void {
    this.courseApiService.deleteFiles(file["id"]).subscribe(
      (response) => {
        this.handleFileDeletionSuccess(file, observer, "id", assetType);
      },
      (error: any) => {
        // Handle error, still delete the file
        this.handleFileDeletionSuccess(file, observer, "id", assetType);
        observer.error(error);
      },
    );
  }

  private deleteFileByUrl(
    file: NzUploadFile,
    observer: any,
    assetType?: any,
  ): void {
    this.handleFileDeletionSuccess(file, observer, "url", assetType);
  }

  private handleFileDeletionSuccess(
    file: NzUploadFile,
    observer: any,
    key: string,
    assetType?: any,
  ): void {
    // Your existing logic for removing from fileList, imagesArray, etc.

    var assetArray: any;
    if (!assetType) {
      this.fileList = this.fileList.filter((f) => f.uid !== file.uid);
      assetArray = this.parentForm?.get(
        this.assetType == SupportedAsset.IMAGE
          ? COURSE_CONST.IMAGES
          : this.assetType == SupportedAsset.VIDEO
            ? COURSE_CONST.VIDEOS
            : COURSE_CONST.AUDIOS,
      ) as FormArray;
    } else {
      if (assetType == SupportedAsset.AUDIO) {
        this.audioFileList = this.audioFileList.filter(
          (f) => f.uid !== file.uid,
        );
        assetArray = this.parentForm?.get(COURSE_CONST.AUDIOS) as FormArray;
      }
    }

    if (this.isBase64(file[key])) {
      const assetIndex = assetArray.value.findIndex(
        (img: any) => img === file[key],
      );
      if (assetIndex > -1) {
        assetArray.removeAt(assetIndex);
      }
    } else {
      const assetIndex = assetArray.value.findIndex(
        (img: any) => img.id === file[key],
      );
      if (assetIndex > -1) {
        assetArray.removeAt(assetIndex);
      }
      this.imageDelete.emit(file[key]);
    }

    /* Removing Thumbnail For Video File */
    if (this.assetType == SupportedAsset.VIDEO) {
      assetArray.value.forEach((file: any, index: number) => {
        if (file?.toString()?.startsWith(`data:image/`)) {
          assetArray.removeAt(index);
        }
      });
    }

    this.handleUploadButtonState();
    this.handleUploadButtonStateForAudio();
    observer.next(true);
    observer.complete();
  }

  private handleUploadButtonState(): void {
    if (
      (this.assetType == SupportedAsset.IMAGE &&
        this.fileList.length === this.imageConfig.maxFileCount &&
        this.selectedOption?.value != LAYER_OPTIONS.L1_L2_OP_3) ||
      (this.selectedOption?.value == LAYER_OPTIONS.L1_L2_OP_3 &&
        this.fileList.length == 1) ||
      (this.assetType == SupportedAsset.VIDEO &&
        this.fileList.length === this.videoConfig.maxFileCount)
    ) {
      this.disableUploadButton = true;
    } else {
      this.disableUploadButton = false;
    }
  }

  private handleUploadButtonStateForAudio(): void {
    if (this.audioFileList.length === this.audioConfig.maxFileCount) {
      this.disableAudioUploadButton = true;
    } else {
      this.disableAudioUploadButton = false;
    }
  }

  beforeUpload = (file: NzUploadFile, assetType?: any): boolean => {
    assetType = assetType && typeof assetType == "object" ? null : assetType;
    if (!this.isFormatValid(file, assetType)) {
      return false;
    }
    if (!this.isSizeValid(file, assetType)) {
      return false;
    }
    this.convertAndStoreFile(file, assetType);
    return false;
  };

  audioBeforeUpload = (file: NzUploadFile): boolean => {
    return this.beforeUpload(file, SupportedAsset.AUDIO);
  };

  private isFormatValid(
    file: NzUploadFile,
    assetType?: SupportedAsset,
  ): boolean {
    // Check if file and file.type are defined
    let assetConfigModified =
      assetType && assetType == SupportedAsset.AUDIO
        ? this.audioConfig
        : this.assetConfiguration;
    //
    if (!file || !file.type) {
      this.showUploadDialog(this.translationService.translate("FILE_ERROR"));
      return false;
    }
    if (!assetConfigModified.allowedImageTypes.includes(file.type)) {
      this.showUploadDialog(
        this.translationService.translate("ALERT_INVALID_EXT") +
        `${assetConfigModified.allowedFileTypes}!`,
      );
      return false;
    }
    return true;
  }

  private isSizeValid(file: NzUploadFile, assetType?: SupportedAsset): boolean {
    let assetConfigModified =
      assetType && assetType == SupportedAsset.AUDIO
        ? this.audioConfig
        : this.assetConfiguration;
    let assetConstantModified =
      assetType && assetType == SupportedAsset.AUDIO
        ? this.assetConstantAudio
        : this.assetConstant;
    const fileSize = file?.size || 0;
    const minSize = assetConfigModified.minSize;
    const maxSize = assetConfigModified.maxSize;
    const isSizeValid =
      fileSize / 1024 >= minSize && fileSize / 1024 <= maxSize;

    if (!isSizeValid) {
      const alertMessage =
        this.translationService.translate(assetConstantModified.ALERT_SIZE) +
        ` ${minSize}KB and ${maxSize}KB, inclusive.!`;
      this.showUploadDialog(alertMessage);
      return false;
    }

    return true;
  }

  private convertAndStoreFile(
    file: NzUploadFile,
    assetType?: SupportedAsset,
  ): void {
    CommonUtils.getBase64(file)
      .then((base64String: any) => {
        const base64Url = base64String as string; // Type assertion here
        file.url = base64Url; // Setting the preview URL for the file
        this.handleFileListAndForm(file, assetType);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  private handleFileListAndForm(
    file: NzUploadFile,
    assetType?: SupportedAsset,
  ): void {
    if (
      this.fileList.length < this.assetConfiguration?.maxFileCount &&
      !assetType
    ) {
      this.fileList = [...this.fileList, file]; // Add the file to the fileList for display
      // Save Base64 string to the form
      const formAssetArray = this.parentForm?.get(
        this.assetType == SupportedAsset.IMAGE
          ? COURSE_CONST.IMAGES
          : COURSE_CONST.VIDEOS,
      ) as FormArray;
      formAssetArray.push(this.fb.control(file.url));
      /* Generating Video Thumbnail */
      if (this.assetType == SupportedAsset.VIDEO) {
        this.globalService
          .generateVideoThumbnail(file)
          .then((base64_VideoThumbnail) => {
            this.reloadingPreview = true;
            formAssetArray.push(this.fb.control(base64_VideoThumbnail));
            this.fileList[0]["isImageUrl"] = true;
            this.fileList[0].thumbUrl = base64_VideoThumbnail;
            /* Refreshing Video Uploads to render Icon properly */
            setTimeout(() => {
              this.reloadingPreview = false;
            }, 0);
          });
      }
      this.handleUploadButtonState();
    }
    if (
      this.audioFileList.length < this.assetConfigurationAudio.maxFileCount &&
      assetType == SupportedAsset.AUDIO
    ) {
      this.audioFileList = [...this.audioFileList, file];
      const formAssetArray = this.parentForm?.get(
        COURSE_CONST.AUDIOS,
      ) as FormArray;
      formAssetArray.push(this.fb.control(file.url));
      this.handleUploadButtonStateForAudio();
    }
  }

  isBase64(str: string): boolean {
    if (this.assetType == SupportedAsset.IMAGE) {
      return str.toString().startsWith(`data:image/`);
    } else if (this.assetType == SupportedAsset.VIDEO) {
      return str.toString().startsWith(`data:video/`);
    } else if (this.assetType == SupportedAsset.AUDIO) {
      return str.toString().startsWith(`data:audio/`);
    }
    return false;
  }

  submitForm() {
    this.formSubmitted.emit(true);
  }

  validateText() {
    return (
      this.parentForm?.get("editor")?.hasError("required") &&
      this.parentForm?.get("editor")?.touched
    );
  }

  get _addButtonContainer(): boolean {
    return this.selectedOption?.value === LAYER_OPTIONS.L1_L2_OP_2;
  }

  get _addConsentButtonContainer(): boolean {
    return this.selectedOption?.value === LAYER_OPTIONS.L1_OP_5;
  }

  get _uploadAssetContainer(): boolean {
    return (
      this.selectedOption?.value === LAYER_OPTIONS.L1_OP_2 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_L2_OP_1 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_L2_OP_3 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_OP_3 ||
      this.selectedOption?.value === LAYER_OPTIONS.L1_OP_4
    );
  }

  displayUploadValidation() {
    return this.isFormSubmit && !this.fileList.length;
  }

  /* Button Configuration */

  addNewButton() {
    var message = "";
    if (this.buttonName.length == 0 && this.buttonArray.value.length < 8) {
      message = "Enter a button name to continue.";
      this.showUploadDialog(message);
      return;
    } else if (this.buttonArray.value.length == 8) {
      message = this.translationService.translate("ALERT_MAX_BUTTON_CONDITION");
      this.showUploadDialog(message);
      return;
    }

    var titleCase = this.buttonName

    const buttonsArray = this.parentForm?.get("buttons") as FormArray;
    buttonsArray.push(this.fb.control(titleCase));
    this.buttonName = "";
  }

  addNewConsentButton() {
    var message = "";
    if (this.buttonName.length == 0 && this.buttonArray.value.length < 2) {
      message = "Enter a button name to continue.";
      this.showUploadDialog(message);
      return;
    } else if (this.buttonArray.value.length == 2) {
      message = this.translationService.translate("ALERT_MAX_BUTTON_CONDITION");
      this.showUploadDialog(message);
      return;
    } else if (this.buttonArray.value.findIndex((button: any) => button.consent == this.consentType) > -1) {
      message = this.translationService.translate("ALREADY_EXIST_CONSENT_BTN");
      this.showUploadDialog(message);
      return;
    }

    var titleCase = this.buttonName

    const buttonsArray = this.parentForm?.get("buttons") as FormArray;
    buttonsArray.push(this.fb.control({
      name: titleCase,
      consent: this.consentType
    }));
    this.buttonName = "";
    this.consentType = !this.consentType
  }

  removeButton(index: any) {
    const modalRef : NzModalRef  = this.modalService.create({
      nzTitle: this.translationService.translate("CONFIRMATION"),
      nzContent: this.translationService.translate(
        "ALERT_DELETING_IMAGE_LAYER_2",
      ),
      nzWidth: "40%",
      nzClosable: false,
      nzMaskClosable: false,
      nzFooter: [
        {
          label: this.translationService.translate("YES"),
          onClick: () => {
            const buttonArray = this.parentForm?.get("buttons") as FormArray;
            if (index > -1) {
              buttonArray.removeAt(index);
              if (this.componentName == COURSE_CONST.STEP_TWO) {
                this.courseService.removingAssetsFromStepTwo({
                  assetName: this.supportedAsset.BUTTON,
                  assetIndex: index
                })
              }
            }
            modalRef.close();
          },
        },
        {
          label: this.translationService.translate("CANCEL"),
          onClick: () => {
            modalRef.close();
          }, 
        }
      ]
    });
  }

  buttonDetails(button: any) {
    return (typeof button) == "object" ? button?.name : button
  }

  get buttonCharacterMoreThen20() {
    let indexOfBtn = this.buttonArray?.value.findIndex(
      (x: string) => x.length > 18,
    );
    if (indexOfBtn > -1) {
      return true;
    } else {
      return false;
    }
  }

  get buttonStyleControl() {
    let characterMoreThen40 = this.buttonArray?.value.findIndex(
      (x: string) => x.length > 40,
    );
    let characterMoreThen20 = this.buttonArray?.value.findIndex(
      (x: string) => x.length > 20,
    );
    if (characterMoreThen40 > -1) {
      return "w-350 h-80";
    } else if (characterMoreThen20 > -1) {
      return "w-350 h-60";
    } else {
      return "w-200";
    }
  }

  buttonBackgroundColor(buttonConfig?: any): string {
    const defaultColor = this.courseService.getStyle()['primaryColor'];

    if (this._addButtonContainer) {
      return this.buttonConfig?.backgroundColor !== 'default' 
        ? this.buttonConfig?.backgroundColor 
        : defaultColor;
    }
  
    if (this._addConsentButtonContainer) {
      if (buttonConfig) {
        return buttonConfig?.consent 
          ? this.buttonConfig?.consentTrueBgColor 
          : this.buttonConfig?.consentFalseBgColor;
      }
      return defaultColor;
    }

    return defaultColor;
  }
  

  /* */
}
