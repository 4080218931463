import { Injectable } from "@angular/core";
import { NzModalService, NzModalRef } from "ng-zorro-antd/modal";
import { SpinnerComponent } from "../core/component/spinner/spinner.component";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  private modalRef: NzModalRef | undefined;

  constructor(private modalService: NzModalService) {}

  show() {
    this.modalRef = this.modalService.create({
      nzContent: SpinnerComponent,
      nzClosable: false,
      nzMask: true,
      nzMaskClosable: false,
      nzFooter: null, // or nzFooter: [],
      nzStyle: {
        width: "40%",
      },
      nzMaskStyle: {
        backgroundColor: "rgba(255, 255, 255, 1)", // Fully opaque white background
      },
    });
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
}
