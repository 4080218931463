import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { LocalStorageUtils } from "src/app/shared/utils/utils";
import { NzModalService } from "ng-zorro-antd/modal";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private modalService: NzModalService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    var strapiToken = this.getToken();
    if (strapiToken) {
      const clonedReq = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + strapiToken),
      });

      return next.handle(clonedReq).pipe(
        tap(
          (succ) => {},
          (err) => {
            this.redirectError(err);
          },
        ),
      );
    } else {
      return next.handle(req.clone()).pipe(
        tap(
          (succ) => {},
          (err) => {
            this.redirectError(err);
          },
        ),
      );
    }
  }

  private getToken(): string {
    // Implement your logic to retrieve the token (e.g., from local storage)
    return LocalStorageUtils.retrieveFromLocalStorage("authData")
      ?.strapiToken;
  }

  private redirectError(err: any) {
    if (err.status === 401) {
      LocalStorageUtils.deleteFromLocalStorage("authData");
      this.router.navigateByUrl("/unauthorized"); //start
    } else if (err.status === 403) {
      this.router.navigateByUrl("/forbidden");
    } else if (err.status === 404) {
      // Handle the 404 error here
      console.error("Not Found:", err);
      // Optionally, you can notify the observer about the error
      // observer.error('Not Found');
    } else {
      var message = err?.error?.error?.message || "Internal Server Error";
      message = "Server Error: " + message + ". Please try again later.";

       // Check if the error message matches the specific API response
       if (message.includes("Course with version")) {
        message = "You can’t restore this event because a newer version has been created and must be used. The only thing you can do with this event is to preview it or ask a Super Admin to permanently delete it.";
      }

      // Show modal for filtered or default errors
      this.modalService.create({
        nzTitle: "Information",
        nzContent: message,
        nzOkText: "OK",
        nzClosable: false,
        nzCancelText: null,
        nzOnOk: () => {},
      });
    }
  }
}
