import { Component } from "@angular/core";
import { CourseService } from "src/app/services/course.service";
import { LocalStorageUtils } from "src/app/shared/utils/utils";

@Component({
  selector: "app-forbidden",
  templateUrl: "./forbidden.component.html",
  styleUrls: ["./forbidden.component.scss"],
})
export class ForbiddenComponent {
  styleObject: any;
  constructor(private courseService: CourseService) {}

  ngOnInit(): void {
    this.courseService.applyStyles();
  }

  getRedirectUrl() {
    window.location.href = LocalStorageUtils.getRedirectURL();
  }
}
