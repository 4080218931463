import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, concatMap, delay, first, forkJoin, of } from "rxjs";
import { QuizStepOneComponent } from "./components/quiz-step-one/quiz-step-one.component";
import { QuizStepThreeComponent } from "./components/quiz-step-three/quiz-step-three.component";
import { QuizStepTwoComponent } from "./components/quiz-step-two/quiz-step-two.component";
import { CommonUtils, Utils } from "../core/utils";
import { Course } from "../create-course/create-course.interfaces";
import { QuizStepZeroComponent } from "./components/quiz-step-zero/quiz-step-zero.component";
import { CourseService } from "../services/course.service";
import { COURSE_CONST, SLIDE_STEP } from "../create-course/data";
import { CourseApiService } from "../services/course.api.service";
import { CourseRequest } from "../models/course-interface";
import { environment } from "src/environments/environment";
import { SpinnerService } from "src/app/services/spinner.service";
import { TranslationService } from "../services/translation.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { PreviewComponent } from "../view-course/components/preview/preview.component";
import { NzModalService } from "ng-zorro-antd/modal";
import { SupportedAsset } from "../models/enum/supported-asset.enum";
import { GlobalService } from "../services/global.service";
import { Steps } from "./create-quiz.interfaces";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-create-quiz",
  templateUrl: "./create-quiz.component.html",
  styleUrls: ["./create-quiz.component.scss"],
})
export class CreateQuizComponent {
  @ViewChild(QuizStepZeroComponent)
  quizStepZeroComponent!: QuizStepZeroComponent;
  @ViewChild(QuizStepOneComponent) quizStepOneComponent!: QuizStepOneComponent;
  @ViewChild(QuizStepTwoComponent) quizStepTwoComponent!: QuizStepTwoComponent;
  @ViewChild(QuizStepThreeComponent)
  quizStepThreeComponent!: QuizStepThreeComponent;

  currentStep: number = 0;
  slideNumber: number = 0;

  formData: any = {};
  courseTitle!: string;
  categoryName!: string;
  steps: Steps[] = [];
  slides: any[] = [];
  courseId: number = 0;
  slideParam: number = 0;
  stepParam: number = 0;
  flgPreview: boolean = false;
  loading: boolean = false;
  imageText!: string;
  videoText!: string;
  audioText!: string;
  defaultImageExtType: string = "png";
  defaultVideoExtType: string = "mp4";
  defaultAudioExtType: string = "mp3";
  userAction: string = "next";
  course: any = {};
  onLoad = false;

  constructor(
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService,
    private courseApiService: CourseApiService,
    private spinner: SpinnerService,
    public translationService: TranslationService,
    private messageService: NzMessageService,
    public globalService: GlobalService,
    public viewPortController:ViewportScroller
  ) {}

  ngOnInit(): void {
    this.imageText = SupportedAsset.IMAGE.toLowerCase();
    this.videoText = SupportedAsset.VIDEO.toLowerCase();
    this.audioText = SupportedAsset.AUDIO.toLowerCase();

    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
    this.getInfoCoureAddOrUpdate();
  }

  ngAfterViewInit(): void {
    this.formData = this.courseService.retrieveCourseObject() || {};

    this.steps = [
      {
        title: SLIDE_STEP.ADD_NEW_SLIDE,
        getComponent: () => this.quizStepZeroComponent,
      },
      {
        title: SLIDE_STEP.SETUP_LAYER,
        getComponent: () => this.quizStepOneComponent,
      },
      {
        title: SLIDE_STEP.ADD_LAYER_ONE_CONFIG,
        getComponent: () => this.quizStepTwoComponent,
      },
    ];

    if (
      this.formData?.stepOne?.questionType === "1" ||
      this.formData?.stepOne?.questionType === "2" ||
      this.stepParam > 3
    ) {
      this.steps.push({
        title: SLIDE_STEP.ADD_LAYER_ONE_CONFIG,
        getComponent: () => this.quizStepThreeComponent,
      });
    }

    this.cdr.detectChanges();
  }

  getInfoCoureAddOrUpdate() {
    this.courseId = Number(
      this.courseService.getParamByName(this.route, COURSE_CONST.ID) || 0,
    );
    this.slideParam = Number(
      this.courseService.getQueryParamByName(this.route, COURSE_CONST.SLIDE) ||
        0,
    );
    this.stepParam = Number(
      this.courseService.getQueryParamByName(this.route, COURSE_CONST.STEP) ||
        0,
    );

    if (this.courseId > 0) {
      this.handleCourseIdGreaterThanZero();
    } else {
      this.handleCourseIdLessThanOne();
    }
  }

  handleCourseExist(course: any) {
    this.flgPreview = true;
    this.courseTitle = course.title;
    this.categoryName = course.category;
    this.slides =
      typeof course.slides == "string"
        ? JSON.parse(course.slides)
        : course.slides;
    this.currentStep = 1;
    this.slideNumber = 0;
    this.route.queryParams.pipe(first()).subscribe((params) => {
      const stepFromQuery = Number(params[COURSE_CONST.STEP]);
      const slideNumberFromQuery = Number(params[COURSE_CONST.SLIDE]);

      if (this.slides.length == 0 && stepFromQuery == 3) {
        this.updateRouteParam(stepFromQuery - 1, slideNumberFromQuery);
      }
      if (stepFromQuery !== undefined && !isNaN(stepFromQuery)) {
        this.currentStep = stepFromQuery - 1;
      }
      if (slideNumberFromQuery !== undefined && !isNaN(slideNumberFromQuery)) {
        this.slideNumber = slideNumberFromQuery - 1;
      }
    });
    this.courseService.updateCourseObject(
      this.slides?.[this.slideNumber] || {},
    );
  }

  handleCourseIdGreaterThanZero() {
    this.spinner.show();

    this.courseApiService.getCourse(this.courseId).subscribe({
      next: (response: any) => {
        this.spinner.hide();

        this.course = { ...response.data.attributes, ...response.data.id };
        this.editCourseValidation(this.course);

        if (this.course) {
          this.handleCourseExist(this.course);
        } else {
          this.router.navigate(["/"]);
        }
      },
      error: () => this.spinner.hide(),
    });
  }

  handleCourseIdLessThanOne() {
    this.route.queryParams.pipe(first()).subscribe((params) => {
      const stepFromQuery = Number(params[COURSE_CONST.STEP]);
      const slideNumberFromQuery = Number(params[COURSE_CONST.SLIDE]);
      this.courseTitle = params[COURSE_CONST.EVENT_NAME];
      this.categoryName = params[COURSE_CONST.CATEGORY_NAME];
      if (stepFromQuery !== undefined && !isNaN(stepFromQuery)) {
        this.currentStep = stepFromQuery - 1;
      }
      if (slideNumberFromQuery !== undefined && !isNaN(slideNumberFromQuery)) {
        this.slideNumber = slideNumberFromQuery - 1;
      }
    });
  }

  handleSave(): void {
    this.userAction = "save";
    const currentComponent = this.steps[this.currentStep].getComponent();
    if (currentComponent) {
      currentComponent.submitForm(true);
    }
    this.saveSlides(true);
    if (this.currentStep == 4 || this.currentStep == 3) {
      this.saveStep3AssetsTogether(false, true);
    } else {
      this.saveCurrentSlide(true);
    }
  }

  handleExit(): void {
    this.router.navigate(["/"]);
  }

  handleFinishCallBack(): void {
    this.modalService.create({
      nzTitle: this.translationService.translate("CONFIRMATION"),
      nzContent: this.translationService.translate("ALERT_DELETE_COURSE"),
      nzWidth: "40%",
      nzClosable: false,
      nzOkText: this.translationService.translate("YES"),
      nzCancelText: this.translationService.translate("CANCEL"),
      nzMaskClosable: false,
      nzOnOk: () => {
        this.saveCourseData(true, () => {
          setTimeout(() => {
            this.router.navigate(["/view-courses"], {
              queryParams: { category: this.categoryName },
            });
          }, 500);
        });
      },
      nzOnCancel: () => {},
    });
  }

  handlePreviewCallBack(preViewLastSlide: boolean = false, slideId: number = this.slides?.length, toSave: boolean = false): void {
    if(toSave) this.handleSave();
    
    const url = this.router
      .createUrlTree(["/view-courses/" + this.courseId], {
        queryParams: {
          previewMode: "true",
          preViewLastSlide: preViewLastSlide,
          slide: preViewLastSlide ? slideId : 1,
        },
      })
      .toString();

    const modal = this.modalService.create({
      nzTitle: preViewLastSlide
        ? this.translationService.translate(`PREVIEW_LAST_QUESTION`)
        : this.translationService.translate(`PREVIEW_MODE`),
      nzContent: PreviewComponent,
      nzData: {
        url: url,
      },
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: "80%",
    });
  }

  handlePreviewLastQuestion() {
    this.handlePreviewCallBack(true);
  }

  handleEditLastQuestion() {
    this.slideNumber = this.course.totalSlideCount - 1;
    this.currentStep = 2;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([`/edit-${this.categoryName}/${this.courseId}`], {
        queryParams: {
          step: 2,
          slide: this.course.totalSlideCount,
        },
      });
    });
  }

  validateCurrentStep(): boolean {
    const currentComponent = this.steps[this.currentStep].getComponent();
    if (currentComponent) {
      let isFormValid = currentComponent.submitForm();
      if (!isFormValid) {
        /* Validation Failed, Alerting User */
        this.formValidationFailed();
      }
      return currentComponent.submitForm();
    }
    return false; // default return for other steps.
  }

  goBack(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.updateQueryParam();
    } else {
      if (
        this.slideNumber - 1 >= 0 &&
        this.slideNumber - 1 < this.slides.length
      ) {
        this.handleSlideWithinRange();
      } else {
        this.courseService.deleteCourseBuilderSteps();
        const url = `/${this.categoryName}?courseId=${this.courseId}`;
        this.router.navigateByUrl(url);
      }
    }
  }

  handleSlideWithinRange() {
    const slide = this.slides[this.slideNumber - 1];
    this.courseService.saveCourseObject(slide);
    this.formData = CommonUtils.breakObjectReference(slide);
    this.slideNumber--;
    if (slide?.stepThree?.modalForms) {
      this.currentStep = 3;
    } else {
      this.currentStep = 2;
      if (this.steps.length == 4) {
        this.steps.pop();
      }
    }
    this.updateQueryParam();
  }

  handleSubmitCallBack(data: any, key: string) {
    this.formData[key] = CommonUtils.breakObjectReference(data);
    if (key === COURSE_CONST.STEP_ONE_DATA) {
      if (
        (data.questionType === "1" || data.questionType === "2") &&
        this.steps.length < 4
      ) {
        // Check if layer is '2' and if the third step isn't already present
        this.steps.push({
          title: SLIDE_STEP.ADD_LAYER_TWO_CONFIG,
          getComponent: () => this.quizStepThreeComponent,
        });
      } else if (
        !(data.questionType === "1" || data.questionType === "2") &&
        this.steps.length === 4
      ) {
        // Check if layer isn't '2' and if the third step is present
        this.steps.pop();
      }
    }
  }

  removeItemsById(array: any, idToRemove: any) {
    return array.filter((item: any) => item.id !== idToRemove);
  }

  handleImageDeleteCallBack(id: number) {
    let imageUrl = "";
    const courseBuilderSteps: any =
      this.courseService.retrieveCourseObject() || [];

    if (courseBuilderSteps?.stepTwo && courseBuilderSteps?.stepTwo?.images) {
      imageUrl = courseBuilderSteps.stepTwo.images.filter(
        (item: any) => item.id == id,
      )[0].url;

      courseBuilderSteps.stepTwo.images =
        courseBuilderSteps.stepTwo.images.filter((item: any) => item.id !== id);
    }

    // Remove items from stepThree where id is 465
    if (
      courseBuilderSteps?.stepThree &&
      courseBuilderSteps?.stepThree?.modalForms
    ) {
      courseBuilderSteps.stepThree.modalForms =
        courseBuilderSteps.stepThree.modalForms.filter(
          (item: any) => item.image !== imageUrl,
        );
    }
    this.courseService.updateCourseObject(courseBuilderSteps);

    this.slides[this.slideNumber] = courseBuilderSteps;
    const courseRequest: CourseRequest = {
      data: {
        slides: this.slides ? JSON.stringify(this.slides) : JSON.stringify([]),
      },
    };
    this.spinner.show();
    this.courseApiService.updateCourse(this.courseId, courseRequest).subscribe(
      (resp) => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      },
    );
  }

  goForward(): void {
    this.onLoad = true;
    this.userAction = "next";
    this.viewPortController.scrollToPosition([0,0]);
    if (this.currentStep === this.steps.length - 1) {
      // The below function checks, whether form is valid or not
      if (!this.handleCourseCompletion(true)) {
        // If invalid, don't proceed
        this.onLoad = false;
        return;
      }

      if (this.slideNumber < this.slides.length) {
        this.formData = CommonUtils.breakObjectReference(
          this.slides[this.slideNumber],
        );
        this.updateQueryParam();
        this.courseService.updateCourseObject(this.slides[this.slideNumber]);
        if (this.currentStep != 3 && this.currentStep != 4) {
          this.saveCourseData(false);
          this.currentStep = 1;
        } else {
          this.saveStep3AssetsTogether(true);
        }
      } else {
        if (this.currentStep == 4 || this.currentStep == 3) {
          this.saveStep3AssetsTogether(false);
        } else {
          this.saveCurrentSlide();
        }
      }
      this.onLoad = false;
      return;
    }
    if (
      this.validateCurrentStep() &&
      this.currentStep < this.steps.length - 1
    ) {
      // Getting into this block when at step 2
      if (this.currentStep == 2) {
        const courseBuilderSteps = this.courseService.retrieveCourseObject();
        if (this.slideNumber < this.slides.length) {
          this.slides[this.slideNumber] = courseBuilderSteps;
        } else {
          this.slides.push(courseBuilderSteps);
        }
        this.slideNumber++;
        this.saveCourseData(false, () => {
          this.courseService.updateCourseObject(
            this.slides[this.slideNumber - 1],
          );
          if (this.slideNumber > this.slides.length) {
            this.slides.splice(this.slideNumber - 1, 1);
          }
          this.slideNumber--;
          this.currentStep++;
          this.updateQueryParam();
        });
      } else {
        this.currentStep++;
        this.updateQueryParam();
      }

      // if (this.currentStep == 1) {
      //   const courseBuilderSteps = this.courseService.retrieveCourseObject();
      //   if (this.slideNumber < this.slides.length) {
      //     this.slides[this.slideNumber] = courseBuilderSteps;
      //   } else {
      //     this.slides.push(courseBuilderSteps);
      //   }
      //   this.slideNumber++;
      //   this.saveCourseData(false, () => {
      //     this.courseService.updateCourseObject(
      //       this.slides[this.slideNumber - 1],
      //     );
      //     if (this.slideNumber > this.slides.length) {
      //       this.slides.splice(this.slideNumber - 1, 1);
      //     }
      //     this.slideNumber--;
      //     this.currentStep++;
      //     this.updateQueryParam();
      //   });
      // } else {
      //   this.currentStep++;
      //   this.updateQueryParam();
      // }
        this.onLoad = false;
    }
  }

  saveCurrentSlide(onlySaveSlide?: boolean): void {
    this.saveCourseData();
    this.flgPreview = true;
    if (!onlySaveSlide) {
      this.currentStep = 0;
    }
  }

  handleCourseCompletion(flgNext = false): boolean {
    const currentComponent = this.steps[this.currentStep].getComponent();
    if (currentComponent && currentComponent.submitForm()) {
      this.saveSlides();
      return true;
    } else {
      /* Validation Failed, Alerting User */
      this.formValidationFailed();
      return false;
    }
  }

  saveSlides(saveOnly?: boolean): void {
    const courseBuilderSteps = this.courseService.retrieveCourseObject();
    if (this.slideNumber < this.slides.length) {
      this.slides[this.slideNumber] = courseBuilderSteps;
    } else {
      this.slides.push(courseBuilderSteps);
    }

    if (!saveOnly) {
      this.slideNumber++;
      this.courseService.deleteCourseBuilderSteps();
    }
  }

  saveCourseData(flgFinish = false, callback?: any): void {
    const slides = this.slides || [];
    const currentSlide = slides[this.slideNumberBasedOnUserAction];

    if (!currentSlide?.stepTwo?.images && !currentSlide?.stepTwo?.videos) {
      if (currentSlide?.stepTwo?.audios) {
        this.convertBase64AssetToUrlForStep2(
          flgFinish,
          this.audioText,
          this.defaultAudioExtType,
        ).subscribe(() => {
          this.insertUpdateCourse(flgFinish);
          callback?.();
        });
      } else {
        this.insertUpdateCourse(flgFinish);
        callback?.();
      }
    } else {
      const mediaType = currentSlide?.stepTwo?.images ? "image" : "video";
      const mediaText = this[`${mediaType}Text`];
      const defaultExtType = currentSlide?.stepTwo?.images
        ? this.defaultImageExtType
        : this.defaultVideoExtType;

      this.convertBase64AssetToUrlForStep2(
        flgFinish,
        mediaText,
        defaultExtType,
      ).subscribe(() => {
        if (currentSlide?.stepTwo?.audios) {
          this.convertBase64AssetToUrlForStep2(
            flgFinish,
            this.audioText,
            this.defaultAudioExtType,
          ).subscribe(() => {
            this.insertUpdateCourse(flgFinish);
            callback?.();
          });
        } else {
          this.insertUpdateCourse(flgFinish);
          callback?.();
        }
      });
    }
  }

  isBase64(str: string, assetType: string): boolean {
    if (assetType == this.imageText) {
      return str.startsWith(`data:${this.imageText}/`);
    } else if (assetType == this.videoText) {
      return str.startsWith(`data:${this.videoText}/`);
    } else if (assetType == this.audioText) {
      return str.startsWith(`data:${this.audioText}/`);
    }
    return false;
  }

  convertBase64ToFile(
    base64: string,
    assetType: string,
    index: number,
  ): File | null {
    if (this.isBase64(base64, assetType)) {
      const blob = this.globalService.base64ToBlob(base64);
      const extension = base64.slice(11, base64.indexOf(";base64"));
      const fileName = `${assetType}${new Date().getTime()}.${extension}`;
      const fileType = `${assetType}/${extension}`;

      if (
        index === 0 ||
        assetType === this.imageText ||
        assetType === this.audioText
      ) {
        return new File([blob], fileName, { type: fileType });
      } else {
        return new File(
          [blob],
          `${this.imageText}${new Date().getTime()}.png`,
          { type: `${this.imageText}/png` },
        );
      }
    }

    return null;
  }

  convertBase64AssetToUrlForStep2(
    flgFinish: any,
    assetType: string,
    saveExtType?: string,
  ): Observable<any> {
    var vmthis = this;
    return new Observable((observer) => {
      const slideArray = vmthis.slides;
      const formData = new FormData();
      const slideNumberVariation =
        this.userAction == "save" ? vmthis.slideNumber : vmthis.slideNumber - 1;
      const assetsArray =
        slideArray[slideNumberVariation]?.stepTwo?.[`${assetType}s`];
      if (assetsArray) {
        const newImagesArray = this.convertAssetArray(
          assetsArray,
          assetType,
          formData,
        );

        slideArray[slideNumberVariation].stepTwo[`${assetType}s`] =
          newImagesArray.filter((item: any) => !("file" in item));
        /* Removing Thumnail image from slide content */
        if (
          assetType == this.videoText &&
          slideArray[slideNumberVariation]?.stepTwo?.[`${assetType}s`].length >
            1
        ) {
          slideArray[slideNumberVariation]?.stepTwo?.[`${assetType}s`].pop();
        }

        if (formData.getAll("files").length > 0) {
          vmthis.spinner.show();
          vmthis.courseApiService.uploadFiles(formData).subscribe(
            (response: any) => {
              vmthis.spinner.hide();
              /* If asset is image, go to full response length. Else Limit array length to 1 ( For Video ) */
              let smallUrls = this.generateURLBasedAssetObject(
                response,
                assetType,
              );

              slideArray[slideNumberVariation].stepTwo[`${assetType}s`] = [
                ...slideArray[slideNumberVariation].stepTwo[`${assetType}s`],
                ...smallUrls,
              ];

              if (
                slideArray[slideNumberVariation].stepThree?.modalForms &&
                slideArray[slideNumberVariation].stepThree.modalForms.length >
                  0 &&
                assetType != this.audioText
              ) {
                for (
                  let i = 0;
                  i <
                  slideArray[slideNumberVariation].stepThree.modalForms.length;
                  i++
                ) {
                  slideArray[slideNumberVariation].stepThree.modalForms[i][
                    `${assetType}s`
                  ] =
                    slideArray[slideNumberVariation].stepTwo[`${assetType}s`][
                      i
                    ];
                }
              }
              observer.next();
              observer.complete();
            },
            (error: any) => {
              observer.error(error);
              observer.complete();
              vmthis.spinner.hide();
            },
          );
        } else {
          observer.next();
          observer.complete();
        }
      }
    });
  }

  convertBase64AssetToUrlForStep3(
    assetType: string,
    step3TabIndex: number,
  ): Observable<any> {
    var vmthis = this;
    return new Observable((observer) => {
      const slideArray = vmthis.slides;
      const formData = new FormData();
      const slideNumberVariation =
        this.userAction == "save" ? vmthis.slideNumber : vmthis.slideNumber - 1;
      const assetsArray = CommonUtils.breakObjectReference(
        slideArray[slideNumberVariation]?.stepThree?.modalForms?.[
          step3TabIndex
        ][`${assetType}s`],
      );

      if (assetsArray) {
        const newImagesArray = this.convertAssetArray(
          assetsArray,
          assetType,
          formData,
        );
        slideArray[slideNumberVariation].stepThree.modalForms[step3TabIndex][
          `${assetType}s`
        ] = newImagesArray.filter((item: any) => !("file" in item));
        /* Removing Thumnail image from slide content */
        if (
          assetType == this.videoText &&
          slideArray[slideNumberVariation]?.stepThree?.modalForms?.[
            step3TabIndex
          ][`${assetType}s`].length > 1
        ) {
          slideArray[slideNumberVariation]?.stepThree?.modalForms?.[
            step3TabIndex
          ][`${assetType}s`].pop();
        }
        if (formData.getAll("files").length > 0) {
          vmthis.spinner.show();
          vmthis.courseApiService.uploadFiles(formData).subscribe(
            (response: any) => {
              vmthis.spinner.hide();
              /* If asset is image, go to full response length. Else Limit array length to 1 ( For Video ) */
              let smallUrls = this.generateURLBasedAssetObject(
                response,
                assetType,
              );

              slideArray[slideNumberVariation].stepThree.modalForms[
                step3TabIndex
              ][`${assetType}s`] = [
                ...slideArray[slideNumberVariation]?.stepThree?.modalForms?.[
                  step3TabIndex
                ][`${assetType}s`],
                ...smallUrls,
              ];

              observer.next();
              observer.complete();
            },
            (error: any) => {
              observer.error(error);
              observer.complete();
              vmthis.spinner.hide();
            },
          );
        } else {
          observer.next();
          observer.complete();
        }
      } else {
        observer.complete();
      }
    });
  }

  convertAssetArray(assetsArray: any, assetType: string, formData: any) {
    return assetsArray.map((asset: any, index: number) => {
      if (
        typeof asset === "string" &&
        this.isBase64(
          asset,
          index == 0 ||
            assetType == this.imageText ||
            assetType == this.audioText
            ? assetType
            : this.imageText,
        )
      ) {
        const blob = this.globalService.base64ToBlob(asset);
        let file;
        if (
          index == 0 ||
          assetType == this.imageText ||
          assetType == this.audioText
        ) {
          /* Assets Base64 to File Conversion */
          file = new File(
            [blob],
            `${assetType}${new Date().getTime()}.${asset.slice(
              11,
              asset.indexOf(";base"),
            )}`,
            {
              type: `${assetType}/${asset.slice(11, asset.indexOf(";base"))}`,
            },
          );
        } else {
          /* Thumbnail For Video */
          file = new File(
            [blob],
            `${this.imageText}${new Date().getTime()}.png`,
            { type: `${this.imageText}/png` },
          );
        }
        formData.append("files", file, file.name);
        return { file };
      } else {
        return asset;
      }
    });
  }

  generateURLBasedAssetObject(response: any, assetType: string) {
    return response
      .slice(0, assetType == this.imageText ? response.length : 1)
      .map((item: any) => ({
        id: item.id,
        name: item.name,
        url: `${environment.logoUrl}${item.url}`,
        /* Adding Thumbnail URL for video based on second element in response array */
        thumbUrl: `${environment.logoUrl}${
          assetType == this.imageText ? item.url : response[1]?.url
        }`,
      }));
  }

  saveStep3AssetsTogether(saveAlready?: boolean, onlySaveSlide?: boolean) {
    const callApiSequentially = (index: number) => {
      if (
        index >=
        this.slides[this.slideNumberBasedOnUserAction].stepThree?.modalForms
          .length
      ) {
        if (!saveAlready) {
          this.saveCurrentSlide(onlySaveSlide);
        } else {
          this.currentStep = 1;
          this.saveCourseData();
        }
        return;
      }

      const assetForm =
        this.slides[this.slideNumberBasedOnUserAction].stepThree?.modalForms[
          index
        ];
      const assetType =
        assetForm.images?.length > 0
          ? SupportedAsset.IMAGE
          : assetForm.videos?.length > 0
            ? SupportedAsset.VIDEO
            : "TEXT";
      const audioAssetType =
        assetForm.audios?.length > 0 ? SupportedAsset.AUDIO : "TEXT";

      // Function to handle assetType API call
      const handleAssetType = (type: string) => {
        if (assetType !== "TEXT" && type != "audio") {
          return this.convertBase64AssetToUrlForStep3(
            assetType.toLowerCase(),
            index,
          );
        } else if (audioAssetType !== "TEXT" && type == "audio") {
          return this.convertBase64AssetToUrlForStep3(
            audioAssetType.toLowerCase(),
            index,
          );
        } else {
          return of(null);
        }
      };

      // ForkJoin to run both API calls concurrently
      forkJoin([handleAssetType("non-audio"), handleAssetType("audio")])
        .pipe(
          delay(500), // Delay if needed
        )
        .subscribe(
          () => {
            callApiSequentially(index + 1);
          },
          (error) => {
            console.error("Error:", error);
          },
        );
    };
    callApiSequentially(0);
  }

  showMessageDialog(message: string) {
    this.modalService.create({
      nzTitle: this.translationService.translate("INFORMATION"),
      nzContent: message,
      nzOkText: this.translationService.translate("OK"),
      nzClosable: false,
      nzCancelText: null, // or nzCancelText: ''
      nzOnOk: () => {},
    });
  }

  insertUpdateCourse(flgFinish: boolean) {
    const courseRequest: CourseRequest = {
      data: {
        title: this.courseTitle,
        category: this.categoryName,
        totalSlideCount: this.slides.length,
        slides: this.slides ? JSON.stringify(this.slides) : JSON.stringify([]),
        status:
          flgFinish == true
            ? COURSE_CONST.COMPLETED
            : COURSE_CONST.NOT_COMPLETED,
      },
    };

    this.spinner.show();
    if (this.courseId < 1) {
      this.courseApiService.createCourse(courseRequest).subscribe(
        (response: any) => {
          this.courseId = response.id;
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
        },
      );
    } else {
      this.courseApiService
        .updateCourse(this.courseId, courseRequest)
        .subscribe(
          (response: any) => {
            this.spinner.hide();
            if (this.currentStep == 0) {
              // this.updateRouteParam(1, this.slides.length + 1);
            }
          },
          (error: any) => {
            this.spinner.hide();
          },
        );
    }
  }

  handleAddAnotherSlide(): void {
    // Trigger stepThreeComponent's submitForm()
    const courseBuilderSteps = this.courseService.retrieveCourseObject();
    if (this.currentStep == 0 && !courseBuilderSteps?.stepOne?.layerOptions) {
      this.currentStep = 1;
      return;
    }

    const currentComponent = this.steps[this.currentStep].getComponent();
    if (currentComponent && currentComponent.submitForm()) {
      this.saveCourseData();
      this.setSteps();
      this.currentStep = 1;
      this.updateQueryParam();
    } else {
      this.showMessageDialog(
        this.translationService.translate("CURRENT_SLIDE_SAVE_FAILED"),
      );
    }
  }

  setSteps() {
    this.steps = [
      {
        title: SLIDE_STEP.ADD_NEW_SLIDE,
        getComponent: () => this.quizStepZeroComponent,
      },
      {
        title: SLIDE_STEP.SETUP_LAYER,
        getComponent: () => this.quizStepOneComponent,
      },
      {
        title: SLIDE_STEP.ADD_LAYER_ONE_CONFIG,
        getComponent: () => this.quizStepTwoComponent,
      },
    ];
  }

  updateQueryParam(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { step: this.currentStep + 1, slide: this.slideNumber + 1 },
      queryParamsHandling: "merge",
      replaceUrl: true,
    });
  }

  updateRouteParam(step: number, slide: number) {
    window.location.href = this.router.url
      .replace(`step=${this.stepParam.toString()}`, `step=${step.toString()}`)
      .replace(
        `slide=${this.slideParam.toString()}`,
        `slide=${slide.toString()}`,
      );
  }

  editCourseValidation(course: Course) {
    if (course.status == COURSE_CONST.COMPLETED || !course) {
      this.modalService.create({
        nzTitle: this.translationService.translate("EDIT_FAILED"),
        nzContent: this.translationService.translate("EDIT_FAILED_MESSAGE"),
        nzWidth: "40%",
        nzOkText: this.translationService.translate("OK"),
        nzClosable: false,
        nzCancelText: null, // or nzCancelText: ''
        nzOnOk: () => {
          this.router.navigate(["/start"]);
        },
      });
    }
  }

  formValidationFailed(): void {
    /* Validation Failed, Alerting User */
    this.globalService.informationModal(
      this.translationService.translate("VALIDATION_FAILED"),
      this.translationService.translate("VALIDATION_ERROR"),
    );
  }

  /* Get Slide Number */
  get slideNumberBasedOnUserAction() {
    if (this.userAction == "next") {
      return this.slideNumber - 1;
    } else {
      return this.slideNumber;
    }
  }
}
